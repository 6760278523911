import { CustomerResponseV3Additional } from '@services/transformers/customers';
import { VeygoResponseError } from '@utils/types';
import { Moment } from 'moment';

import { types as loginTypes } from './login.actions';
import { QuoteLicence, CustomerQuote } from './customerTypes';
import { RegisterFailure } from './register.actions';

export const SET_CUSTOMER_FIELD = 'SET_CUSTOMER_FIELD';
export const CUSTOMER_FETCH_ME_REQUEST = 'FETCH_ME.REQUEST';
export const CUSTOMER_FETCH_ME_SUCCESS = 'FETCH_ME.SUCCESS';
export const CUSTOMER_FETCH_ME_FAILURE = 'FETCH_ME.FAILURE';

export const CUSTOMER_SAVE_REQUEST = 'CUSTOMER_SAVE.REQUEST';
export const CUSTOMER_SAVE_SUCCESS = 'CUSTOMER_SAVE.SUCCESS';
export const CUSTOMER_SAVE_FAILURE = 'CUSTOMER_SAVE.FAILURE';

export const CUSTOMER_SAVE_FINISH_REQUEST = 'SAVE_CUSTOMER_FINISH.REQUEST';
export const CUSTOMER_SAVE_FINISH_SUCCESS = 'SAVE_CUSTOMER_FINISH.SUCCESS';
export const CUSTOMER_SAVE_FINISH_FAILURE = 'SAVE_CUSTOMER_FINISH.FAILURE';

export const CUSTOMER_UPGRADE_REQUEST = 'UPGRADE_CUSTOMER.REQUEST';
export const CUSTOMER_UPGRADE_SUCCESS = 'UPGRADE_CUSTOMER.SUCCESS';

export const DRIVER_FROM_QUOTE = 'DRIVER_FROM_QUOTE';
export const OWNER_FROM_QUOTE = 'OWNER_FROM_QUOTE';
export const PROFILE_FROM_QUOTE = 'PROFILE_FROM_QUOTE';
export const SET_CONTACT_METHOD = 'SET_CONTACT_METHOD';
export const CANNOT_BE_CONTACTED = 'CANNOT_BE_CONTACTED';

export interface SetCustomerField {
  type: typeof SET_CUSTOMER_FIELD;
  field: string;
  value: boolean | Moment;
}

export interface CustomerLoginSuccess {
  type: typeof loginTypes.LOGIN_SUCCESS;
}

export interface CustomerLogout {
  type: typeof loginTypes.LOGOUT_SUCCESS;
}

export interface FetchMe {
  type: typeof CUSTOMER_FETCH_ME_REQUEST;
}

export interface FetchMeSuccess {
  type: typeof CUSTOMER_FETCH_ME_SUCCESS;
  data: CustomerResponseV3Additional;
}

export interface FetchMeFailure {
  type: typeof CUSTOMER_FETCH_ME_FAILURE;
  error: VeygoResponseError;
}

export interface SaveCustomerRequest {
  type: typeof CUSTOMER_SAVE_REQUEST;
}
export interface SaveCustomerSuccess {
  type: typeof CUSTOMER_SAVE_SUCCESS;
  data: CustomerResponseV3Additional;
}

export interface SaveCustomerFailure {
  type: typeof CUSTOMER_SAVE_FAILURE;
  error: VeygoResponseError;
}

export interface SaveCustomerAndGoTo {
  type: typeof CUSTOMER_SAVE_REQUEST;
  page: string;
}

export interface SaveCustomerAndFinishRequest {
  type: typeof CUSTOMER_SAVE_FINISH_REQUEST;
  destination: string;
}
export interface SaveCustomerAndFinishSuccess {
  type: typeof CUSTOMER_SAVE_FINISH_SUCCESS;
  data: CustomerResponseV3Additional;
  token: string;
}

export interface SaveCustomerAndFinishFailure {
  type: typeof CUSTOMER_SAVE_FINISH_FAILURE;
  error: VeygoResponseError;
}

export interface SetDriverFromQuote {
  type: typeof DRIVER_FROM_QUOTE;
  uuid: string;
  quote: CustomerQuote;
}

export interface SetOwnerFromQuote {
  type: typeof OWNER_FROM_QUOTE;
  uuid: string;
  quote: CustomerQuote;
}

export interface SetProfileFromQuote {
  type: typeof PROFILE_FROM_QUOTE;
}

export interface UpgradeCustomerRequest {
  type: typeof CUSTOMER_UPGRADE_REQUEST;
}

export interface UpgradeCustomerSuccess {
  type: typeof CUSTOMER_UPGRADE_SUCCESS;
  data: {
    licence: QuoteLicence;
  };
}

export interface SetContactMethod {
  type: typeof SET_CONTACT_METHOD;
  email: boolean;
  phone: boolean;
}

export interface SetCannotBeContacted {
  type: typeof CANNOT_BE_CONTACTED;
  cannotBeContacted: boolean;
}

export type HandleSaveFailuresActionTypes =
  | SaveCustomerFailure
  | SaveCustomerAndFinishFailure
  | RegisterFailure;

export type CustomerActions =
  | SetCustomerField
  | FetchMe
  | FetchMeSuccess
  | FetchMeFailure
  | SaveCustomerRequest
  | SaveCustomerSuccess
  | SaveCustomerFailure
  | SaveCustomerAndGoTo
  | SaveCustomerAndFinishRequest
  | SaveCustomerAndFinishSuccess
  | SaveCustomerAndFinishFailure
  | SetDriverFromQuote
  | SetOwnerFromQuote
  | SetProfileFromQuote
  | UpgradeCustomerRequest
  | UpgradeCustomerSuccess
  | SetContactMethod
  | SetCannotBeContacted
  | CustomerLoginSuccess
  | CustomerLogout;

// Actions
export const setCustomerField = (field: string, value: boolean | Moment): SetCustomerField => ({
  type: SET_CUSTOMER_FIELD,
  field,
  value,
});

export const fetchMe = (): FetchMe => ({
  type: CUSTOMER_FETCH_ME_REQUEST,
});

export const fetchMeSuccess = (data: CustomerResponseV3Additional): FetchMeSuccess => ({
  type: CUSTOMER_FETCH_ME_SUCCESS,
  data,
});

export const fetchMeFailure = (error: VeygoResponseError): FetchMeFailure => ({
  type: CUSTOMER_FETCH_ME_FAILURE,
  error,
});

export const saveCustomer = (): SaveCustomerRequest => ({
  type: CUSTOMER_SAVE_REQUEST,
});

export const saveCustomerSuccess = (data: CustomerResponseV3Additional): SaveCustomerSuccess => ({
  type: CUSTOMER_SAVE_SUCCESS,
  data,
});

export const saveCustomerFailure = (error: VeygoResponseError): SaveCustomerFailure => ({
  type: CUSTOMER_SAVE_FAILURE,
  error,
});

export const saveCustomerAndGoTo = (page: string): SaveCustomerAndGoTo => ({
  type: CUSTOMER_SAVE_REQUEST,
  page,
});

export const saveCustomerAndFinish = (destination: string): SaveCustomerAndFinishRequest => ({
  type: CUSTOMER_SAVE_FINISH_REQUEST,
  destination,
});

export const saveCustomerAndFinishSuccess = (
  data: CustomerResponseV3Additional,
  token: string,
): SaveCustomerAndFinishSuccess => ({
  type: CUSTOMER_SAVE_FINISH_SUCCESS,
  data,
  token,
});

export const saveCustomerAndFinishFailure = (
  error: VeygoResponseError,
): SaveCustomerAndFinishFailure => ({
  type: CUSTOMER_SAVE_FINISH_FAILURE,
  error,
});

export const setDriverFromQuote = (uuid: string, quote: CustomerQuote): SetDriverFromQuote => ({
  type: DRIVER_FROM_QUOTE,
  uuid,
  quote,
});

export const setOwnerFromQuote = (uuid: string, quote: CustomerQuote): SetOwnerFromQuote => ({
  type: OWNER_FROM_QUOTE,
  uuid,
  quote,
});

export const setProfileFromQuote = (): SetProfileFromQuote => ({
  type: PROFILE_FROM_QUOTE,
});

export const upgradeCustomerRequest = (): UpgradeCustomerRequest => ({
  type: CUSTOMER_UPGRADE_REQUEST,
});

export const upgradeCustomerSuccess = (data: {
  licence: QuoteLicence;
}): UpgradeCustomerSuccess => ({
  type: CUSTOMER_UPGRADE_SUCCESS,
  data,
});

export const setContactMethod = (email: boolean, phone: boolean): SetContactMethod => ({
  type: SET_CONTACT_METHOD,
  email,
  phone,
});

export const setCannotBeContacted = (cannotBeContacted: boolean): SetCannotBeContacted => ({
  type: CANNOT_BE_CONTACTED,
  cannotBeContacted,
});
