import moment from 'moment';

const weekdayAbbreviations = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'];

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const createMomentFromDateAndHour = (date, time) => {
  const { hour, minute } = time;

  return moment(date)
    .hour(hour || 0)
    .minute(minute || 0)
    .second(0);
};

export const getDaysAndHoursFromHours = (hours) => {
  const days = Math.floor(hours / 24);
  // eslint-disable-next-line no-param-reassign
  hours %= 24;

  if (days > 0) {
    return `${days} days ${hours} hours`;
  }
  return `${hours} hours`;
};

export const getDaysFromHours = (hours) => Math.floor(hours / 24);

export const getAge = (birthdate) => moment().diff(birthdate, 'years');

export const isOutsideRange = (day, toOrFrom, currentFromDate, currentToDate) => {
  // day is in the past
  if (day.isBefore(moment(), 'day')) return true;
  // from date is after 60 days
  if (toOrFrom === 'from' && day.isAfter(moment().add(60, 'days'), 'day')) return true;
  // to date is before from date
  if (toOrFrom === 'to' && day.isBefore(currentFromDate, 'day')) return true;
  // from date is after to date
  if (toOrFrom === 'from' && day.isAfter(currentToDate)) return true;

  return false;
};

export const buildDateForApi = (dateObject) => {
  if (!dateObject) return null;

  return dateObject.format('YYYY-MM-DD');
};

export const isBetween22and5 = (date) => {
  if (!date) return null;

  return date.hours() >= 22 || date.hours() < 5;
};

export const isForbiddenDuration = (start, end) => {
  const endDate = moment(end);
  const startDate = moment(start);
  const durationLessThan24h = moment.duration(endDate.diff(startDate)).asHours() < 24;

  return durationLessThan24h && (isBetween22and5(startDate) || isBetween22and5(endDate));
};

export const formatDate = (date) => moment(date).format('Do MMMM YYYY');

export const formatWeekday = (date) => weekdayAbbreviations[date.getDay()];

export const formatMonth = (date) => monthNames[date.getMonth()];

export const createNativeDateFromMoment = (date) => {
  return date ? date.toDate() : null;
};

export const createMomentFromNativeDate = (date) => {
  return moment(date.toISOString());
};

export const formatNativeDate = (date) => createMomentFromNativeDate(date).format('D MMMM YYYY');
