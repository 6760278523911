/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useState, useEffect, lazy, Suspense } from 'react';
import { useHistory } from 'react-router-dom';
import { Moment } from 'moment-timezone';
import styled from 'styled-components';
import { useOptimizelyFlag } from '@hooks';

import QuoteStep from '@templates/QuoteStep';
import Group from '@atoms/layout/form/Group';
import SectionTitle from '@atoms/titles/componentTitles/SectionTitle';
import RestrictedPeriodErrorBox from '@atoms/blocks/RestrictedPeriodErrorBox';
import RestrictedPeriodInfoBox from '@molecules/blocks/RestrictedPeriodInfoBox';
import ToggleButtons from '@molecules/inputs/ToggleButtons';
import timebarRange from '@config/timebarRange.json';
import Loader from '@assets/loading.gif';
import { FieldContainer, Info } from '@rentecarlo/component-library';
import Spacer from '@atoms/layout/form/Spacer';
import { CurrentRestrictedPeriod } from './DurationOfCover.container';
import { getMaxDurationDaysForProductType } from './utils';

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
`;

const LoadingImg = styled.img`
  width: 20px;
  height: 20px;
`;

const LoadingPlaceholder = () => (
  <LoaderContainer>
    <LoadingImg src={Loader} />
  </LoaderContainer>
);

interface DurationOfCoverProps {
  productType: string;
  isSubscription: boolean;
  immediateStart: boolean | null;
  endDateTime: Moment | null;
  startDateTime: Moment | null;
  isCoverDurationInRestrictedPeriod: boolean;
  currentRestrictedPeriod: CurrentRestrictedPeriod[];
  isStartDateTimeBetween22and5: boolean | null;
  loggedIn: boolean;
  isCoverStartTimeInFuture: boolean;
  isCoverStartBefore60Days: boolean;
  isCoverEndTimeAfterCoverStartTime: boolean;
  isCoverLongerThanMinimumDuration: boolean;
  isCoverShorterThanMaximumDuration: boolean;
  resetEndDate: () => void;
  setStartDateTime: (date: Moment, isSubscription?: boolean) => void;
  setEndDateTime: (date: Moment) => void;
  nextAction: (action: string | boolean) => void;
  setImmediateStart: (immediateStart: boolean, isSubscription: boolean) => void;
  driverDOB: Moment | null;
}

const LazyQuoteDateTimeInput = lazy(() => import('@organisms/modals/QuoteDateTimeInput'));

type DurationFormErrors = {
  immediateStartError: string | undefined;
  startDateTimeError: string | undefined;
  endDateTimeError: string | undefined;
  isOldEnoughError: string | undefined;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const removeUndefinedKeys = <T extends Record<string, any>>(formErrors: T): Partial<T> => {
  return Object.entries(formErrors).reduce<Partial<T>>((acc, [key, value]) => {
    if (value !== undefined) {
      acc[key as keyof T] = value;
    }
    return acc;
  }, {});
};

const DurationOfCover: FC<DurationOfCoverProps> = ({
  resetEndDate,
  setImmediateStart,
  isCoverEndTimeAfterCoverStartTime,
  isCoverLongerThanMinimumDuration,
  isCoverShorterThanMaximumDuration,
  isCoverStartTimeInFuture,
  isCoverStartBefore60Days,
  setStartDateTime,
  setEndDateTime,
  nextAction,
  loggedIn,
  productType,
  isSubscription,
  immediateStart = null,
  startDateTime = null,
  endDateTime = null,
  isCoverDurationInRestrictedPeriod,
  currentRestrictedPeriod,
  isStartDateTimeBetween22and5,
  driverDOB,
}) => {
  const [submitError, setSubmitError] = useState(false);
  const [formErrors, setFormErrors] = useState<DurationFormErrors>({
    immediateStartError: undefined,
    startDateTimeError: undefined,
    endDateTimeError: undefined,
    isOldEnoughError: undefined,
  });
  const subsFuturePurchase = useOptimizelyFlag('TEMP_SUBS_FUTURE_PURCHASE');

  const doesStartDateExist = () => {
    return immediateStart || startDateTime !== null;
  };

  const hasError = () => {
    return (
      !doesStartDateExist() ||
      !endDateTime ||
      !isCoverStartTimeInFuture ||
      !isCoverEndTimeAfterCoverStartTime ||
      !isCoverLongerThanMinimumDuration ||
      !isCoverShorterThanMaximumDuration ||
      isCoverDurationInRestrictedPeriod ||
      formErrors.isOldEnoughError !== undefined
    );
  };

  const getStartDateTimeError = () => {
    let error = 'none';

    if (!isCoverStartTimeInFuture) {
      error = `You cannot purchase cover in the past, for less than one hour or for more than ${getMaxDurationDaysForProductType(
        productType,
      )} days.`;
    } else if (!isCoverStartBefore60Days) {
      error = 'Please note, you cannot purchase cover more than 60 days in advance.';
    }

    return error;
  };

  const getEndDateTimeError = () => {
    let error = 'none';

    if (
      !isCoverEndTimeAfterCoverStartTime ||
      !isCoverLongerThanMinimumDuration ||
      !isCoverShorterThanMaximumDuration
    ) {
      error = `You cannot purchase cover in the past, for less than one hour or for more than ${getMaxDurationDaysForProductType(
        productType,
      )} days.`;
    }
    return error;
  };

  const updateFormErrors = () => {
    const durationFormErrors: DurationFormErrors = {
      immediateStartError: undefined,
      startDateTimeError: undefined,
      endDateTimeError: undefined,
      isOldEnoughError: undefined,
    };

    if (immediateStart === null) durationFormErrors.immediateStartError = 'Error';

    if ((getStartDateTimeError() !== 'none' || startDateTime === null) && immediateStart !== null) {
      durationFormErrors.startDateTimeError = 'Error';
    }

    if (
      (getEndDateTimeError() !== 'none' || endDateTime === null) &&
      (immediateStart || (!immediateStart && startDateTime))
    ) {
      durationFormErrors.endDateTimeError = 'Error';
    }
    if (startDateTime) {
      const isOldEnough = startDateTime.diff(driverDOB?.startOf('day'), 'years') >= 17;
      if (!isOldEnough) durationFormErrors.isOldEnoughError = 'Error';
    }
    setFormErrors(durationFormErrors);
  };

  const history = useHistory();
  useEffect(() => {
    if (isSubscription && !subsFuturePurchase.enabled) {
      history.goBack();
    }
  }, [isSubscription]);

  useEffect(() => {
    if (immediateStart || startDateTime || endDateTime) updateFormErrors();
  }, [immediateStart, startDateTime, endDateTime]);

  const onDateChange = (date: Moment, startOrEnd: string) => {
    // If the time is not in the allowed set of values then default to 0
    // this case can happen after the user chooses START NOW and selects a duration from the slider
    // and then changes back to start later.
    if (!timebarRange.minutes.find((pair) => pair.value === date.minutes())) date.minutes(0);
    return startOrEnd === 'start' ? setStartDateTime(date) : setEndDateTime(date);
  };

  const updateSubscriptionDuration = (startDate: Moment) => {
    setStartDateTime(startDate, true);
  };

  const onSubmit = () => {
    if (!hasError()) {
      nextAction(loggedIn);
    } else {
      updateFormErrors();
      setSubmitError(true);
    }
  };

  const handleImmediateStart = (isImmediateStart: boolean) => {
    resetEndDate();
    setImmediateStart(isImmediateStart, isSubscription);
  };

  return (
    <QuoteStep
      id='durationOfCover-component-quoteStep'
      nextAction={() => onSubmit()}
      displaySummaryFooter
      paddingBottom={120}
      stepTitle={!isSubscription ? 'Duration of cover' : 'Subscription start'}
      stepSubtitle={
        !isSubscription
          ? `You can buy our ${
              productType === 'ldp' ? 'learner driver' : ''
            } insurance from 1 hour to ${getMaxDurationDaysForProductType(productType)} days.`
          : undefined
      }
      form
      submitError={submitError && hasError()}
      formErrors={removeUndefinedKeys(formErrors)}
    >
      <Group id='durationOfCover-container-groupStart'>
        <SectionTitle id='durationOfCover-title-start'>
          When do you want your cover to start?
        </SectionTitle>
        <FieldContainer
          id='immediateStartError'
          showErrorContainer={
            (submitError && !!formErrors.immediateStartError) || !!formErrors.isOldEnoughError
          }
          showError={!!formErrors.isOldEnoughError}
          error='Oops! The driver must be 17+ years old when the policy begins'
        >
          <ToggleButtons
            value={immediateStart}
            onSelect={(e: { target: { value: string } }) =>
              handleImmediateStart(e.target.value === 'true')
            }
            groupName='isImmediateStart'
            leftLabel='START NOW'
            rightLabel='START LATER'
            page='durationOfCover'
          />
        </FieldContainer>
        {immediateStart === false && (
          <FieldContainer
            id='startDateTimeError'
            showErrorContainer={submitError && !!formErrors.startDateTimeError}
            showError={getStartDateTimeError() !== 'none'}
            error={getStartDateTimeError()}
          >
            <Suspense fallback={LoadingPlaceholder}>
              <LazyQuoteDateTimeInput
                endDateTime={!isSubscription ? endDateTime : null}
                startDateTime={startDateTime}
                toOrFrom='from'
                onChange={(date) =>
                  !isSubscription ? onDateChange(date, 'start') : updateSubscriptionDuration(date)
                }
                labelId='from'
                dateId='fromDate'
                timeId='fromTime'
                calenderIconId='fromCalenderIcon'
              />
            </Suspense>
            <Info id='durationOfCover-text-inAdvance'>
              You can buy insurance up to 60 days in advance
            </Info>
          </FieldContainer>
        )}
      </Group>
      {immediateStart !== null && startDateTime && !isSubscription && (
        <div>
          <Group id='durationOfCover-container-groupEnd'>
            <SectionTitle id='durationOfCover-title-durationEnd' hideMargin>
              When do you want your cover to end?
            </SectionTitle>
            <RestrictedPeriodInfoBox />
            <Spacer />
            <FieldContainer
              id='endDateTimeError'
              showErrorContainer={submitError && !!formErrors.endDateTimeError}
              showError={getEndDateTimeError() !== 'none'}
              error={getEndDateTimeError()}
            >
              <Suspense fallback={LoadingPlaceholder}>
                <LazyQuoteDateTimeInput
                  endDateTime={endDateTime}
                  startDateTime={startDateTime}
                  toOrFrom='to'
                  onChange={(date) => onDateChange(date, 'end')}
                  labelId='to'
                  dateId='toDate'
                  timeId='toTime'
                  calenderIconId='toCalenderIcon'
                  isStartDateTimeBetween22and5={isStartDateTimeBetween22and5}
                />
              </Suspense>
            </FieldContainer>
            {isCoverDurationInRestrictedPeriod && (
              <RestrictedPeriodErrorBox currentRestrictedPeriod={currentRestrictedPeriod} />
            )}
          </Group>
        </div>
      )}
    </QuoteStep>
  );
};

export default DurationOfCover;
