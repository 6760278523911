import { Address, Quote } from './addressTypes';

export const LOOKUP_ADDRESS_FAILURE = 'LOOKUP_ADDRESS.FAILURE';
export const DRIVER_FROM_QUOTE = 'DRIVER_FROM_QUOTE';
export const FETCH_ME_SUCCESS = 'FETCH_ME.SUCCESS';

export interface LookupAddressFailure {
  type: typeof LOOKUP_ADDRESS_FAILURE;
  code: string;
}

export interface DriverFromQuote {
  type: typeof DRIVER_FROM_QUOTE;
  quote: Quote;
}

export interface FetchMeSuccess {
  type: typeof FETCH_ME_SUCCESS;
  data: {
    addresses: Address[];
  };
}

export type AddressActionTypes = LookupAddressFailure | DriverFromQuote | FetchMeSuccess;
