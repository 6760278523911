/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
import * as React from 'react';
import styled from 'styled-components';
import { WrappedFieldProps } from 'redux-form';
import moment from 'moment';

import colors from '@config/colors';
import fieldStyleProps from '@services/fieldStyleProps';
import { FieldContainer } from '@rentecarlo/component-library';

type ContainerProps = {
  borderBottomColor: string;
  disabled: boolean;
};

const Container = styled.div<ContainerProps>`
  height: 65px;
  width: 100%;
  border-radius: 4px;
  background-color: ${({ disabled }): string =>
    disabled ? colors.grey.background.disabled : colors.white};
  box-shadow: 0 1px 3px 0 ${colors.shadow};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: proxima-soft;
  font-size: 16px;
  color: ${colors.grey.text};
  border: 0.5px solid ${colors.grey.lines.light};
  transition: border 400ms ease-in-out;
  border-bottom: 2px solid ${({ borderBottomColor }): string => borderBottomColor};
  position: relative;
`;

const DateField = styled.input<StyledDateProps>`
  flex: 1;
  width: 33%;
  border: none;
  color: ${({ textColor }): string => textColor || colors.outerSpace};
  text-align: center;
  font-family: proxima-soft;
  font-size: 16px;
  background-color: inherit;
  &:focus {
    outline: none;
  }
  font-weight: bold;
  min-width: 0;
  ::placeholder {
    font-weight: normal;
  }
`;

const IconContainer = styled.div`
  position: absolute;
  right: 8px;
  top: 8px;
`;

type StyledDateProps = {
  textColor: string;
};

type DateFieldProps = {
  id: string;
  placeholder: string;
  type: string;
  maxLength: number;
  ref: string;
  onKeyUp: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onBlur: () => void;
  value: string;
  textColor: string;
  disabled: boolean;
  onChange: () => void;
};

const DateFieldInput = React.forwardRef<HTMLInputElement, DateFieldProps>((props, ref) => {
  const {
    id,
    placeholder,
    type,
    maxLength,
    onKeyUp,
    onKeyDown,
    onBlur,
    value,
    textColor,
    disabled,
    onChange,
  } = props;
  return (
    <DateField
      id={id}
      placeholder={placeholder}
      type={type}
      maxLength={maxLength}
      onKeyUp={onKeyUp}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
      ref={ref}
      value={value}
      textColor={textColor}
      disabled={disabled}
      onChange={onChange}
    />
  );
});

type Props = {
  disabled: boolean;
  id: string;
} & WrappedFieldProps;

const stripNonDigits = (value: string): string => value.replace(/[^\d]/g, '');

const zeroPadding = (value: string): string => {
  if (value.length === 1) value = `0${value}`;
  return value;
};

const yearPadding = (year: string): string => {
  if (year.length === 0 || year.length > 2) return year; // only pad 1 or two digit values
  return parseInt(year) <= 20 ? `20${zeroPadding(year)}` : `19${zeroPadding(year)}`;
};

const DateInput: React.FC<Props> = ({
  input: { value, onChange, onBlur, onFocus },
  meta: { error, active, submitFailed, touched, pristine, valid, visited },
  id,
  disabled,
}) => {
  const dayRef = React.useRef<React.ElementRef<'input'>>(null);
  const monthRef = React.useRef<React.ElementRef<'input'>>(null);
  const yearRef = React.useRef<React.ElementRef<'input'>>(null);

  const [modified, setModified] = React.useState(false);

  const onInputChange = (): void => {
    setModified(true);
    const day = dayRef.current ? dayRef.current.value : '';
    const month = monthRef.current ? monthRef.current.value : '';
    const year = yearRef.current ? yearRef.current.value : '';

    const dob = day || month || year ? moment(`${day}/${month}/${year}`, 'DD/MM/YYYY', true) : null;

    onChange(dob);
  };

  const autoCorrectDay = (): void => {
    if (dayRef.current && dayRef.current.value) {
      if (parseInt(dayRef.current.value) > 31) dayRef.current.value = '31';
      dayRef.current.value = stripNonDigits(dayRef.current.value);
    }
  };

  const autoCorrectMonth = (): void => {
    if (monthRef.current && monthRef.current.value) {
      if (parseInt(monthRef.current.value) > 12) monthRef.current.value = '12';
      monthRef.current.value = stripNonDigits(monthRef.current.value);
    }
  };

  const autoCorrectYear = (): void => {
    if (yearRef.current && yearRef.current.value) {
      yearRef.current.value = stripNonDigits(yearRef.current.value);
    }
  };

  const onDayKeyUp = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    const { target, key } = event;
    if (!(target instanceof window.HTMLInputElement) || key === 'Tab' || key === 'Shift') {
      return;
    }

    autoCorrectDay();

    target.value.toString().length === 2 && monthRef.current && monthRef.current.focus();

    target.selectionStart === 2 && monthRef.current && monthRef.current.focus();

    onInputChange();
  };

  const onMonthKeyUp = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    const { target, key } = event;
    if (!(target instanceof window.HTMLInputElement) || key === 'Tab' || key === 'Shift') {
      return;
    }
    autoCorrectMonth();

    target.value.toString().length === 2 && yearRef.current && yearRef.current.focus();

    onInputChange();
  };

  const onMonthKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    const { target, key } = event;

    if (!(target instanceof window.HTMLInputElement) || key === 'Tab' || key === 'Shift') {
      return;
    }
    if (target.value.toString().length === 0 && key === 'Backspace' && dayRef.current) {
      dayRef.current.focus();
    }
  };

  const onYearKeyUp = (): void => {
    autoCorrectYear();
    onInputChange();
  };

  const onYearKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    const { target, key } = event;

    if (!(target instanceof window.HTMLInputElement)) {
      return;
    }
    if (target.value.toString().length === 0 && key === 'Backspace' && monthRef.current !== null) {
      monthRef.current.focus();
    }
  };

  const onBlurDay = (): void => {
    if (dayRef.current && dayRef.current.value) {
      dayRef.current.value = zeroPadding(dayRef.current.value);
      if (dayRef.current.value === '00') dayRef.current.value = '01';
    }
  };

  const onBlurMonth = (): void => {
    if (monthRef.current && monthRef.current.value) {
      monthRef.current.value = zeroPadding(monthRef.current.value);
      if (monthRef.current.value === '00') monthRef.current.value = '01';
    }
  };

  const onBlurYear = (): void => {
    if (!modified) {
      return;
    }
    if (yearRef.current && yearRef.current.value) {
      yearRef.current.value = yearPadding(yearRef.current.value);
    }
    onInputChange(); // fire event to trigger validation
  };

  const onInputFocus = (e: React.FocusEvent): void => {
    onFocus(e);
  };

  const onInputBlur = (): void => {
    const day = dayRef.current ? dayRef.current.value : '';
    const month = monthRef.current ? monthRef.current.value : '';
    const year = yearRef.current ? yearRef.current.value : '';

    const dob = day || month || year ? moment(`${day}/${month}/${year}`, 'DD/MM/YYYY', true) : null;

    onBlur(dob);
  };

  const getDayValue = (): string => {
    return !visited && moment(value).isValid() ? moment(value).format('DD') : undefined;
  };

  const getMonthValue = (): string => {
    return !visited && moment(value).isValid() ? moment(value).format('MM') : undefined;
  };

  const getYearValue = (): string => {
    return !visited && moment(value).isValid() ? moment(value).format('YYYY') : undefined;
  };

  const { Icon, textColor, borderBottomColor } = fieldStyleProps(
    disabled,
    active,
    moment(value).isValid() && valid,
    ((touched && !pristine) || submitFailed) && error && !active,
  );

  return (
    <FieldContainer
      id={id}
      showErrorContainer={submitFailed && error}
      showError={((!pristine && touched) || submitFailed) && error && (!active || submitFailed)}
      error={error}
    >
      <Container
        id='quote-container-dateInputDOB'
        onFocus={(e): void => onInputFocus(e)}
        onBlur={onInputBlur}
        borderBottomColor={borderBottomColor}
        disabled={disabled}
      >
        <DateFieldInput
          id='quote-field-dateInputDay'
          placeholder='DD'
          type='tel'
          maxLength={2}
          ref={dayRef}
          onKeyUp={onDayKeyUp}
          onBlur={onBlurDay}
          value={getDayValue()}
          textColor={textColor}
          disabled={disabled}
          onChange={onChange}
        />
        /
        <DateFieldInput
          id='quote-field-dateInputMonth'
          placeholder='MM'
          type='tel'
          maxLength={2}
          ref={monthRef}
          onKeyUp={onMonthKeyUp}
          onKeyDown={onMonthKeyDown}
          onBlur={onBlurMonth}
          value={getMonthValue()}
          textColor={textColor}
          disabled={disabled}
          onChange={onChange}
        />
        /
        <DateFieldInput
          id='quote-field-dateInputYear'
          placeholder='YYYY'
          type='tel'
          maxLength={4}
          ref={yearRef}
          onKeyUp={onYearKeyUp}
          onKeyDown={onYearKeyDown}
          onBlur={onBlurYear}
          value={getYearValue()}
          textColor={textColor}
          disabled={disabled}
          onChange={onChange}
        />
        <IconContainer>{Icon && <Icon />}</IconContainer>
      </Container>
    </FieldContainer>
  );
};

export default DateInput;
