import React from 'react';
import { Field, FormErrors, InjectedFormProps } from 'redux-form';
import { SummaryLine, InformationBox } from '@rentecarlo/component-library';

import ReduxFieldValidation from '@services/formValidation';

import QuoteStep from '@templates/QuoteStep';

import SummaryBox from '@molecules/blocks/SummaryBox';
import ModalComponent from '@molecules/modals/ModalComponent';

import SectionTitle from '@atoms/titles/componentTitles/SectionTitle';
import Group from '@atoms/layout/form/Group';
import TextInput from '@atoms/inputs/TextInputField';
import { ComponentType, useComponentId, useOptimizelyFlag } from '@hooks';

export interface DriverDetailsSummaryProps {
  address: string[];
  birthdate: string;
  canEdit: boolean;
  driverFullName: string;
  edit: () => void;
  email: string;
  licenceNumber: string;
  occupation: string;
  phone: string;
  hasEditedPersonalDetails: boolean;
  isLoggedIn: boolean;
  confirmAction: (
    props: DriverDetailsSummaryProps,
    formValues: Record<string, string>,
    mobileNumberUpdated: boolean,
    directOtcSubsToDrivingProfile: boolean,
  ) => void;
  onCancel: () => void;
  onAccept: (props: DriverDetailsSummaryProps, directOtcSubsToDrivingProfile: boolean) => void;
  display30DayEditModal: boolean;
  productType: string;
  formErrors: FormErrors<object, string>;
  licenceType: string;
  paymentMethod: string;
}

const DriverDetailsSummary: React.FC<DriverDetailsSummaryProps & InjectedFormProps> = (props) => {
  const creator = useComponentId();
  const redirectOtcSubsToDrivingProfile = useOptimizelyFlag('TEMP_ENABLE_DRIVING_PROFILE_OTC_SUBS');
  const {
    address,
    birthdate,
    canEdit,
    confirmAction,
    display30DayEditModal,
    driverFullName,
    edit,
    email,
    licenceNumber,
    occupation,
    onAccept,
    onCancel,
    phone,
    productType,
    handleSubmit,
    formErrors,
    submitFailed,
    valid,
  } = props;

  return (
    <>
      <ModalComponent
        show={display30DayEditModal}
        okLabel='Continue'
        onAccept={() => onAccept(props, redirectOtcSubsToDrivingProfile.enabled)}
        onCancel={() => onCancel()}
        id={creator(ComponentType.COMPONENT, '30DayPopUp')}
      >
        Please note that you can only change your profile information once every 30 days, so it is
        important that you have submitted the correct information on these pages
      </ModalComponent>
      <QuoteStep
        id={creator(ComponentType.COMPONENT, 'quoteStep')}
        includeStepPrefix={false}
        nextAction={handleSubmit((values: Record<string, string>) => {
          const { phoneNumber } = values;
          const mobileNumberUpdated = !!(phoneNumber && phoneNumber !== phone);
          confirmAction(
            props,
            values,
            mobileNumberUpdated,
            redirectOtcSubsToDrivingProfile.enabled,
          );
        })}
        nextLabel='Confirm'
        stepTitle='Driver details'
        stepSubtitle='Here’s the information we have about you, check the details below to see if they are all
        still correct.'
        form
        submitError={submitFailed && !valid}
        formErrors={formErrors}
      >
        <SummaryBox
          title='Your details'
          pillButtonAction={() => edit()}
          id={creator(ComponentType.BUTTON, 'edit')}
          hidePillButton={!canEdit}
        >
          <SummaryLine
            label='Name'
            values={[driverFullName]}
            id={creator(ComponentType.TEXT, 'driverName')}
          />
          <SummaryLine
            label='Date of birth'
            values={[birthdate]}
            id={creator(ComponentType.TEXT, 'driverDob')}
          />
          <SummaryLine
            label='Phone'
            values={[phone]}
            id={creator(ComponentType.TEXT, 'driverNumber')}
          />
          <SummaryLine
            label='Email'
            values={[email]}
            id={creator(ComponentType.TEXT, 'driverEmail')}
          />
          <SummaryLine
            label='Occupation'
            values={[occupation]}
            id={creator(ComponentType.TEXT, 'driverOccupation')}
          />
          <SummaryLine
            label='Address'
            values={[...address]}
            id={creator(ComponentType.TEXT, 'driverAddress')}
          />
          <SummaryLine
            label='Licence number'
            values={[licenceNumber]}
            id={creator(ComponentType.TEXT, 'driverLicenceNumber')}
          />
        </SummaryBox>
        {productType === 'newdriver' && (
          <form>
            <Group>
              <SectionTitle id={creator(ComponentType.TITLE)}>
                Driver’s mobile phone number
              </SectionTitle>
              <Field
                id={creator(ComponentType.FIELD, 'phoneNumber')}
                name='phoneNumber'
                type='tel'
                placeholder='e.g. 07123456789'
                component={TextInput}
                validate={[ReduxFieldValidation.isMobileNumber]}
                showInitialError
              />
              <InformationBox
                id={creator(ComponentType.FIELD, 'mobileImportantInformation')}
                type='important'
              >
                Please make sure that the driver’s mobile number is correct as we will use this to
                activate the New Driver app.
              </InformationBox>
            </Group>
          </form>
        )}
      </QuoteStep>
    </>
  );
};

export default DriverDetailsSummary;
