import { push } from 'connected-react-router';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import moment from 'moment';
import { CognitoClient } from '@rentecarlo/node-amplify-client';

import { logout as logoutApi } from '@services/api';
import { eraseCookie } from '@services/cookies';
import isInQuote from '@services/isInQuote';
import { isUserNotLoggedInError } from '@utils/errors';
import { types, logout, loginSuccess, logoutSuccess } from '../reducer/account/login.actions';
import { types as initTypes } from '../init';
import {
  CUSTOMER_FETCH_ME_FAILURE,
  CUSTOMER_SAVE_FAILURE,
} from '../reducer/account/customer.actions';
import { types as registerTypes } from '../reducer/account/register.actions';
import { types as configTypes } from '../reducer/config/actions';
import { types as paymentTypes } from '../reducer/quote/payment.actions';
import { setLegalField } from '../reducer/quote/legal.actions';

import handleError from '../utils/errorHandler';

export const getLoggedIn = (state) => state.account.login.loggedIn;
export const getLoginEmail = (state) => state.account.login.email;
export const getLoginPassword = (state) => state.account.login.password;
export const getTokenExpiration = (state) => state.account.login.tokenExpiration;
export const getPostLoginPage = (state) => state.account.login.postLoginPage;
export const getCurrentLoginPoint = (state) => state.quote.legal.loginPoint;
export const getProductType = (state) => state.quote.product.productType;

export function* logoutSaga(action) {
  try {
    yield call(CognitoClient.signOut);
  } catch (e) {
    handleError(e);
    try {
      // Customer should automatically log out if legacy
      // but just incase log them out if they are not a cognito user
      yield call(logoutApi);
    } catch (error) {
      // do nothing
    }
  }
  eraseCookie('customer_uuid');
  yield put(push(action.redirect));

  yield put(logoutSuccess());
}

export function* logoutOnErrorSaga(action) {
  if (action.error && action.error.status === 401) yield [put(logout('/account/login'))];
}

export function* logoutOnTokenExpiredSaga() {
  const tokenExpiration = yield select(getTokenExpiration);
  if (tokenExpiration && tokenExpiration.isBefore(moment())) yield put(logout());
}

export function* loginSaga() {
  const product = yield select(getProductType);
  if (product === 'newdriver' && !window.location.href.includes('product=newdriver')) {
    window.location.assign(
      `${process.env.REACT_APP_LOGIN_URL}/?redirect=${window.location.href}?product=${product}`,
    );
  } else {
    window.location.assign(`${process.env.REACT_APP_LOGIN_URL}/?redirect=${window.location.href}`);
  }
}

export function* checkLoggedInSaga() {
  try {
    const session = yield call(() => CognitoClient.getCurrentSession());
    if (session.isValid) {
      yield put(loginSuccess());
    } else {
      yield put(logout('/start'));
    }
  } catch (error) {
    // Only raise error if not No current user
    if (!isUserNotLoggedInError(error)) {
      handleError(error);
      yield put(logout('/start'));
    }
    // LoggedIn state should be false if getCurrentSession fails
    const isLoggedIn = yield select(getLoggedIn);
    if (isLoggedIn) {
      yield put(logout('/start'));
    }
  }
}

export function* quoteLoginPointSaga() {
  let loginPoint = yield select(getCurrentLoginPoint) || '';
  if (document.referrer.includes(process.env.REACT_APP_LOGIN_URL)) {
    loginPoint = window.location.pathname;
  }
  if (isInQuote(loginPoint)) {
    yield put(setLegalField('loginPoint', loginPoint));
  } else {
    yield put(setLegalField('loginPoint', 'before quote'));
  }
}

export function* signOutLegacyUsersSaga() {
  const isLoginReferrer = document.referrer.includes(
    process.env.NODE_ENV === 'preprod' ? 'login.preprod-veygo.com' : 'login.veygo.com',
  );
  const isLoggedIn = yield select(getLoggedIn);

  if (!isLoginReferrer) {
    if (window.location.pathname === '/start') {
      if (isLoggedIn) {
        try {
          yield call(() => CognitoClient.getCurrentSession());
        } catch (error) {
          yield put(logout());
        }
      }
    }
  }
}

export function* watchLogin() {
  yield takeEvery(types.LOGIN_REQUEST, loginSaga);
  yield takeEvery(types.LOGIN_SUCCESS, quoteLoginPointSaga);
  yield takeEvery(types.LOGIN_SUCCESS, signOutLegacyUsersSaga);
}

export function* watchLogout() {
  yield takeEvery(initTypes.INIT, logoutOnTokenExpiredSaga);
  yield takeEvery(initTypes.CHECK_SESSION, checkLoggedInSaga);
  yield takeEvery(types.LOGOUT_REQUEST, logoutSaga);
  yield takeEvery(
    [
      CUSTOMER_FETCH_ME_FAILURE,
      CUSTOMER_SAVE_FAILURE,
      registerTypes.REGISTER_FAILURE,
      configTypes.CONFIG_OCCUPATION_FAILURE,
      paymentTypes.PAYMENT_FAILURE,
    ],
    logoutOnErrorSaga,
  );
}
