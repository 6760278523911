import { combineReducers } from 'redux';

import ancillary from './ancillary';
import car from './car';
import claims from './claims';
import driver from './driver';
import duration from './duration';
import importantInformation from './importantInformation';
import indicativePrice from './indicativePrice';
import legal from './legal';
import licence from './licence';
import newdriver from './newdriver';
import noClaimsBonus from './noClaimsBonus';
import owner from './owner';
import payment from './payment';
import product from './product';
import purchasedQuote from './purchasedQuote';
import save from './save';
import tempcover from './tempcover';
import user from './user';
import alternativeProducts from './alternativeProducts';

const QuoteReducer = combineReducers({
  alternativeProducts,
  ancillary,
  car,
  claims,
  driver,
  duration,
  importantInformation,
  indicativePrice,
  legal,
  licence,
  newdriver,
  noClaimsBonus,
  owner,
  payment,
  product,
  purchasedQuote,
  save,
  tempcover,
  user,
});

export default QuoteReducer;

export interface QuoteState extends ReturnType<typeof QuoteReducer> {}
