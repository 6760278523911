import * as React from 'react';
import styled from 'styled-components';

import PillButton from '@atoms/buttons/PillButton';
import colors from '@config/colors';
import fonts from '@config/fonts';

const ChildContainer = styled.div`
  margin-top: 36px;
`;

const Container = styled.div`
  border: 0.5px solid ${colors.grey.lines.light};
  border-radius: 8px;
  background-color: ${colors.white};
  padding: 24px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
  margin-top: 16px;
  margin-bottom: 30px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Title = styled.div`
  color: #364344;
  font-family: ${fonts.title};
  font-size: 28px;
  font-weight: bold;
  line-height: 34px;
`;

interface Props {
  title: string;
  pillButtonAction: () => void;
  hidePillButton?: boolean;
  id: string;
  children: React.ReactNode;
}

const SummaryBox: React.FC<Props> = ({
  title,
  pillButtonAction,
  hidePillButton = false,
  id,
  children,
}) => (
  <Container>
    <Header>
      <Title id='quote-title-driverDetailsSummaryBox'>{title}</Title>
      <PillButton onClick={pillButtonAction} isLocked={hidePillButton} id={id}>
        Edit
      </PillButton>
    </Header>
    <ChildContainer>{children}</ChildContainer>
  </Container>
);

export default SummaryBox;
