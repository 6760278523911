/* eslint-disable camelcase */
import { UnknownAction } from 'redux';
import { Moment } from 'moment-timezone';
// Types

export enum ActionTypes {
  IMMEDIATE_START = 'IMMEDIATE_START',
  RESET_DURATION = 'RESET_DURATION',
  SET_DURATION_START = 'SET_DURATION_START',
  SET_DURATION_END = 'SET_DURATION_END',
  SAVE_QUOTE_SUCCESS = 'SAVE_QUOTE.SUCCESS',
}

export interface ImmediateStartAction {
  type: ActionTypes.IMMEDIATE_START;
  value: boolean;
}

export interface ResetDurationAction extends UnknownAction {
  type: ActionTypes.RESET_DURATION;
}

export interface SetDurationStartAction {
  type: ActionTypes.SET_DURATION_START;
  value: Moment | null;
}

export interface SetDurationEndAction {
  type: ActionTypes.SET_DURATION_END;
  value: Moment | null;
}

export interface SaveQuoteSuccess {
  type: ActionTypes.SAVE_QUOTE_SUCCESS;
  data: {
    duration_start: Moment;
    duration_end: Moment;
  };
}

// Actions
export const isStartImmediate = (value: boolean): ImmediateStartAction => ({
  type: ActionTypes.IMMEDIATE_START,
  value,
});

export const resetDuration = (): ResetDurationAction => ({
  type: ActionTypes.RESET_DURATION,
});

export const setDurationStart = (value: Moment | null): SetDurationStartAction => ({
  type: ActionTypes.SET_DURATION_START,
  value: value ? value.clone() : null,
});

export const setDurationEnd = (value: Moment | null): SetDurationEndAction => ({
  type: ActionTypes.SET_DURATION_END,
  value: value ? value.clone() : null,
});

export type DurationAction =
  | ImmediateStartAction
  | ResetDurationAction
  | SetDurationStartAction
  | SetDurationEndAction
  | SaveQuoteSuccess;
