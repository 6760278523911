import React from 'react';
import styled from 'styled-components';
import colors from '@config/colors';
import { Moment } from 'moment';

const DateFieldContainer = styled.div`
  display: flex;
  flex: 1;
  height: 65px;
  margin: 0 -4px 16px;
`;

const DateField = styled.input`
  color: ${colors.outerSpace};
  border: 0.5px solid ${colors.grey.lines.light};
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  flex-grow: 1;
  font-family: 'proxima-soft';
  font-size: 16px;
  font-weight: bold;
  height: 65px;
  margin: 0 4px 0;
  position: relative;
  text-align: center;
  width: 0;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : null)};
  background-color: ${(props) => (props.disabled ? colors.grey.background.disabled : 'inherit')};
`;

interface Props {
  date: Moment;
}

const DateInput: React.FC<Props> = ({ date }) => {
  const day = date.format('DD');
  const month = date.format('MM');
  const year = date.format('YYYY');

  return (
    <DateFieldContainer>
      <DateField placeholder='DD' value={day} type='number' disabled />
      <DateField placeholder='MM' value={month} type='number' disabled />
      <DateField placeholder='YYYY' value={year} type='number' disabled />
    </DateFieldContainer>
  );
};

export default DateInput;
