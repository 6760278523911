import { takeEvery, select, put } from 'redux-saga/effects';
import moment from 'moment';

import { isForbiddenDuration } from '@services/date';

import { ActionTypes } from '../reducer/quote/duration.actions';
import { saveQuote } from '../reducer/quote/save.actions';

const getStartDateTime = (state) => state.quote.duration.startDateTime;
const getEndDateTime = (state) => state.quote.duration.endDateTime;

export function* saveOnValidDurationChange() {
  const startDateTime = yield select(getStartDateTime);
  const endDateTime = yield select(getEndDateTime);

  if (
    moment.isMoment(startDateTime) &&
    moment.isMoment(endDateTime) &&
    endDateTime.isAfter(startDateTime) &&
    !isForbiddenDuration(startDateTime.format(), endDateTime.format())
  ) {
    yield put(saveQuote());
  }
}

export function* watchDuration() {
  yield takeEvery(
    [ActionTypes.SET_DURATION_START, ActionTypes.SET_DURATION_END],
    saveOnValidDurationChange,
  );
}
