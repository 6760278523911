import moment from 'moment';
import deepUpdate from '../../../services/deepUpdate';
import { OwnerActions, types } from './owner.actions';
import { types as quoteTypes, QuoteActions } from './actions';
import { types as userTypes } from './user.actions';

import { types as loginTypes, LoginActiontypes } from '../account/login.actions';
import { PreferredCar, State } from './ownerTypes';

// Reducer
export const initialState: State = {
  email: '',
  firstName: '',
  surname: '',
  phoneNumber: '',
  occupation: '',
  occupationType: '',
  postcode: '',
  birthdate: null,
  save: false,
  relationship: '',
};

const getOwnerFromPreferredCar = (preferredCar: PreferredCar) => ({
  email: preferredCar.car.owner.email,
  firstName: preferredCar.car.owner.firstName,
  surname: preferredCar.car.owner.lastName,
  phoneNumber: preferredCar.car.owner.phoneNumber,
  occupation: preferredCar.car.owner.occupation,
  occupationType: preferredCar.car.owner.occupationType,
  birthdate: preferredCar.car.owner.birthdate ? moment(preferredCar.car.owner.birthdate) : null,
  postcode: preferredCar.car.owner.postcode,
  relationship: preferredCar.relationship,
});

type OwnerActionTypes = OwnerActions | QuoteActions | LoginActiontypes;

export default function owner(state = initialState, action: OwnerActionTypes): State {
  switch (action.type) {
    case types.SET_OWNER_FIELD:
      return deepUpdate(state, action.field, action.value);
    case types.SET_OWNER_FIELDS:
      // New owner modal doesn't require postcode
      if (action.featureToggled) {
        return {
          ...state,
          ...action.payload,
        };
      }
      return {
        ...state,
        ...action.payload,
        //  Having to disable because
        // eslint-disable-next-line no-nested-ternary
        postcode:
          action.payload.postcode !== undefined
            ? action.payload.postcode.toUpperCase()
            : state.postcode,
      };
    case userTypes.PREFILL_OWNER:
    case userTypes.PREFILL_OWNER_ON_EDIT:
      return {
        ...state,
        birthdate: moment(action.data.customer.birthdate),
        email: action.data.customer.email,
        firstName: action.data.customer.firstName,
        occupation: action.data.customer.occupation,
        occupationType: action.data.customer.occupationType,
        phoneNumber: action.data.customer.phoneNumber,
        postcode: action.data.customer.postcode,
        surname: action.data.customer.lastName,
      };
    case userTypes.PREFILL_CONNECTION:
      return {
        ...state,
        ...getOwnerFromPreferredCar(action.preferredCar),
      };
    case loginTypes.LOGOUT_SUCCESS:
    case quoteTypes.RESET_QUOTE:
      return initialState;
    default:
      return state;
  }
}
