import { types as quoteTypes, QuoteActions } from './actions';
import deepUpdate from '../../../services/deepUpdate';

import { types as loginTypes, LoginActiontypes } from '../account/login.actions';
import { Payment } from './paymentTypes';
import { types as paymentTypes, PaymentActions } from './payment.actions';

// Reducer
export const initialState: Payment = {
  termsAndConditionsAccepted: false,
  checksPassed: false,
  confirmationToken: null,
  billingAddress: {
    isManual: false,
    country: 'GBR',
    county: '',
    flatNumber: '',
    houseName: '',
    houseNumber: '',
    line1: '',
    line2: '',
    postcode: '',
    road: '',
    town: '',
    lookupId: '',
    udprn: '',
    lookedUp: false,
    key: '',
    reuseDriverAddress: null,
  },
  cardHolderType: '',
  cardHolderEmail: '',
  cardNumber: '',
  cardHolderName: '',
  cardCvc: '',
  cardExpiry: {
    month: '',
    year: '',
  },
  discountCode: '',
  loading: false,
  paymentText: '',
  error: null,
  errorCode: null,
  declinedCode: null,
  paid: false,
  maxPaymentTriesReached: false,
  paymentMethod: '',
  paymentIntent: { clientSecret: null, status: null },
  paperlessDelivery: true,
  deliveryPreference: 'postal',
};

type PaymentActionTypes = PaymentActions | QuoteActions | LoginActiontypes;

export default function payment(
  state: Payment = initialState,
  action = {} as PaymentActionTypes,
): Payment {
  switch (action.type) {
    case paymentTypes.SET_PAYMENT_FIELD:
      return deepUpdate(state, action.field, action.value);
    case paymentTypes.PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        paid: true,
        error: null,
        paymentText: '',
      };
    case paymentTypes.PAYMENT_FAILURE:
      return {
        ...state,
        paymentText: '',
        loading: false,
        error: action.error,
        errorCode: action.errorCode,
        declinedCode: action.declinedCode,
      };
    case paymentTypes.RESET_PAYMENT_ERROR:
      return {
        ...state,
        error: null,
        errorCode: null,
        declinedCode: null,
      };
    case paymentTypes.SET_BILLING_ADDRESS_FIELDS:
      return {
        ...state,
        billingAddress: {
          ...state.billingAddress,
          ...action.data,
        },
      };
    case paymentTypes.SET_REUSE_DRIVER_ADDRESS:
      return {
        ...state,
        billingAddress: {
          ...state.billingAddress,
          reuseDriverAddress: action.value,
        },
      };
    case loginTypes.LOGOUT_SUCCESS:
    case quoteTypes.RESET_QUOTE:
      return initialState;
    case paymentTypes.SET_PAPERLESS_DELIVERY:
      return {
        ...state,
        paperlessDelivery: action.value,
      };
    case paymentTypes.SET_DELIVERY_PREFERENCE:
      return {
        ...state,
        deliveryPreference: action.value,
      };

    case paymentTypes.SET_TERMS_AND_CONDITIONS:
      return {
        ...state,
        termsAndConditionsAccepted: true,
      };
    case paymentTypes.PAYMENT_REQUEST_FINAL_CHECKS:
      return {
        ...state,
      };
    case paymentTypes.PAYMENT_FINAL_CHECKS_SUCCESS:
      return {
        ...state,
        checksPassed: true,
      };
    case paymentTypes.PAYMENT_FINAL_CHECKS_FAILED:
      return {
        ...state,
        checksPassed: false,
      };
    default:
      return state;
  }
}
