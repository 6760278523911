import { useSelector } from 'react-redux';
import { useDecision, OptimizelyDecision } from '@optimizely/react-sdk';

import { optimizelyOverrideAttributes } from '@reselectors/optimizely';

export interface OptimizelyFlag extends OptimizelyDecision {
  clientReady: boolean;
}

const useOptimizelyFlag = (featureFlag: string): OptimizelyFlag => {
  const overrideAttributes = useSelector(optimizelyOverrideAttributes);

  const [decision, clientReady] = useDecision(
    featureFlag,
    {},
    {
      overrideUserId: overrideAttributes.user_identity,
      overrideAttributes,
    },
  );
  return { ...decision, clientReady };
};

export default useOptimizelyFlag;
