import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, getFormSyncErrors, FormErrors } from 'redux-form';

import { saveAndGoTo } from '@redux/reducer/quote/save.actions';
import { setNewDriverField } from '@redux/reducer/quote/newdriver.actions';
import { RootState } from '@redux/reducer';
import DrivingProfile from './DrivingProfile';

interface StateProps {
  formErrors: FormErrors<unknown, string>;
}

const mapStateToProps = (state: RootState): StateProps => ({
  formErrors: getFormSyncErrors('drivingProfileForm')(state),
});

interface DispatchProps {
  submission: (values: Record<string, string>) => void;
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  submission: (values: Record<string, string>) => {
    dispatch(setNewDriverField('expectedMileage', values.expectedMileage));
    dispatch(setNewDriverField('overnightLocation', values.overnightLocation));
    dispatch(setNewDriverField('hasAlternateVehicle', values.hasAlternateVehicle));
    dispatch(
      setNewDriverField(
        'typeOfUsage',
        values.hasAlternateVehicle === 'true' ? values.typeOfUsage : null,
      ),
    );
    return dispatch(saveAndGoTo('driving-history'));
  },
});

export interface ComponentProps extends StateProps, DispatchProps {}

const DrivingProfileForm = reduxForm({
  form: 'drivingProfileForm',
})(DrivingProfile);

export default connect(mapStateToProps, mapDispatchToProps)(DrivingProfileForm);
