/* eslint-disable no-undef */
import * as React from 'react';
import isPlainObject from 'lodash/isPlainObject';
import forEach from 'lodash/forEach';
import intersection from 'lodash/intersection';
import moment from 'moment';

import Stripe from './stripe';

const exists = (fieldValue) => {
  // return boolean whether the field is not null or empty, or whether all its attributes are not null or empty in the case of an object
  if (isPlainObject(fieldValue)) {
    let result = true;
    forEach(fieldValue, (value) => {
      if (!exists(value)) result = false;
    });

    return result;
  }
  return fieldValue !== null && fieldValue !== '';
};

const blockedPostcode = (value) => {
  const upperValue = value.toUpperCase();
  const isBF = /^BF[a-zA-Z0-9 ]{3,6}$/.test(upperValue);
  const isGuernsey = /^GY[a-zA-Z0-9 ]{3,6}$/.test(upperValue);
  const isIsleOfMan = /^IM[a-zA-Z0-9 ]{3,6}$/.test(upperValue);
  const isJersey = /^JE[a-zA-Z0-9 ]{3,6}$/.test(upperValue);
  return isBF || isGuernsey || isIsleOfMan || isJersey;
};

export const validationTests = {
  isTrue: (value) => !!value,
  required: (value) => exists(value),
  requiredDependent: ({ value, dependent }) => !dependent || exists(value),
  phonenumber: (value) => /^[0-9 ]{9,12}$/.test(value) || value === '',
  postcode: (value) => /^[a-zA-Z0-9 ]{5,8}$/.test(value),
  blockedPostcode: (value) => !blockedPostcode(value),
  // eslint-disable-next-line no-useless-escape
  email: (value) =>
    value === '' ||
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      value,
    ),
  secured: (value) => /^(?=.*[A-Za-z])(?=.*\d).{6,}$/.test(value),
  maxLength20: (value) => value.length <= 20,
  validSecurityAnswer: (value) => value.length >= 4 && value.length <= 50,
  manualRequired: (address) => !address.isManual || validationTests.required(address.road),
  addressNumberRequired: (address) =>
    !address.isManual || exists(address.houseName) || exists(address.houseNumber),
  sixtyDays: (startDate) => moment().add(60, 'days').isAfter(startDate),
  validCoverDuration: ({ startDate, endDate, immediateStart, startTime, endTime, licenceType }) => {
    if (!exists(startDate) || !exists(endDate)) return true;
    const startDateMoment = !immediateStart
      ? moment(startDate)
          .hour(parseInt(startTime.hour))
          .minute(parseInt(startTime.minute))
          .second(0)
      : moment();
    const endDateMoment = moment(endDate)
      .hour(parseInt(endTime.hour))
      .minute(parseInt(endTime.minute) + 1)
      .second(0);

    if (licenceType === 'uk_prov') {
      return !(
        moment(startDateMoment).add(2160, 'hours').isBefore(endDateMoment) ||
        moment(endDateMoment).subtract(2, 'hours').isBefore(startDateMoment) ||
        moment(startDateMoment).isBefore(moment(), 'hour')
      );
    }
    return !(
      moment(startDateMoment).add(720, 'hours').isBefore(endDateMoment) ||
      moment(endDateMoment).subtract(1, 'hours').isBefore(startDateMoment) ||
      moment(startDateMoment).isBefore(moment(), 'hour')
    );
  },
  validExtendCoverDuration: ({ originalStartDate, newEndDate, newEndTime, licenceType }) => {
    const startDate = originalStartDate;
    const endDate = moment(newEndDate)
      .hour(parseInt(newEndTime.hour))
      .minute(parseInt(newEndTime.minute) + 1)
      .second(0);
    return !(
      moment(startDate)
        .add(licenceType === 'ukp' ? 90 : 30, 'days')
        .isBefore(endDate) || moment(endDate).subtract(1, 'hours').isBefore(startDate)
    );
  },
  hourlyCoverValidation: (durationObject) => {
    if (!exists(durationObject.startDate) || !exists(durationObject.endDate)) return true;

    const startDate = moment(durationObject.startDate)
      .hour(parseInt(durationObject.startTime.hour))
      .minute(parseInt(durationObject.startTime.minute))
      .second(0);
    const endDate = moment(durationObject.endDate)
      .hour(parseInt(durationObject.endTime.hour))
      .minute(parseInt(durationObject.endTime.minute))
      .second(0);

    return !(
      (startDate.hour() >= 22 || startDate.hour() <= 4) &&
      moment(startDate).add(23, 'hours').add(58, 'minutes').isAfter(moment(endDate))
    );
  },
  match: (values) => values[1] && values[0] === values[1],
  validLicenceNumber: (licenceNumber) =>
    /^[A-Z0-9]{5} ?\d[0156]\d([0][1-9]|[12]\d|3[01])\d ?[A-Z0-9]{3}[A-Z]{2}$/.test(licenceNumber),
  validCardNumber: (...args) => Stripe.card.validateCardNumber(...args),
  validCardExpiry: (date) => Stripe.card.validateExpiry(date.month, date.year),
  validCardCVC: (...args) => Stripe.card.validateCVC(...args),
  occupation: (value) => value.length >= 2,
  licenceDobValid: ({ number, dob }) => {
    if (!number || !dob) return false;

    const formatedNumber = number.replace(/ /g, '');
    const licenceYear = formatedNumber[5] + formatedNumber[10];
    const licenceMonth =
      parseInt(formatedNumber.substring(6, 8)) > 12
        ? parseInt(formatedNumber[6]) - 5 + formatedNumber[7]
        : formatedNumber.substring(6, 8);
    const licenceDate = formatedNumber.substring(8, 10);
    const licenceDob = `${licenceYear}-${licenceMonth}-${licenceDate}`;
    return licenceDob === dob.format('YY-MM-DD');
  },
  licenceDateRequired: ({ fullLicenceConfirmed, fullLicenceDate }) =>
    !fullLicenceConfirmed || !!fullLicenceDate,
  maximumAge: (age) => age.length <= 2,
  minimumAge: (age) => age >= 17,
};

export const getErrorsForField = (validationObject, inputValue, eventTypes) => {
  const errors = {};
  if (validationObject) {
    // If there is a validation for this field
    forEach(validationObject, (errorObject, validationType) => {
      // For each validation rule about this field
      if (intersection(errorObject.events, eventTypes).length > 0) {
        // If there is a validation rule for at least one of the event in eventTypes
        const hasPassedValidation = validationTests[validationType](inputValue); // Do the validation
        errors[errorObject.label] = hasPassedValidation
          ? null
          : { title: errorObject.title, text: errorObject.text, children: errorObject.children }; // Set the error message if the validation has failed
      }
    });
  }

  return errors;
};

const isDefined = (value) => value !== null && value !== undefined && value !== '';

const matchesNameRegex = (name) => /^[A-Za-zÀ-ÿ][A-Za-zÀ-ÿ-\s]+$/.test(name);

const containsNumberRegex = (name) => /\d+/.test(name);

const isName = (value) => {
  if (value && matchesNameRegex(value) && !containsNumberRegex(value)) {
    return null;
  }
  return 'Please enter a valid name';
};

const matchesEmailRegex = (value) =>
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    value,
  );
const matchesPhoneRegex = (value) => /^[0-9 ]{9,12}$/.test(value);

export const matchesMobileRegex = (value) => /^(07\d{3}[ ]?\d{6})$/.test(value);

const invalidPostcodeMessage = 'Oops! Please enter a valid postcode';
/*
  Postcode regex now allows all valid UK postcodes with a special case for GIR OAA. Prevents invalid postcdes making it to equifax.
  Rules found at: https://www.mrs.org.uk/pdf/postcodeformat.pdf
 */
const matchesPostcodeRegex = (value) =>
  /^(([gG][iI][rR]\s{0,1}0[aA]{2})|((([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y]?[0-9][0-9]?)|(([a-pr-uwyzA-PR-UWYZ][0-9][a-hjkstuwA-HJKSTUW])|([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y][0-9][abehmnprv-yABEHMNPRV-Y])))\s{0,1}[0-9][abd-hjlnp-uw-zABD-HJLNP-UW-Z]{2}))$/.test(
    value,
  );
const isCoveredPostcode = (value) => {
  const formattedValue = value?.trim();
  // eslint-disable-next-line no-nested-ternary
  return isDefined(formattedValue) &&
    matchesPostcodeRegex(formattedValue) &&
    !blockedPostcode(formattedValue)
    ? undefined
    : blockedPostcode(formattedValue)
    ? 'Sorry, we are unable to provide cover in that area.'
    : invalidPostcodeMessage;
};
const securePasswordRegex = /^(?=.*[A-Za-z])(?=.*\d).{6,}$/;

const dobMatchDln = (value, allValues, props) => {
  const { birthdate } = props;
  const licenceNumberDOBPart = value;

  if (!licenceNumberDOBPart || !birthdate) {
    return 'Please enter a valid Driving Licence Number';
  }
  const formatedNumber = licenceNumberDOBPart.replace(/ /g, '');
  const licenceYear = formatedNumber[0] + formatedNumber[5];
  const licenceMonth =
    parseInt(formatedNumber.substring(1, 3)) > 12
      ? parseInt(formatedNumber[1]) - 5 + formatedNumber[2]
      : formatedNumber.substring(1, 3);
  const licenceDate = formatedNumber.substring(3, 5);
  const licenceDob = `${licenceYear}-${licenceMonth}-${licenceDate}`;
  if (licenceDob !== birthdate.format('YY-MM-DD')) {
    return 'Your Driving Licence Number does not match the date of birth you have provided. Please make sure that all details are correct.';
  }

  return undefined;
};

const isValidDOB = (value) => {
  if (!(moment.isMoment(value) && value.isValid())) {
    return 'Oops! Please enter a valid date of birth';
  }
  return undefined;
};

const isOver16 = (value) => {
  const lowerAgeLimit = 16;
  const upperAgeLimit = 75;
  if (moment().diff(value, 'y') < lowerAgeLimit || moment().diff(value, 'y') > upperAgeLimit) {
    return 'Unfortunately our underwriting criteria for insurance is minimum 16 and maximum 75 years old 🙁';
  }
  return undefined;
};

const isOldEnoughDOB = (value, _allValues, props) => {
  const { quoteStart } = props;
  if (!quoteStart) return undefined;

  const ageLimit = 17;
  if (value.isAfter(quoteStart.clone().subtract(ageLimit, 'year'))) {
    return 'Oops! The driver must be 17+ years old when the policy begins';
  }
  return undefined;
};

const matchesDLNRegexSurnamePart = (value) => /^[A-Z9]{5}$/.test(value);
const matchesDLNRegexDOBPart = (value) => /^\d[0156]\d([0][1-9]|[12]\d|3[01])\d$/.test(value);
const matchesDLNRegexFinalPart = (value) => /^[A-Z9]{1}[A-Z9]{1}[0-9]{1}[A-Z]{2}$/.test(value);

const DLNErrorMessage =
  'The Driving Licence Number you have provided is not valid. Please check that you have entered the right 16 Driving Licence Number characters from the front of your licence.';

const isValidADI = (text) =>
  (text.length === 5 || text.length === 6) && /^[a-z0-9]+$/i.test(text)
    ? undefined
    : 'Your ADI number must be 5 or 6 alphanumeric characters';

const isOwnerOver16 = (value) => {
  const ageLimit = 16;
  if (moment().diff(value, 'y') < ageLimit) {
    return 'Sorry, the owner of the vehicle must be at least 16 years old to purchase a policy.';
  }
  return undefined;
};

const isDriverOwnerAgeGapEnough = (driverDob, ownerDob, relationship) => {
  if (relationship !== 'pa') return true;
  return Math.abs(moment(ownerDob).diff(moment(driverDob), 'years')) >= 13;
};

const validAlternativeInsurer = (insurer) =>
  insurer.replace(/\s/g, '').length >= 2
    ? null
    : 'Oops! Please enter a valid alternative insurance provider.';

const isRequired = (value, _allValues, _props, name) => {
  if (!value) {
    const result =
      {
        addressKey: 'address',
        'ncb.level': 'number of no claims bonus',
      }[name] ||
      name.replace(/([A-Z])/g, ' $1') ||
      name.replace(/([_])/g, ' ');
    const formattedName = result.charAt(0).toUpperCase() + result.slice(1).toLowerCase();
    return `${formattedName} is required`;
  }
  return null;
};

/*
  Note some fields appear as Uppercase on the website but may be Lowercase when running regex matches
*/
export default {
  isRequired,
  required: (value) => (isDefined(value) ? undefined : 'Required'),
  maxLength: (max) => (value) =>
    isDefined(value) && value.length > max ? `Must be ${max} characters or less` : undefined,
  exactLength: (charNumber) => (value) =>
    isDefined(value) && value.length !== charNumber
      ? `Must be exactly ${charNumber} characters`
      : undefined,
  isEmail: (value) =>
    isDefined(value) && matchesEmailRegex(value)
      ? undefined
      : 'Oops! Please enter a valid email address',
  isPhoneNumber: (value) =>
    isDefined(value) && matchesPhoneRegex(value)
      ? undefined
      : 'Oops! Please enter a valid phone number',
  isMobileNumber: (value) =>
    isDefined(value) && matchesMobileRegex(value)
      ? undefined
      : "Please enter a valid UK mobile number with 11 digits, starting with '07' and without spaces.",
  password: (value) =>
    isDefined(value) && !securePasswordRegex.test(value)
      ? 'This password is not secure enough, it needs to be six characters or more and use at least one letter and one digit'
      : undefined,
  isPostcode: (value) =>
    isDefined(value) && matchesPostcodeRegex(value) ? undefined : invalidPostcodeMessage,
  isCoveredPostcode,
  dobMatchDln,
  isValidDOB,
  isOver16,
  isOldEnoughDOB,
  isDateInThePast: (value) =>
    isDefined(value) && value > moment() ? `You can't use a date in the future` : undefined,
  validDLNSurnamePart: (value) => (matchesDLNRegexSurnamePart(value) ? undefined : DLNErrorMessage),
  validDLNDOBPart: (value) => (matchesDLNRegexDOBPart(value) ? undefined : DLNErrorMessage),
  validDLNFinalPart: (value) => (matchesDLNRegexFinalPart(value) ? undefined : DLNErrorMessage),
  validAlternativeInsurer,
  isTrue: (value) => (value === 'true' || value === true ? undefined : 'Must be true'),
  isValidADI,
  isOwnerOver16,
  validateRelationshipAgainstAge: (value, fields, props) => {
    const { driverDob } = props;
    const { relationship } = fields;

    if (isDefined(value) && !isDriverOwnerAgeGapEnough(driverDob, value, relationship)) {
      const url = 'https://www.veygo.com/contact/';

      return (
        <span id='car-text-carOwnerRelationship'>
          {' '}
          OK, no biggy, but the parent/child ages have thrown us a bit. Could you have another look
          and drop us line at{' '}
          <a id='car-link-contactUs' href={url}>
            {url}
          </a>{' '}
          if these are the correct details.
        </span>
      );
    }

    return undefined;
  },
  mustConfirmPrivacyPolicy: (value) =>
    value ? undefined : 'You must accept the privacy policy to continue.',
  emailsMatch: (value, allValues) =>
    value !== allValues.email ? 'The email addresses you have entered do not match.' : undefined,
  passwordMatch: (value, allValues) =>
    value !== allValues.password ? 'The passwords you have entered do not match.' : undefined,
  isValidCarReg: (value) => {
    if (
      value &&
      typeof value === 'string' &&
      /(^[A-Z]{2}[0-9]{2}\s?[A-Z]{3}$)|(^[A-Z][0-9]{1,3}[A-Z]{3}$)|(^[A-Z]{3}[0-9]{1,3}[A-Z]$)|(^[0-9]{1,4}[A-Z]{1,2}$)|(^[0-9]{1,3}[A-Z]{1,3}$)|(^[A-Z]{1,2}[0-9]{1,4}$)|(^[A-Z]{1,3}[0-9]{1,3}$)|(^[A-Z]{1,3}[0-9]{1,4}$)|(^[0-9]{3}[DX]{1}[0-9]{3}$)/i.test(
        value.replace(/[^a-zA-Z0-9]/g, ''),
      )
    ) {
      return undefined;
    }
    return "Hmm...that doesn't seem to be a valid registration number. Please give it another go";
  },
  isName,
  newDriverIsOwner: (value, _allValues, props) =>
    isDefined(value) && props.productType === 'newdriver' && value !== 'owner'
      ? "You must be the registered keeper on the car's V5C document to purchase New Driver insurance"
      : undefined,
};
