import React, { ReactElement, useState, useEffect, ReactNode } from 'react';
import styled from 'styled-components';
import { InformationBox, RadioButton } from '@rentecarlo/component-library';

import SubQuestion from '@molecules/SubQuestion';

interface StyledProps {
  marginBottom: string | undefined;
}

const ToggleButtonContainer = styled.div`
  display: flex;
  margin: 0 -4px ${({ marginBottom }: StyledProps): string => marginBottom || '16px'};
`;

export const errors = {
  ERROR_RIGHT: 'invalid_choice_right',
  ERROR_LEFT: 'invalid_choice_left',
};

export interface Props {
  leftLabel: string;
  rightLabel: string;
  groupName: string;
  errorMessage?: string;
  displayLeftError?: boolean;
  yesSubquestion?: ReactNode;
  noSubquestion?: ReactNode;
  input: {
    onChange: (value: string) => void;
    value: string;
  };
  meta: {
    error?: string;
  };
  marginBottom?: string;
}

const ToggleSelector = ({
  yesSubquestion,
  noSubquestion,
  leftLabel,
  rightLabel,
  groupName,
  errorMessage,
  displayLeftError,
  input: { onChange, value },
  meta: { error },
  marginBottom,
}: Props): ReactElement => {
  const [initialValue, setInitalValue] = useState(null);
  useEffect(() => {
    setInitalValue(value);
  }, [value]);
  return (
    <>
      <ToggleButtonContainer marginBottom={marginBottom}>
        <RadioButton
          id={`${groupName}-yes`}
          checked={value === 'true'}
          onSelect={(selectedValue: string): void => onChange(selectedValue)}
          initialValue={initialValue}
          text={leftLabel}
          value='true'
          displayError={error === 'invalid_choice_left' || displayLeftError}
        />
        <RadioButton
          id={`${groupName}-no`}
          checked={value === 'false'}
          onSelect={(selectedValue: string): void => onChange(selectedValue)}
          initialValue={initialValue}
          text={rightLabel}
          value='false'
          displayError={error === 'invalid_choice_right'}
        />
      </ToggleButtonContainer>
      {errorMessage !== undefined && error !== undefined && error !== 'Required' && (
        <InformationBox id={`${groupName}-text-errorMessage`} type='error'>
          {errorMessage}
        </InformationBox>
      )}
      {yesSubquestion && value === 'true' && (
        <SubQuestion id={`${groupName}-text-yesSubquestion`} value={value}>
          {yesSubquestion}
        </SubQuestion>
      )}
      {noSubquestion && value === 'false' && (
        <SubQuestion id={`${groupName}-text-noSubquestion`} value={value}>
          {noSubquestion}
        </SubQuestion>
      )}
    </>
  );
};

ToggleSelector.defaultProps = {
  leftLabel: 'YES',
  rightLabel: 'NO',
  displayLeftError: false,
};

export default ToggleSelector;
