import moment, { Moment } from 'moment-timezone';
import { types as quoteTypes, QuoteActions } from './actions';
import { types as indicativePriceTypes, IndicativePriceActions } from './indicativePrice.actions';
import { ActionTypes, DurationAction } from './duration.actions';
import { ProductActions, SET_PAYMENT_METHOD } from './product.actions';

export interface EndChoiceTypes {
  text: string;
  value: string;
  inHours: number;
  sub: string;
}

interface State {
  endChoiceSelected: EndChoiceTypes | null;
  endDateTime: Moment | null;
  immediateStart: boolean | null;
  startDateTime: Moment | null;
  hours: number | null;
}

// Reducer
export const initialState: State = {
  endChoiceSelected: null,
  endDateTime: null,
  immediateStart: null,
  startDateTime: null,
  hours: null,
};

// Any type required due to backend response from quote create/update not being typed yet.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handleImmediateStart = (state: State, data: any) => {
  if (!state.immediateStart || !data.duration_start) return state;
  // Update the start date and time with information from the server if this
  // is an 'immediate start' quote
  return {
    ...state,
    startDateTime: moment(data.duration_start),
    endDateTime: moment(data.duration_end),
  };
};

// Any type required due to other dependent action creators not being typed yet.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ListenedAction = DurationAction | QuoteActions | IndicativePriceActions | ProductActions;

export default function duration(state: State = initialState, action: ListenedAction): State {
  switch (action.type) {
    case ActionTypes.IMMEDIATE_START:
      return {
        ...state,
        immediateStart: action.value,
        startDateTime: action.value ? moment.tz('Europe/London') : null,
      };
    case ActionTypes.SET_DURATION_START:
      return {
        ...initialState,
        startDateTime: action.value,
        immediateStart: false,
        hours: null,
      };
    case ActionTypes.SET_DURATION_END:
      return {
        ...state,
        endDateTime: action.value,
        endChoiceSelected: null,
        hours: null,
      };
    case ActionTypes.RESET_DURATION:
      return initialState;
    case ActionTypes.SAVE_QUOTE_SUCCESS:
      return handleImmediateStart(state, action.data);
    case SET_PAYMENT_METHOD:
    case quoteTypes.RESET_QUOTE:
      return initialState;
    case indicativePriceTypes.INDICATIVE_PRICE_QUICK_SELECT:
      return {
        ...state,
        immediateStart: true,
        startDateTime: moment.tz('Europe/London'),
        endDateTime: moment.tz('Europe/London').add(action.duration.inHours, 'hours'),
        endChoiceSelected: action.duration,
        hours: action.duration.inHours,
      };
    default:
      return state;
  }
}
