import React from 'react';
import styled, { css } from 'styled-components';

import size from '@config/size';
import colors from '@config/colors';
import GreenTick from '@atoms/inputs/GreenTick';
import { FieldContainer } from '@rentecarlo/component-library';

const Input = styled.input`
  background-clip: padding-box;
  border-radius: 4px;
  border: 0.5px solid ${colors.grey.lines.light};
  border-right: 0px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  flex: 1;
  font-family: proxima-soft;
  font-size: 16px;
  font-weight: ${(props) => (props.hasValue ? 'bold' : '')};
  height: 65px;
  letter-spacing: 1px;
  line-height: 65px;
  margin: 0px;
  min-width: 0;
  outline: none;
  padding-left: 16px;
  text-transform: uppercase;
  text-overflow: ellipsis;
  background-color: ${(props) => (props.disabled ? colors.grey.background.disabled : 'inherit')};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'inherit')};
  ::placeholder {
    text-transform: none;
  }
  ${css`
    @media (max-width: ${(props) => props.desktopMinWidth}) {
      padding-right: 47px;
    }
  `}
`;

const FlatSideButton = styled.button`
  border: none;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  background: ${colors.blue.primary};
  color: ${colors.white};
  font-family: gotham-rounded;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  height: 65px;
  width: 120px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'inherit')};
  opacity: ${(props) => (props.disabled ? 0.6 : 1)};
`;

const Row = styled.div`
  border-radius: 4px;
  display: flex;
  justify-content: flex-start;
`;

const PostcodeSearchBox = ({
  onSearch,
  disabled,
  id,
  selectorType,
  input,
  meta,
  postcodeNotFound,
}) => {
  const { valid, touched, error, active, pristine, submitFailed } = meta;
  const { value, onChange, onBlur, onFocus } = input;
  const desktopMinWidth = size.desktopWidth;

  const getFormErrors = () => {
    const postcodeNotFoundError = (
      <div>
        <p id='payment-paragraph-postCodeSearchError'>
          Oops, we don't recognise that postcode!{' '}
          <a id='payment-link-postCodeSearchError' href='https://www.veygo.com/contact/'>
            Contact us
          </a>{' '}
          and we'll do our best to help.
        </p>
      </div>
    );
    return postcodeNotFound ? postcodeNotFoundError : error;
  };
  return (
    <div>
      <FieldContainer
        id={id}
        showErrorContainer={(submitFailed && error) || postcodeNotFound}
        showError={
          (((touched && !pristine) || submitFailed) && error && (!active || submitFailed)) ||
          postcodeNotFound
        }
        error={getFormErrors()}
      >
        <GreenTick showTick={valid} mobileRightMargin='136px' desktopMinWidth={desktopMinWidth}>
          <Row>
            <Input
              onFocus={onFocus}
              onBlur={onBlur}
              onChange={onChange}
              placeholder='Add your postcode'
              type='text'
              maxLength='8'
              value={value}
              hasValue={value !== ''}
              desktopMinWidth={desktopMinWidth}
              disabled={disabled}
              id={id}
              data-test={selectorType}
            />
            <FlatSideButton
              id='payment-button-postcodeSearch'
              data-test='postcodeSearchButton'
              onClick={(event) => {
                event.preventDefault();
                return !disabled && onSearch(value);
              }}
              disabled={!valid}
            >
              Search
            </FlatSideButton>
          </Row>
        </GreenTick>
      </FieldContainer>
    </div>
  );
};

export default PostcodeSearchBox;
