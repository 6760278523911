import { connect } from 'react-redux';
import {
  reduxForm,
  change,
  getFormSyncErrors,
  getFormValues,
  getFormInitialValues,
} from 'redux-form';
import { push } from 'connected-react-router';

import { setAddressFields, setDriverField, setDriverOwner } from '@reducer/quote/driver.actions';
import { lookupAddress } from '@reducer/lookup/actions';

import { setCarField } from '@reducer/quote/car.actions';
import { setProduct, setPaymentMethod } from '@reducer/quote/product.actions';
import { setUserField } from '@reducer/quote/user.actions';
import { saveAndGoTo, saveQuote } from '@reducer/quote/save.actions';
import { RootState } from '@redux/reducer';
import { Dispatch } from 'redux';
import { FormEventHandler } from 'react';
import QuoteStart from './QuoteStart';

interface StateProps {
  productType: string;
  licenceType: string;
  formErrors: Record<string, unknown>;
  formValues: Record<string, unknown> | Record<string, never>;
}

interface DispatchProps {
  submission: (values: Record<string, unknown>, productType: string) => void;
  submitAddressAndDob: (values: Record<string, unknown>, licenceType: string) => void;
  setProductType: (type: string, licenceType: string, productType: string) => void;
  setDriverOwner: (type: string, productType: string) => void;
}

interface MergeProps {
  setProduct: (type: string) => void;
  setDriverOwner: (type: string, productType: string) => void;
}

export interface ComponentProps extends StateProps, DispatchProps, MergeProps {
  valid: boolean;
  submitFailed: boolean;
  handleSubmit: (value: Record<string, unknown>) => FormEventHandler<HTMLFormElement> | undefined;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    // licenceType and productType would have been refactored out into the actual QuoteStart component,
    // but they're used in a weird way in the mergeProps() function below, so they've been left here for now
    licenceType: state.quote.licence.type,
    productType: state.quote.product.productType,
    // Trying to move these Redux Form props into the QuoteStart component resulted in weird behaviour
    // where certain fields wouldn't get the message that values were set, so they've been left here for now
    formErrors: getFormSyncErrors('quoteStartForm')(state),
    formValues: getFormValues('quoteStartForm')(state) || {},
    initialValues: getFormInitialValues('quoteStartForm')(state) || {},
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  submission: (values: Record<string, unknown>, productType: string) => {
    dispatch(setAddressFields({ postcode: values.postcode }));
    dispatch(setDriverField('birthdate', values.birthdate));
    dispatch(lookupAddress(values.postcode));
    dispatch(setCarField('transmission', values.car?.transmission));
    dispatch(setCarField('make', values.car?.make));
    dispatch(setCarField('model', values.car?.model));
    dispatch(setCarField('abiCode', values.car?.abi_code));
    dispatch(setCarField('engineSize', values.car?.engine_size));
    dispatch(setCarField('registrationNumber', values.carReg));
    dispatch(setCarField('year', values.car?.year));

    if (productType === 'newdriver') {
      return dispatch(saveAndGoTo('duration-of-cover'));
    }

    return dispatch(saveAndGoTo('choose-your-cover'));
  },
  submitAddressAndDob: (values: Record<string, unknown>, licenceType: string) => {
    if (licenceType === 'non_uk') {
      return dispatch(push('rejection'));
    }
    dispatch(setAddressFields({ postcode: values.postcode }));
    dispatch(setDriverField('birthdate', values.birthdate));
    dispatch(lookupAddress(values.postcode));
    dispatch(change('quoteStartForm', 'carRegistration', null));
    return dispatch(saveQuote());
  },
  setProductType: (type: string, licenceType: string, productType: string) => {
    if (productType !== 'newdriver') {
      if (licenceType === 'uk_prov') {
        dispatch(setProduct('ldp'));
      } else {
        const product = type === 'owner' ? 'tc' : 'csi';
        dispatch(setProduct(product));
      }
      dispatch(setPaymentMethod(null));
    }

    dispatch(setUserField('status', 'driver'));
  },
  setDriverOwner: (type: string) => {
    return dispatch(setDriverOwner(type === 'owner'));
  },
});

const mergeProps = (
  propsFromState: StateProps,
  propsFromDispatch: DispatchProps,
  ownProps: unknown,
): MergeProps => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,
  setProduct: (type) => {
    propsFromDispatch.setProductType(type, propsFromState.licenceType, propsFromState.productType);
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(
  reduxForm({
    form: 'quoteStartForm',
    enableReinitialize: true,
    touchOnChange: true,
  })(QuoteStart),
);
