import styled from 'styled-components';

import colors from '@config/colors';

export const InfoTagContainer = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 24px;
  padding-right: 24px;
  background: ${colors.grey.background.light};
`;

export const InfoText = styled.div`
  color: ${colors.grey.text.light};
  font-family: proxima-soft;
  font-size: 14px;
  line-height: 17px;
  display: inline;
`;

export const InfoLink = styled.a`
  color: ${colors.blue.royal};
  font-family: proxima-soft;
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;
  display: inline;
  cursor: pointer;
`;
