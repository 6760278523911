import React from 'react';
import { Field } from 'redux-form';
import { Tooltip } from '@rentecarlo/component-library';
import TextInputField from '@atoms/inputs/TextInputField';
import Spacer from '@atoms/layout/form/Spacer';
import SectionTitleWithTooltip from '@atoms/titles/SectionTitleWithTooltip';
import { SectionTitle } from '@config/importantInformation';
import ToggleSelector from '@molecules/inputs/ToggleSelectorField';
import SelectNew from '@molecules/selects/SelectNew';
import ReduxFieldValidation from '@services/formValidation';
import { TooltipText } from '../assets/styles';

interface Props {
  input: {
    onChange: (val: string) => void;
    value: string;
  };
  meta: {
    error?: string;
  };
  alternativeInsurers: Array<{ name: string; value: string }>;
  otherInsuranceSelected: boolean;
  getInsurersName: string;
  isDriverOwner: boolean;
}

const HasInsuranceField: React.FC<Props> = ({
  input,
  meta,
  alternativeInsurers,
  otherInsuranceSelected,
  getInsurersName,
  isDriverOwner,
}: Props) => (
  <ToggleSelector
    input={input}
    meta={meta}
    groupName='hasInsurance'
    yesSubquestion={
      <>
        <SectionTitle id='car-title-hasInsuranceField'>
          Select the insurance provider from the list
        </SectionTitle>
        <Field
          id='car-field-mainInsurer'
          name='mainInsurer'
          component={SelectNew}
          options={alternativeInsurers}
          placeholder='Insurance provider'
          validate={ReduxFieldValidation.required}
        />
        {otherInsuranceSelected && (
          <Spacer>
            <Field
              name='alternateInsurer'
              id='car-field-alternateInsurer'
              component={TextInputField}
              placeholder='Please type the name of your insurer'
              validate={[
                ReduxFieldValidation.required,
                ReduxFieldValidation.validAlternativeInsurer,
              ]}
              optional
            />
          </Spacer>
        )}
        {isDriverOwner && (getInsurersName || otherInsuranceSelected) && (
          <>
            <Spacer space='27px' />
            <SectionTitleWithTooltip
              title={`Are you a named driver with ${getInsurersName || ''}?`}
              tooltip={
                <Tooltip
                  title='What does this mean?'
                  modalTitle='What does this mean?'
                  tooltipDirection='right'
                  width='200'
                >
                  <TooltipText id='car-text-hasInsuranceFieldTooltip'>
                    If you are insured to drive this car through {getInsurersName} as your name is
                    down on the policy, you're a named driver. This is also known as an additional
                    driver.
                  </TooltipText>
                </Tooltip>
              }
            />
            <Field
              name='isNamedDriver'
              id='car-field-isNamedDriver'
              component={ToggleSelector}
              validate={[ReduxFieldValidation.required]}
              groupName='namedDriver'
              marginBottom='10px'
            />
          </>
        )}
      </>
    }
  />
);

export default HasInsuranceField;
