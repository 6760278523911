/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Dispatch } from 'redux';
import {
  quickSelectIndicativePrice,
  getQuickSelectPrices,
} from '@reducer/quote/indicativePrice.actions';
import { getIndicativeDurationsConfig } from '@reselectors/price';

import { resetDuration } from '@reducer/quote/duration.actions';
import { DefaultDuration } from '@services/getDefaultDuration';
import { DurationOptions, Durations } from '@config/indicativeDurations';
import { RootState } from '@redux/reducer';

import IndicativePriceInput from './IndicativePriceInput';

export const getTabOptions = (durationOptions: Durations) => {
  const result: {
    text: string;
    value: string;
    locked: boolean;
    options: DurationOptions[];
  }[] = [];
  const keys = Object.keys(durationOptions) as Array<keyof Durations>;

  keys.map((key) => {
    if (key !== 'default') {
      result.push(durationOptions[key]);
    }
    return null;
  });
  return result;
};

export const containsSelectedDuration = (
  options: DurationOptions[],
  endChoiceSelected: string | null,
): boolean => options.find((option) => option.value === endChoiceSelected) !== undefined;

export const getOpenTab = (
  durations: Durations,
  endChoiceSelected: string | null,
): string | boolean => {
  const keys = Object.keys(durations) as Array<keyof Durations>;

  const filteredDurations = keys.filter((key) => {
    if (key !== 'default') {
      return containsSelectedDuration(durations[key].options, endChoiceSelected);
    }

    return false;
  });
  if (filteredDurations.length > 0) {
    return durations[filteredDurations[0]].value;
  }
  return 'hours';
};

const mapStateToProps = (state: RootState) => {
  const {
    app: { isMobile },
    quote: {
      duration: { endDateTime, endChoiceSelected, startDateTime },
      indicativePrice: { price, loading },
      product: { productType },
    },
  } = state;

  const endChoiceSelectedValue = endChoiceSelected && endChoiceSelected.value;

  return {
    endChoiceSelected: endChoiceSelectedValue,
    price,
    durations: getIndicativeDurationsConfig(state),
    getProductType: productType,
    isLoadingPrices: loading,
    endDateTime,
    startDateTime,
    tabOptions: getTabOptions(getIndicativeDurationsConfig(state)),
    openTab: getOpenTab(getIndicativeDurationsConfig(state), endChoiceSelectedValue),
    isMobile,
    loggedIn: state.account.login.loggedIn,
  };
};

const resetAndGoToDuration = (dispatch: Dispatch) => {
  dispatch(resetDuration());
  dispatch(push('duration-of-cover'));
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  selectDuration: (duration) => dispatch(quickSelectIndicativePrice(duration)),
  getQuickSelectPrices: (isSubscription: boolean, defaultDuration: DefaultDuration) =>
    dispatch(getQuickSelectPrices(isSubscription, defaultDuration)),
  goToDurationOfCover: () => resetAndGoToDuration(dispatch),
});

const IndicativePriceInputContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(IndicativePriceInput);

export default IndicativePriceInputContainer;
