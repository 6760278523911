import React from 'react';
import { useStore } from 'react-redux';
import { formValueSelector } from 'redux-form';

import { useOptimizelyFlag, useAppSelector } from '@hooks';
import { getPrice, PriceDataObjectProps } from '@services/variableExcess';

import {
  SummaryContainer,
  IconWrapper,
  ArrowIcon,
  LabelWrapper,
  Label,
  PriceContainer,
  CurrentPriceLabel,
  CurrentPriceValue,
} from './styles';

const importantInformationForm = formValueSelector('importantInformationForm');
export interface Props {
  onClick: () => void;
  visible: boolean;
  useExcessPrice?: boolean;
}

const ViewQuoteSummaryBanner: React.FC<Props> = ({ onClick, visible, useExcessPrice = false }) => {
  const isSubscription = useAppSelector((state) => state.quote.product.isSubscription);
  const pathName = useAppSelector((state) => state.router.location.pathname);
  const hideSubsIndicativePrice = useOptimizelyFlag('TEMP_HIDE_INDICATIVE_SUBS').enabled;
  const indicativePriceRemoved = useOptimizelyFlag('TEMP_INDICATIVE_PRICE_REMOVED').enabled;
  const hidePrice = hideSubsIndicativePrice && isSubscription;
  const getState = useStore().getState();

  const priceDataObject: PriceDataObjectProps = {
    useExcessPrice,
    selectedExcess: importantInformationForm(getState, 'excess'),
    finalPricesByExcess: useAppSelector(
      (state) => state.quote.importantInformation.finalPricesByExcess,
    ),
    isBreakdownCoverSelected: importantInformationForm(getState, 'breakdownCover') === 'true',
    savedPrice: useAppSelector((state) => state.quote.save.price),
    indicativePrice: useAppSelector((state) => state.quote.indicativePrice?.price),
  };
  const price = getPrice(priceDataObject);

  const getShowPrice = () => {
    if (indicativePriceRemoved) {
      return (
        price &&
        !hidePrice &&
        [
          '/product-choice',
          '/important-information',
          '/final-quote',
          '/quote-register',
          '/almost-there',
          '/payment',
        ].includes(pathName)
      );
    }
    return price && !hidePrice;
  };
  const showPrice = getShowPrice();

  return (
    <SummaryContainer id='quote-container-summaryBanner' onClick={onClick}>
      <LabelWrapper>
        <Label id='quote-title-summaryBanner'>View quote summary</Label>
      </LabelWrapper>
      <IconWrapper>
        <ArrowIcon visible={visible} />
      </IconWrapper>
      {showPrice && (
        <PriceContainer id='quote-container-summaryBannerPrice'>
          <CurrentPriceLabel id='quote-text-quoteSummaryBannerPrice'>
            Current price
          </CurrentPriceLabel>
          <CurrentPriceValue id='quote-text-quoteSummaryBannerPriceValue'>{`£${price}`}</CurrentPriceValue>
        </PriceContainer>
      )}
    </SummaryContainer>
  );
};

export default ViewQuoteSummaryBanner;
