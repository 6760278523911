import { SetStateFieldActionCreator } from '@utils/types';

import { LicenceState, LicenceTypes } from './licence';
import { SaveQuoteFailure } from './save.actions';

export enum types {
  SET_LICENCE_FIELD = 'SET_LICENCE_FIELD',
  CHANGE_LICENCE_TYPE = 'CHANGE_LICENCE_TYPE',
  FETCH_LICENCE_TYPE = 'FETCH_LICENCE_TYPE.REQUEST',
}

export type SetLicenceField = ReturnType<typeof setLicenceField>;

export interface ChangeLicenceType {
  type: typeof types.CHANGE_LICENCE_TYPE;
  value: LicenceTypes;
}

export interface GoToUpgrade {
  type: typeof types.FETCH_LICENCE_TYPE;
}

export type HandleSaveFailuresActionTypes = SaveQuoteFailure;
export type LicenceActions = SetLicenceField | ChangeLicenceType;

// Actions
export const setLicenceField: SetStateFieldActionCreator<
  LicenceState,
  typeof types.SET_LICENCE_FIELD
> = (field, value) => ({
  type: types.SET_LICENCE_FIELD,
  field,
  value,
});

export const changeLicenceType = (value: LicenceTypes): ChangeLicenceType => ({
  type: types.CHANGE_LICENCE_TYPE,
  value,
});

export const goToUpgrade = (): GoToUpgrade => ({
  type: types.FETCH_LICENCE_TYPE,
});
