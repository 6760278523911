import React from 'react';
import { Panel, P, TextLink } from '@rentecarlo/component-library';

import { Container } from './assets/styles';

const PrivacyPolicyInfo: React.FC = () => (
  <Container>
    <Panel>
      <P id='start-paragraph-privacyPolicy' fontSize={14}>
        By hitting 'Next' below, you confirm you're happy for Veygo to use your data as outlined in
        our{' '}
        <TextLink
          id='start-link-privacyPolicy'
          fontSize={14}
          href='https://www.veygo.com/privacy-policy/'
        >
          Privacy Policy
        </TextLink>
      </P>
    </Panel>
  </Container>
);

export default PrivacyPolicyInfo;
