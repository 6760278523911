import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import moment, { Moment } from 'moment';

import { login } from '@reducer/account/login.actions';
import { setClaimsField } from '@reducer/quote/claims.actions';
import { markPageAsComplete } from '@reducer/pages/actions';
import { getLast16CharactersOfDLN, isLicenceNotFound, isLicenceExpired } from '@reselectors/quote';
import { setNCBField } from '@reducer/quote/noClaimsBonus.actions';
import { changeLicenceType, setLicenceField } from '@reducer/quote/licence.actions';
import { saveAndGoTo } from '@reducer/quote/save.actions';
import { resetQuote } from '@reducer/quote/actions';
import { setCustomerField } from '@reducer/account/customer.actions';

import { sendEvent } from '@redux/reducer/analytics/actions';
import { ConversionProject } from '@config/constants';

import {
  reduxForm,
  formValueSelector,
  getFormSyncErrors,
  getFormInitialValues,
  getFormValues,
} from 'redux-form';

import { dlnAlreadyUsed } from '@reselectors/errors';
import { Dispatch } from 'redux';
import { RootState } from '@redux/reducer';
import { QuoteData } from '@redux/reducer/quote/saveTypes';
import { LicenceTypes } from '@redux/reducer/quote/licence';
import { optimizelyClient } from '@services/clients/optimizely';
import DrivingHistory, {
  DrivingHistoryFields,
  DrivingHistoryProps,
  NCBFields,
} from './DrivingHistory';

export const postQuoteSaveRedirect = (
  quote: QuoteData,
  altProduct: { passedUW: boolean; price: number | null },
  productType: string,
  userIdentity: string,
  isSubscription: boolean,
  driverDOB: Moment | null,
): string => {
  const indicativePriceRemoved = optimizelyClient.isFeatureEnabled(
    'TEMP_INDICATIVE_PRICE_REMOVED',
    userIdentity,
  );

  if (indicativePriceRemoved) {
    const isOldEnough = moment().startOf('day').diff(driverDOB?.startOf('day'), 'years') >= 17;
    if (isSubscription || !isOldEnough) {
      return 'duration-of-cover';
    }
    return 'duration';
  }

  if (!quote.underwriting_criteria || quote.price === null) {
    return 'rejection';
  }

  if (
    altProduct &&
    altProduct.price &&
    (productType === 'newdriver' || altProduct.price <= Number(quote.price))
  ) {
    return 'product-choice';
  }

  return 'important-information';
};

const initialFormSelector = getFormInitialValues('drivingHistoryForm');
const formValues = getFormValues('drivingHistoryForm');
const formSelector = formValueSelector('drivingHistoryForm');

const parseStringToBoolean = (value: string) => value === 'true';
const parseStringInt = (value: string) => parseInt(value);

const submitNCBFields = (values: NCBFields, dispatch: Dispatch) => {
  const { haveNCB, level } = values;
  if (haveNCB !== null) {
    const parsedHaveNCB = parseStringToBoolean(haveNCB);
    dispatch(setNCBField('haveNCB', parsedHaveNCB));
  }
  if (level !== null) {
    const parsedLevel = parseStringInt(level);
    dispatch(setNCBField('level', parsedLevel));
  }
};

export const replaceSpecialChars = (str: string): string =>
  str.replace(/[^\w]/gi, '').replace('_', '');

export const getFirst5CharactersOfDLN = (lastName: string): string =>
  replaceSpecialChars(lastName).substring(0, 5).toUpperCase().padEnd(5, '9');

export const getNext6CharactersOfDLN = (dob: string, title: string): string | null => {
  if (title === 'mx') {
    return null;
  }
  const date = moment(dob);
  const day = date.format('DD');
  let month = date.format('MM');
  const year = date.format('YY');
  if (title !== 'mr') {
    month = (parseInt(month) + 50).toString();
  }
  return year.substring(0, 1) + month + day + year.substring(1, 2);
};

// eslint-disable-next-line consistent-return
const submission = (
  values: DrivingHistoryFields,
  dispatch: Dispatch,
  props: DrivingHistoryProps,
  shouldSave = true,
) => {
  const {
    hasClaims,
    numberOfClaims,
    ncb,
    fullLicenceConfirmed,
    fullLicenceDate,
    licenceNumberSurnamePart,
    licenceNumberDOBPart,
    licenceNumberFinalPart,
  } = values;
  if (props.productType === 'newdriver' && props.licenceType === 'uk_prov') {
    return dispatch(push('/licence-change'));
  }
  const parsedHasClaims = parseStringToBoolean(hasClaims);
  dispatch(setClaimsField('hasClaims', parsedHasClaims));
  // eslint-disable-next-line no-unused-expressions
  numberOfClaims ? dispatch(setClaimsField('numberOfClaims', numberOfClaims)) : null;
  if (fullLicenceConfirmed) {
    dispatch(setLicenceField('fullLicenceConfirmed', fullLicenceConfirmed));
    dispatch(setLicenceField('fullLicenceDate', fullLicenceDate));
    dispatch(setCustomerField('licence.fullLicenceConfirmed', fullLicenceConfirmed));
    dispatch(setCustomerField('licence.fullLicenceDate', fullLicenceDate));
  }

  let updatedLicenceNumber = '';

  if (props.loggedIn === false) {
    updatedLicenceNumber = licenceNumberSurnamePart + licenceNumberDOBPart + licenceNumberFinalPart;
  } else {
    updatedLicenceNumber = props.licenceNumber;
  }

  dispatch(setLicenceField('licenceNumber', updatedLicenceNumber));

  if (ncb) submitNCBFields(ncb, dispatch);

  if (shouldSave) {
    dispatch(markPageAsComplete('drivingHistory'));
    return dispatch(
      saveAndGoTo(
        (
          data: QuoteData,
          altProduct: { passedUW: boolean; price: number | null },
          productType: string,
        ) =>
          postQuoteSaveRedirect(
            data,
            altProduct,
            productType,
            props.userIdentity,
            props.isSubscription,
            props.birthdate,
          ),
      ),
    );
  }

  return null;
};

const mapStateToProps = (state: RootState): DrivingHistoryProps => {
  const {
    quote: {
      licence: { cassieType },
      user: { prefilledDriver },
      driver: { birthdate },
      car: { isNewCar },
      product: { productType, isSubscription },
      save: { error },
    },
    account: {
      customer: { ncb },
      login: { loggedIn },
    },
    config: {
      optimizelyAttributes: { user_identity: userIdentity },
    },
  } = state;

  return {
    birthdate,
    cassieType,
    customerNCB: ncb.haveNCB,
    dlnAlreadyUsed: dlnAlreadyUsed(state),
    isLicenceNotFound: isLicenceNotFound(state),
    isLicenceExpired: isLicenceExpired(state),
    isNewCar,
    hasClaims: formSelector(state, 'hasClaims') === 'true',
    haveNCB: formSelector(state, 'ncb.haveNCB') === 'true',
    level: formSelector(state, 'ncb.level'),
    licenceNumber:
      formSelector(state, 'licenceNumberSurnamePart') +
      formSelector(state, 'licenceNumberDOBPart') +
      formSelector(state, 'licenceNumberFinalPart'),
    productType,
    prefilled: prefilledDriver,
    formErrors: getFormSyncErrors('drivingHistoryForm')(state),
    isLoggedIn: loggedIn,
    licenceType: state.quote.licence.type,
    licenceNumberFinalPart: getLast16CharactersOfDLN(state),
    initialValues:
      {
        ...initialFormSelector(state),
        licenceNumberFinalPart: getLast16CharactersOfDLN(state),
      } || {},
    formValues: formValues(state) || {},
    saveError: error,
    userIdentity,
    isSubscription,
  };
};

interface DispatchProps {
  restartAsLearner: (e: Event) => void;
  goToForgottenPassword: () => void;
  goToLogin: () => void;
  sendGaErrorEvent: (label: string) => void;
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  restartAsLearner: (e: Event) => {
    e.preventDefault();
    dispatch(resetQuote());
    dispatch(changeLicenceType(LicenceTypes.uk_prov));
    dispatch(push('/start'));
  },
  goToForgottenPassword: () =>
    window.location.assign(
      `${process.env.REACT_APP_LOGIN_URL}/password/forgot?redirect=${window.location.href}`,
    ),
  goToLogin: () => dispatch(login('driving-history')),
  sendGaErrorEvent: (label: string) =>
    dispatch(
      sendEvent(ConversionProject.ConversionOptimization, {
        featureFlagName: ConversionProject.THIRD_PARTY_ERROR,
        featureFlagValue: label,
      }),
    ),
});

const mergeProps = (
  propsFromState: DrivingHistoryProps,
  propsFromDispatch: DispatchProps,
  ownProps: Record<string, unknown>,
) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,
  initialValues: propsFromState.initialValues,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(
  reduxForm({
    form: 'drivingHistoryForm',
    onSubmit: (values: DrivingHistoryFields, dispatch: Dispatch, props: DrivingHistoryProps) =>
      submission(values, dispatch, props),
    enableReinitialize: true,
    touchOnChange: false,
    touchOnBlur: true,
  })(DrivingHistory),
);
