import { SetStateFieldActionCreator } from '@utils/types';
import { DriverAddress, DriverPracticalTest, DriverFields, DriverState } from './driverTypes';

export enum ActionTypes {
  SET_DRIVER_FIELD = 'SET_DRIVER_FIELD',
  SET_DRIVER_FIELDS = 'SET_DRIVER_FIELDS',
  SET_DRIVER_ADDRESS_FIELDS = 'SET_DRIVER_ADDRESS_FIELDS',
  SET_DRIVER_OWNER = 'SET_DRIVER_OWNER',
  SET_DRIVER_PRACTICAL_TEST_FIELD = 'SET_DRIVER_PRACTICAL_TEST_FIELD',
}

export interface SetDriverFields {
  type: typeof ActionTypes.SET_DRIVER_FIELDS;
  obj: DriverFields;
}

export interface SetDriverOwner {
  type: typeof ActionTypes.SET_DRIVER_OWNER;
  driver: boolean;
}

export interface SetAddressFields {
  type: typeof ActionTypes.SET_DRIVER_ADDRESS_FIELDS;
  data: DriverAddress;
}

export interface SetDriverPracticalTestField {
  type: typeof ActionTypes.SET_DRIVER_PRACTICAL_TEST_FIELD;
  data: DriverPracticalTest;
}

// Actions
export const setDriverField: SetStateFieldActionCreator<
  DriverState,
  typeof ActionTypes.SET_DRIVER_FIELD
> = (field, value) => ({
  type: ActionTypes.SET_DRIVER_FIELD,
  field,
  value,
});

export type SetDriverField = ReturnType<typeof setDriverField>;

export const setDriverFields = (obj: DriverFields): SetDriverFields => ({
  type: ActionTypes.SET_DRIVER_FIELDS,
  obj,
});

export const setDriverOwner = (driver: boolean): SetDriverOwner => ({
  type: ActionTypes.SET_DRIVER_OWNER,
  driver,
});

export const setAddressFields = (data: DriverAddress): SetAddressFields => ({
  type: ActionTypes.SET_DRIVER_ADDRESS_FIELDS,
  data,
});

export const setDriverPracticalTestField = (
  data: DriverPracticalTest,
): SetDriverPracticalTestField => ({
  type: ActionTypes.SET_DRIVER_PRACTICAL_TEST_FIELD,
  data,
});

export type DriverActions = SetDriverField | SetDriverFields | SetDriverOwner | SetAddressFields;
