import { call, fork, put, select, take, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { changeLicenceType } from '@reducer/quote/licence.actions';
import { setLegalField } from '@reducer/quote/legal.actions';
import {
  CUSTOMER_FETCH_ME_SUCCESS,
  CUSTOMER_SAVE_SUCCESS,
} from '../reducer/account/customer.actions';
import { types as quoteTypes } from '../reducer/quote/actions';
import {
  types as userTypes,
  prefillConnection,
  prefillDriver,
  prefillDriverOnEdit,
  prefillOwnerOnEdit,
} from '../reducer/quote/user.actions';
import { saveQuote } from '../reducer/quote/save.actions';
import { types as loginTypes } from '../reducer/account/login.actions';

export const getUserStatus = (state) => state.quote.user.status;
export const getQuoteConnection = (state) => state.quote.user.connection;
export const getQuoteUUID = (state) => state.quote.save.uuid;
export const getLicenceType = (state) => state.quote.licence.type;
export const getLoggedIn = (state) => state.account.login.loggedIn;
export const getPostLoginPage = (state) => state.account.login.postLoginPage;
export const getAccount = (state) => state.account;
export const getCurrentPage = (state) => state.router.location.pathname;

export const getAccountLicenceType = (state) => state.account.customer.licence.type;

export const getProductType = (state) => state.quote.product.productType;

export function* prefillQuoteSaga() {
  const connection = yield select(getQuoteConnection);

  yield put(prefillDriver());
  if (connection) yield put(prefillConnection());
}

export function* prefillQuoteOnUserChange({ field }) {
  if (field === 'connection') yield fork(prefillQuoteSaga);
}

const redirectToLicenceChange = (page, licenceType, productType) =>
  page === '/driving-history' &&
  licenceType === 'uk_prov' &&
  (productType === 'csi' || productType === 'tc');

export function* prefillQuoteOnLoginSaga() {
  yield take(CUSTOMER_FETCH_ME_SUCCESS);
  yield call(prefillQuoteSaga);

  const page = yield select(getCurrentPage);

  if (page.includes('start')) {
    const licenceType = yield select(getAccountLicenceType);
    yield put(changeLicenceType(licenceType));
  }

  if (page === '/about-you') {
    yield put(push('driver-details-summary'));
  }
}

// This saga checks if the customer is logged in and will prefill details
export function* prefillQuoteOnReset() {
  const loggedIn = yield select(getLoggedIn);
  if (loggedIn) {
    yield put(setLegalField('loginPoint', 'before quote'));
    yield call(prefillQuoteSaga);
  }
}

export function* prefillQuoteOnEditSaga() {
  const status = yield select(getUserStatus);

  if (!status) return;

  if (status === 'driver') yield put(prefillDriverOnEdit());
  else if (status === 'owner') yield put(prefillOwnerOnEdit());

  yield put(saveQuote());
}

export function* watchPrefill() {
  yield takeEvery(CUSTOMER_SAVE_SUCCESS, prefillQuoteOnEditSaga);
  yield takeEvery(loginTypes.LOGIN_SUCCESS, prefillQuoteOnLoginSaga);
  yield takeEvery(userTypes.SET_USER_FIELD, prefillQuoteOnUserChange);
  yield takeEvery(quoteTypes.RESET_QUOTE, prefillQuoteOnReset);
}
