import { useContext } from 'react';
import { useSelector } from 'react-redux';

import { OptimizelyContext } from '@optimizely/react-sdk';

import { optimizelyOverrideAttributes } from '@reselectors/optimizely';

const useOptimizelyTrack = (): ((eventKey: string) => void) => {
  const overrideAttributes = useSelector(optimizelyOverrideAttributes);
  const { optimizely } = useContext(OptimizelyContext);

  const eventTrigger = (eventKey: string): void => {
    optimizely?.track(eventKey, overrideAttributes.user_identity, overrideAttributes);

    window.optimizely = window.optimizely || [];

    window.optimizely.push({
      type: 'user',
      attributes: overrideAttributes,
    });
    window.optimizely.push({
      type: 'event',
      eventName: eventKey,
    });
  };

  return eventTrigger;
};

export default useOptimizelyTrack;
