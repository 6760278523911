import * as Sentry from '@sentry/browser';
import { compose } from 'redux';
import { merge } from 'lodash';
import mapValues from 'lodash/mapValues';

import moment from 'moment';

import persistState, { mergePersistedState, transformState } from 'redux-localstorage';
import adapter from 'redux-localstorage/lib/adapters/sessionStorage';
import alternativeProducts from '@redux/reducer/quote/alternativeProducts';

export function mapToObject(map) {
  return Array.from(map).reduce((obj, [key, value]) => {
    obj[key] = value;
    return obj;
  }, {});
}

export function objectToMap(object) {
  return new Map(Object.entries(object));
}

const transformationMap = {
  account: {
    customer: {
      birthdate: moment,
    },
    login: {
      tokenExpiration: moment,
    },
  },
  quote: {
    alternativeProducts: objectToMap,
    driver: {
      birthdate: moment,
      practicalTest: {
        date: moment,
      },
    },
    owner: {
      birthdate: moment,
    },
    duration: {
      startDateTime: moment,
      endDateTime: moment,
    },
    purchasedQuote: {
      startDate: moment,
    },
  },
};

function transform(state, map = transformationMap) {
  return mapValues(state, (value, key) => {
    if (value !== undefined && value !== null && key in map) {
      const subMap = map[key];
      if (typeof subMap === 'function') return subMap(value);
      return transform(value, subMap);
    }
    return value;
  });
}

export const persistedReducer = compose(
  mergePersistedState((initialState, persistedState) => merge({}, initialState, persistedState)),
);

export const down = (state) => {
  return {
    account: state.account,
    lookup: state.lookup,
    quote: {
      ...state.quote,
      alternativeProducts: mapToObject(state.quote.alternativeProducts),
      payment: {
        termsAndConditionsAccepted: state.quote.payment.termsAndConditionsAccepted,
      },
      save: {
        ...state.quote.save,
        expiry: moment().add(2, 'hours'),
      },
    },
    config: {
      ...state.config,
      amplifyLoaded: false,
    },
    app: state.app,
    pages: state.pages,
  };
};

export const up = (state) => {
  if (!state) {
    return undefined;
  }

  if (moment(state.quote.save.expiry) < moment()) {
    Sentry.addBreadcrumb({
      message: 'Session storage expired',
      category: 'redux',
      level: 'info',
      data: { expiry: state?.quote?.save?.expiry },
    });

    // Reset state, but keep state.quote.purchasedQuote
    return {
      quote: {
        purchasedQuote: state?.quote?.purchasedQuote,
      },
    };
  }
  return transform(state);
};

const storage = compose(transformState(down, up))(adapter(window.sessionStorage));

export const sessionStorageEnhancer = compose(persistState(storage, 'redux'));

export default persistedReducer;
