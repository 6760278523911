/*
 * Re-selectors for static files.
 */
import { createSelector } from 'reselect';

const product = (state) => state.quote.product;

export const getKeyFactsUrl = createSelector([product], ({ productType }) => {
  switch (productType) {
    case 'ldp':
      return `${process.env.REACT_APP_CSI_BASE_URL}/static/learner-driver-key-facts.pdf`;
    default:
      return `${process.env.REACT_APP_CSI_BASE_URL}/static/key-facts.pdf`;
  }
});

export const getYourAgreementWithAbleUrl = createSelector(
  [],
  () =>
    `${process.env.REACT_APP_CSI_BASE_URL}/static/Your_Agreement_with_Able_Insurance_Services_Limited.pdf`,
);

export const getPolicyBookletUrl = createSelector([product], ({ productType }) => {
  if (productType === 'ldp') {
    return `${process.env.REACT_APP_CSI_BASE_URL}/static/Learner_Driver_Policy_Book.pdf`;
  }
  if (productType === 'newdriver') {
    return `${process.env.REACT_APP_CSI_BASE_URL}/static/newdriver/New_Driver_Policy_Book.pdf`;
  }
  return `${process.env.REACT_APP_CSI_BASE_URL}/static/tempcover_v2/Policy_booklet.pdf`;
});

export const getIPIDUrl = createSelector([product], ({ productType }) => {
  if (productType === 'ldp') {
    return `${process.env.REACT_APP_CSI_BASE_URL}/static/ldp/Learner_Driver_Insurance_Product_Information_Document.pdf`;
  }
  if (productType === 'newdriver') {
    return `${process.env.REACT_APP_CSI_BASE_URL}/static/newdriver/New_Driver_Insurance_Product_Information_Document.pdf`;
  }
  return `${process.env.REACT_APP_CSI_BASE_URL}/static/tempcover_v2/Insurance_Product_Information_Document.pdf`;
});

export const getTermsAndConditionsUrl = createSelector(
  [],
  () => `${process.env.REACT_APP_CSI_BASE_URL}/static/Conditions_of_Use.pdf`,
);

export const getBreakdownCoverIPIDUrl = createSelector(
  [],
  () =>
    `${process.env.REACT_APP_CSI_BASE_URL}/static/ancillary/Breakdown_Cover_Information_Document.pdf`,
);

export const getBreakdownCoverFullTermsUrl = createSelector(
  [],
  () => `${process.env.REACT_APP_CSI_BASE_URL}/static/ancillary/Breakdown_Cover_Full_Terms.pdf`,
);

export const getNewDriverGuideUrl = createSelector(
  [],
  () => `${process.env.REACT_APP_CSI_BASE_URL}/static/Your_Guide_to_ND_2023.pdf`,
);
