import deepUpdate from '../../../services/deepUpdate';
import { types as quoteTypes } from './actions';
import { types as loginTypes } from '../account/login.actions';
import { types as lookupTypes } from '../lookup/actions';
import { Car } from './carTypes';

import { types as carTypes, CarActions } from './car.actions';

// Reducer
export const initialState: Car = {
  abiCode: null,
  engineSize: null,
  hasInsurance: '',
  isNewCar: true,
  mainInsurer: '',
  make: '',
  model: '',
  registrationNumber: '',
  transmission: '',
  value: null,
  valueBand: null,
  year: null,
  termsOfCoverConfirmed: false,
  personalTempcoverVehicleConfirmed: false,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type CarActionTypes = CarActions | any;

export default function driver(
  state: Car = initialState,

  action = {} as CarActionTypes,
): Car {
  switch (action.type) {
    case carTypes.LOAD_FROM_LOOKUP:
      return {
        ...state,
        abiCode: action.carData.abi_code,
        engineSize: action.carData.engine_size,
        make: action.carData.make,
        model: action.carData.model,
        transmission: action.carData.transmission,
        value: action.carData.value,
        valueBand: action.carData.band,
        year: action.carData.year,
      };
    case carTypes.TOGGLE_HAS_INSURANCE:
      return {
        ...state,
        hasInsurance: action.value,
      };
    case carTypes.SET_CAR_FIELD:
      return deepUpdate(state, action.field, action.value);
    case carTypes.TOGGLE_TERMS_OF_COVER:
      return {
        ...state,
        termsOfCoverConfirmed: !state.termsOfCoverConfirmed,
      };
    case carTypes.TOGGLE_TEMPCOVER_PERSONAL_VEHICLE:
      return {
        ...state,
        personalTempcoverVehicleConfirmed: !state.personalTempcoverVehicleConfirmed,
      };
    case lookupTypes.LOOKUP_CAR_SUCCESS:
      return {
        ...state,
        mainInsurer: action.carData.abi_code !== state.abiCode ? '' : state.mainInsurer,
        termsOfCoverConfirmed: false,
        hasInsurance: '',
        personalTempcoverVehicleConfirmed: false,
      };
    case loginTypes.LOGOUT_SUCCESS:
    case quoteTypes.RESET_QUOTE:
      return initialState;
    default:
      return state;
  }
}
