import React from 'react';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { stopSubmit } from 'redux-form';
import { CognitoClient } from '@rentecarlo/node-amplify-client';

import CustomersClient from '@services/clients/customers';

import { isUserNotLoggedInError } from '@utils/errors';
import { webLog as webLogApi } from '../../services/api';
import isInQuote from '../../services/isInQuote';
import { types, registerSuccess, registerFailure } from '../reducer/account/register.actions';
import {
  setDriverFromQuote,
  setOwnerFromQuote,
  setCustomerField,
} from '../reducer/account/customer.actions';
import { saveAndGoTo } from '../reducer/quote/save.actions';
import { sendEvent } from '../reducer/analytics/actions';
import { setLegalField } from '../reducer/quote/legal.actions';

import handleError from '../utils/errorHandler';

export const getQuote = (state) => state.quote;
export const getCustomer = (state) => state.account.customer;
export const getRegistrationEmail = (state) => state.account.register.email;
export const getRegistrationPassword = (state) => state.account.register.password;
export const getCurrentPage = (state) => state.router.location.pathname;
export const getRegistrationToken = (state) => state.account.register.registrationToken;
export const getAddress = (state) => state.account.address[0];

export function* registerSaga(action) {
  let cognitoSignUpResult = null;
  try {
    const loginPoint = yield select(getCurrentPage);
    if (isInQuote(loginPoint)) {
      yield put(setLegalField('loginPoint', loginPoint));
    } else {
      yield put(setLegalField('loginPoint', 'before quote'));
    }

    const quote = yield select(getQuote);
    const uuid = null;

    if (quote.user.status === 'owner') {
      yield put(setOwnerFromQuote(uuid, quote));
    } else {
      yield put(setDriverFromQuote(uuid, quote));
    }

    const email = yield select(getRegistrationEmail);
    const password = yield select(getRegistrationPassword);
    const customerData = yield select(getCustomer);
    const addressData = yield select(getAddress);

    let cognitoSignedUp = false;

    try {
      const session = yield call(() => CognitoClient.getCurrentSession());
      if (session.isValid) {
        cognitoSignedUp = true;
      }
    } catch (error) {
      // Only raise error if not No current user
      if (!isUserNotLoggedInError(error)) {
        handleError(error);
      }
    }
    if (!cognitoSignedUp) {
      // If the customer api has completely failed and cognito sign up worked
      // it can sometimes leave a customer in a half state where they cannot sign up
      // as they exist in Cognito but not CSI so if they are already signed up we
      // wont do it again.
      yield call(() =>
        CognitoClient.signUp(email, password, {
          givenName: customerData.firstName,
          familyName: customerData.lastName,
        }),
      );
      cognitoSignUpResult = yield call(() => CognitoClient.signIn(email, password));
    }

    const responseData = yield call(() => {
      return CustomersClient.postV3({
        ...customerData,
        user: {
          username: email,
        },
        address: { ...addressData },
      });
    });

    // Copy registration token to login reducer
    const token = yield select(getRegistrationToken);

    yield put(setCustomerField('hasEdited', false));
    yield put(setCustomerField('hasEditedPersonalDetails', false));
    yield put(setCustomerField('display30DayEditModal', false));

    // send event to GA
    yield put(sendEvent('registerInlineSuccess', { quote: quote.save.uuid, customer: uuid }));

    yield put(registerSuccess(responseData, token));

    yield put(saveAndGoTo(action.nextPage));
  } catch (error) {
    const cognitoUserExists = error.code && error.code === 'EXISTING_USER';
    const djangoUserExists = error.code === 'USERALREADYEXISTS';
    const customerAlreadyExists = error.code === 'CUSTOMER_ALREADY_EXISTS';

    const invalidRequest = error.code === 'INVALIDREQUEST';

    if (cognitoUserExists || djangoUserExists) {
      const forgotPasswordUrl = `${process.env.REACT_APP_LOGIN_URL}/password/forgot?redirect=${window.location.origin}/final-quote`;
      const message = (
        <p id='register-paragraph-forgottenPassword'>
          There seems to be an account already registered with this email address. If you have
          forgotten your password please reset your password{' '}
          <a id='register-link-forgottenPassword' href={forgotPasswordUrl}>
            here
          </a>
          . Alternatively, please try a different email address.
        </p>
      );
      yield put(stopSubmit('quoteRegisterForm', { email: message }));
    } else if (customerAlreadyExists) {
      const forgotEmailUrl = `${process.env.REACT_APP_LOGIN_URL}/email/forgot?redirect=${window.location.origin}/final-quote`;
      const message = (
        <p id='register-paragraph-accountAlreadyRegistered'>
          There seems to be an account already registered with the provided driving licence number.
          Please click{' '}
          <a id='register-link-accountAlreadyRegistered' href={forgotEmailUrl}>
            here
          </a>{' '}
          to retrieve your email.
        </p>
      );
      yield put(stopSubmit('quoteRegisterForm', { email: message }));
    } else {
      let message = (
        <p id='register-paragraph-somethingWentWrong'>
          Oops, something went wrong. Please try to refresh the page, if the problem persists then
          contact us.
        </p>
      );
      if (invalidRequest) {
        message = (
          <p id='register-paragraph-invalidRequest'>
            Oops, something went wrong. It looks like we are missing some information please try and
            restart the quote.
          </p>
        );
      }
      yield put(stopSubmit('quoteRegisterForm', { confirmPassword: message }));
      handleError(error);
      yield call(webLogApi.sendError, 'Unhandled sign up error occurred', {
        error_message: error.message ?? error.toString(),
        service: 'registration',
      });
    }

    if (
      cognitoSignUpResult &&
      (djangoUserExists || customerAlreadyExists || cognitoUserExists || invalidRequest)
    ) {
      // Remove cognito user:
      //    If the customer request was invalid (no attempt to create the customer happens)
      //    If the customer has already registered
      const email = yield select(getRegistrationEmail);
      let logMessage = 'Deleting user after already exists error';
      if (invalidRequest) {
        logMessage = 'Deleting user after invalid request error';
      }
      try {
        cognitoSignUpResult.deleteUser();
        yield call(webLogApi.sendError, logMessage, {
          error_message: error.toString(),
          service: 'cognito',
          email,
          description: `Automatically deleted cognito user ${email}`,
        });
      } catch (errorCognito) {
        handleError(errorCognito);
        yield call(webLogApi.sendError, 'Failure to delete a cognito user after registering', {
          error_message: errorCognito.toString(),
          service: 'cognito',
          email,
          description: `Manually need to delete cognito user ${email}`,
        });
      }
    }

    yield put(registerFailure(error));
  }
}

export function* watchRegister() {
  yield takeEvery(types.REGISTER_REQUEST, registerSaga);
}
