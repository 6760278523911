import { OptimizelyOverrideAttributes } from '@redux/reselectors/optimizely';
import { optimizelyClient } from './clients/optimizely';

export default function sendOptimizelyEvent(
  eventKey: string,
  attributes: OptimizelyOverrideAttributes,
): void {
  optimizelyClient.track(eventKey, attributes.user_identity, {
    ...attributes,
  });

  window.optimizely = window.optimizely || [];

  window.optimizely.push({
    type: 'user',
    attributes,
  });

  window.optimizely.push({
    type: 'event',
    eventName: eventKey,
  });
}
