import React from 'react';
import Group from '@atoms/layout/form/Group';
import { FieldContainer } from '@rentecarlo/component-library';
import SectionTitle from '@atoms/titles/componentTitles/SectionTitle';
import { Field, WrappedFieldProps } from 'redux-form';
import ReduxFieldValidation from '@services/formValidation';
import TypeOfUsageField from './TypeOfUsageField';

interface Props {
  errorText?: string;
}

const AlternateVehicleField: React.FC<Props & WrappedFieldProps> = ({
  input: { onChange },
  meta: { touched, error, active, pristine, submitFailed },
  errorText,
}) => {
  return (
    <Group>
      <SectionTitle id='drivingProfile-title-AlternateVehicleField'>
        Do you have access to another vehicle?
      </SectionTitle>
      <FieldContainer
        id='drivingProfile-container-hasAlternateVehicle'
        showErrorContainer={
          !!(((touched && !pristine) || submitFailed) && error && (!active || submitFailed))
        }
        showError={
          !!(((touched && !pristine) || submitFailed) && error && (!active || submitFailed))
        }
        error={
          ((touched && !pristine) || submitFailed) && error && (!active || submitFailed)
            ? errorText
            : undefined
        }
      >
        <Field
          id='drivingProfile-field-hasAlternateVehicleInner'
          name='hasAlternateVehicle'
          component={TypeOfUsageField}
          validate={[ReduxFieldValidation.required]}
          onChange={onChange}
        />
      </FieldContainer>
    </Group>
  );
};

export default AlternateVehicleField;
