import React from 'react';
import styled, { css } from 'styled-components';
import { InformationBox } from '@rentecarlo/component-library';
import { Moment } from 'moment';

import size from '@config/size';
import GreenTick from '@atoms/inputs/GreenTick';
import MobileGreenTick from '@atoms/inputs/MobileGreenTick';
import DesktopDateInput from './DesktopDateInput';
import MobileDateInput from './MobileDateInput';
import DisabledDesktopDateInput from './DisabledDesktopDateInput';

const Mobile = styled.div`
  ${css`
    @media (min-width: ${size.desktopWidth}) {
      display: none;
    }
  `}
`;
const Desktop = styled.div`
  display: none;
  ${css`
    @media (min-width: ${size.desktopWidth}) {
      display: block;
    }
  `}
`;

interface Props {
  valid: boolean;
  date: Moment;
  prefilled: boolean;
  touched: boolean;
  error: string;
  setDate: (date: Moment | null) => void;
  onBlur: (date: Moment | null) => void;
}

const DateInput: React.FC<Props> = (props) => {
  const { valid, date, prefilled, touched, error } = props;
  return (
    <>
      <Desktop>
        <GreenTick
          id='upgradeAccount-icon-dateInputGreenTick'
          mobileRightMargin='0px'
          showTick={valid}
        >
          {date && prefilled ? (
            <DisabledDesktopDateInput {...props} />
          ) : (
            <DesktopDateInput {...props} />
          )}
        </GreenTick>
      </Desktop>
      <Mobile>
        <MobileGreenTick showTick={valid}>
          <MobileDateInput disabled={date && prefilled} {...props} />
        </MobileGreenTick>
      </Mobile>
      {!valid && touched && (
        <InformationBox id='upgradeAccount-text-dateInputError' type='error'>
          {error}
        </InformationBox>
      )}
    </>
  );
};

export default DateInput;
