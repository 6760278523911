import { Store } from 'redux';
import { push } from 'connected-react-router';

import getPages from '@services/pageOrderValidation';
import { RootState } from '@redux/reducer';

// Constructor for redux middleware to implement a page order guard.
export default (store: Store<RootState>) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  (next: any) => (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
    action: any,
  ): void => {
    if (action.type === '@@router/LOCATION_CHANGE') {
      const { pathname } = action.payload.location;
      const pages = getPages(store.getState());
      if (pages.findIndex((value) => value.url === pathname) >= 0) {
        let index = 0;

        while (index < pages.length) {
          if (pathname === pages[index].url) {
            next(action);
            return;
          }

          if (!pages[index].hasRequiredData) {
            const { url } = pages[index];
            Promise.resolve().then(() => store.dispatch(push(url)));
            return;
          }

          index += 1;
        }
      }
    }

    next(action);
  };
