import { optimizelyClient } from '@services/clients/optimizely';
import { ROUTES } from '../../../../router';

const checkPath = (pathname: string, currentRoute: string): boolean =>
  [pathname, `/${pathname}`].includes(`/${currentRoute}`);

export function getProgress(pathname: string, productType: string, userIdentity: string): number {
  const routes = ROUTES.filter(
    (route) => !(route.products && !route.products.includes(productType)),
  );

  if (routes === undefined) return 0;

  if (userIdentity) {
    const indicativePriceRemoved = optimizelyClient.isFeatureEnabled(
      'TEMP_INDICATIVE_PRICE_REMOVED',
      userIdentity,
    );

    if (!indicativePriceRemoved && pathname === '/duration-of-cover') {
      const durationOfCoverIndex = 4;
      // can remove after test is over, this is a temporary fix as the router can't access the store before the application loads
      return Math.floor((100 / (routes.length - 1)) * durationOfCoverIndex);
    }
  }

  let index;
  let i;
  for (i = 0; i < routes.length; i += 1) {
    if (checkPath(pathname, routes[i].path)) {
      index = i;
      break;
    }
  }

  if (index !== undefined) {
    const percentage = Math.floor((100 / (routes.length - 1)) * index);
    return percentage;
  }

  return 0;
}
