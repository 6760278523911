/* eslint-disable camelcase */
import React, { FC } from 'react';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import styled from 'styled-components';
import { InformationBox } from '@rentecarlo/component-library';

dayjs.extend(advancedFormat);

const Container = styled.div`
  margin-top: 16px;
`;

type Props = {
  currentRestrictedPeriod: [
    {
      end_date: string;
      min_duration: number;
    },
  ];
};

const RestrictedPeriodErrorBox: FC<Props> = ({ currentRestrictedPeriod }) => {
  const restrictedPeriod = currentRestrictedPeriod[0];
  const periodEndDate = dayjs(restrictedPeriod.end_date).add(1, 'day').format('Do MMM');
  return (
    <Container>
      <InformationBox id='durationOfCover-text-restrictedPeriodError' type='error'>
        {`Sorry! You can’t select less than ${restrictedPeriod.min_duration} hours during these dates.
         These policies will be available again from ${periodEndDate}.`}
      </InformationBox>
    </Container>
  );
};

export default RestrictedPeriodErrorBox;
