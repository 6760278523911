import React, { FC } from 'react';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import advancedFormat from 'dayjs/plugin/advancedFormat';

import { ComponentType, useComponentId } from '@hooks';
import {
  DurationSummary,
  DateSummary,
  DateLabel,
  DateCalendarValue,
  DateTimeValue,
  QuoteRowContainer,
} from './assets/QuoteDurationSummary';

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(advancedFormat);

type Props = {
  duration: {
    startDateTime?: string;
    immediateStart?: string;
    endDateTime?: string;
  };
  isSubscription?: boolean;
};

const QuoteDurationSummary: FC<Props> = ({ duration, isSubscription = false }) => {
  const creator = useComponentId();
  return (
    <QuoteRowContainer>
      <DurationSummary displayColumn={isSubscription}>
        <DateSummary fullWidth={isSubscription}>
          <DateLabel id={creator(ComponentType.TEXT, 'durationSummaryStart')}>
            {isSubscription ? 'Monthly subscription starts' : 'From'}
          </DateLabel>
          <DateCalendarValue id={creator(ComponentType.TEXT, 'durationSummaryStartDate')}>
            {dayjs(duration.startDateTime).tz('Europe/London').format('ddd, Do MMM').toString()}
          </DateCalendarValue>
          <DateTimeValue id={creator(ComponentType.TEXT, 'durationSummaryStartTime')}>
            {duration.immediateStart
              ? '(Now)'
              : dayjs(duration.startDateTime).tz('Europe/London').format('HH:mm').toString()}
          </DateTimeValue>
        </DateSummary>
        <DateSummary
          id={creator(ComponentType.COMPONENT, 'dateSummary')}
          fullWidth={isSubscription}
        >
          {!isSubscription && (
            <>
              <DateLabel id={creator(ComponentType.TEXT, 'dateSummaryTo')}>To</DateLabel>
              <DateCalendarValue id={creator(ComponentType.TEXT, 'dateSummaryEndDate')}>
                {dayjs(duration.endDateTime).tz('Europe/London').format('ddd, Do MMM').toString()}
              </DateCalendarValue>
              <DateTimeValue id={creator(ComponentType.TEXT, 'dateSummaryEndTime')}>
                {dayjs(duration.endDateTime).tz('Europe/London').format('HH:mm').toString()}
              </DateTimeValue>
            </>
          )}
        </DateSummary>
      </DurationSummary>
    </QuoteRowContainer>
  );
};

export default QuoteDurationSummary;
