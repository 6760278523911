import Debug from 'debug';
import { push } from 'connected-react-router';
import { take, call, put, select, takeEvery } from 'redux-saga/effects';

import { types as customerTypes } from '@reducer/account/customer.actions';
import { setVariableExcess } from '@reducer/quote/importantInformation.actions';
import {
  updatePrice,
  types,
  saveQuoteSuccess,
  saveQuoteFailure,
  setSaveField,
} from '@reducer/quote/save.actions';
import { updateInitialPriceWithBreakdownCover } from '@reducer/quote/ancillary.actions';
import { customerLicenceTypeSelector } from '@reselectors/account';
import {
  getDefaultExcessForProduct,
  getIsSubscription,
  getProductType,
  getPreviousProductType,
} from '@reselectors/quote';
import quoteApi from '@services/api/quote';
import { isCassieFullLicence } from '@services/licence';
import { setAlternativeProduct } from '@redux/reducer/quote/alternativeProducts.actions';
import { optimizelyClient } from '@services/clients/optimizely';
import {
  getOptimizelyUserIdentity,
  optimizelyOverrideAttributes,
} from '@redux/reselectors/optimizely';
import sendOptimizelyEvent from '@services/sendOptimizelyEvent';
import { DirtyState } from '../utils/dirtyState';
import { sendEvent } from '../reducer/analytics/actions';

const stateUpdates = DirtyState.getInstance();

export const requiredCustomerFields = [
  'title',
  'firstName',
  'lastName',
  'birthdate',
  'occupation',
  'occupationType',
  'phoneNumber',
  'postcode',
];

const debug = Debug('veygo:sagas:save');

function* determineExcessAndPrice() {
  // checks that the selected excess still exists:
  // if so it updates the price else it resets to default excess
  const finalPricesByExcess = yield select(
    (state) => state.quote.importantInformation.finalPricesByExcess,
  );
  if (finalPricesByExcess.length > 0) {
    const excessSelected = yield select((state) => state.quote.importantInformation.excessSelected);
    const currentExcessAndPrice = finalPricesByExcess.find(
      (element) => element.excess === excessSelected,
    );
    if (currentExcessAndPrice === undefined) {
      const defaultExcess = yield select((state) => getDefaultExcessForProduct(state));
      yield put(setVariableExcess(defaultExcess));
    } else {
      const currentURL = yield select((state) => state.router.location.pathname);
      if (currentURL.includes('driving-history')) {
        yield put(updatePrice(currentExcessAndPrice.price));
      }
    }
  }
}

function* determineInitialPriceWithBreakdown() {
  const isBreakdownCover = yield select((state) => state.quote.ancillary.breakdownCover);
  const finalPricesByExcess = yield select(
    (state) => state.quote.importantInformation.finalPricesByExcess,
  );
  const excessSelected = yield select((state) => state.quote.importantInformation.excessSelected);

  if (finalPricesByExcess.length > 0 && excessSelected && isBreakdownCover) {
    const price = finalPricesByExcess.find((element) => element.excess === excessSelected);
    if (price !== undefined) {
      const priceWithBreakdown = price.price_with_breakdown.toString() || null;
      yield put(updateInitialPriceWithBreakdownCover(priceWithBreakdown));
    }
  } else {
    yield put(updateInitialPriceWithBreakdownCover(null));
  }
}

// eslint-disable-next-line consistent-return
export function rejectQuoteRedirectPage(failures) {
  const FAILURE_REASON_CODES = ['FR17'];
  let failureFound = false;
  failures.forEach((failure) => {
    if (FAILURE_REASON_CODES.filter((code) => code === failure.reason).length > 0) {
      failureFound = true;
    }
  });
  return failureFound ? 'rejection' : undefined;
}

// eslint-disable-next-line consistent-return
export function upgradeAccountRedirectPage(
  isLoggedIn,
  quoteLicenceType,
  quoteCassieLicenceType,
  customerLicenceType,
) {
  if (isLoggedIn) {
    if (customerLicenceType === 'uk_prov' && isCassieFullLicence(quoteCassieLicenceType)) {
      return 'licence-change/success';
    }
  } else if (quoteLicenceType === 'ukp' && isCassieFullLicence(quoteCassieLicenceType)) {
    return 'licence-change/error';
  }
}

// eslint-disable-next-line consistent-return
function* fetchAltProduct(uuid, productType) {
  const debugSave = debug.extend('saveQuoteSaga');
  try {
    const { passed_uw: passedUW, price } = yield call(quoteApi.getAltProduct, uuid, productType);
    yield put(setAlternativeProduct(productType, { passedUW, price }));

    return { passedUW, price };
  } catch (error) {
    debugSave('error', error);
  }
}

function* shouldCheckAltProduct() {
  const productType = yield select(getProductType);
  const previousProductType = yield select(getPreviousProductType);
  const isSubscription = yield select(getIsSubscription);
  const currentURL = yield select((state) => state.router.location.pathname);

  const userIdentity = yield select(getOptimizelyUserIdentity);
  const indicativePriceRemoved = optimizelyClient.isFeatureEnabled(
    'TEMP_INDICATIVE_PRICE_REMOVED',
    userIdentity,
  );
  return (
    (productType === 'tc' || (productType === 'newdriver' && previousProductType === 'tc')) &&
    isSubscription &&
    (indicativePriceRemoved
      ? currentURL.includes('duration') || currentURL.includes('duration-of-cover')
      : currentURL.includes('driving-history'))
  );
}

function* sendPageVisitEvents(page) {
  const pagesToSend = {
    'final-quote': 'pageVisit-finalQuote',
    'duration-of-cover': 'pageVisit-durationOfCover',
  };

  if (pagesToSend[page]) {
    const overrideAttributes = yield select(optimizelyOverrideAttributes);
    sendOptimizelyEvent(pagesToSend[page], overrideAttributes);
  } else {
    yield;
  }
}

export function* saveQuoteSaga({ page, switchProduct = false }) {
  const debugSave = debug.extend('saveQuoteSaga');
  debugSave('start');

  const isLoggedIn = yield select((state) => state.account.login.loggedIn);
  const isNewCar = yield select((state) => state.quote.car.isNewCar);
  const isBreakdownCover = yield select((state) => state.quote.ancillary.breakdownCover);

  const shouldCreateAncillary = (ancillaryId) => isBreakdownCover && !ancillaryId;
  const shouldUpdateAncillary = (ancillaryId) => isBreakdownCover && ancillaryId;

  const save = yield select((state) => state.quote.save);
  let callee;
  let ancillaryId;

  if (save && save.uuid) {
    debugSave('save, save.uuid', save, save.uuid);
    ancillaryId = save.ancillaryUuid;
    if (shouldCreateAncillary(ancillaryId)) {
      debugSave('creating ancillary');
      const ancillaryResponse = yield call(quoteApi.createAncillary, save.uuid);
      ancillaryId = ancillaryResponse.ancillary_id;
    } else if (shouldUpdateAncillary(ancillaryId)) {
      yield call(quoteApi.updateAncillary, save.uuid, ancillaryId);
    }
    if (!isBreakdownCover && ancillaryId) {
      yield call(quoteApi.deleteAncillary, save.uuid, ancillaryId);
      ancillaryId = null;
    }
    const quote = yield select((state) => state.quote);

    debugSave('updating quote');
    callee = call(quoteApi.update, save.uuid, quote);
  } else {
    debugSave('creating quote');
    const quote = yield select((state) => state.quote);
    callee = call(quoteApi.create, quote);
  }

  try {
    let destination = null;
    debugSave('calling api');

    const userIdentity = yield select(getOptimizelyUserIdentity);
    const onlyUpdateQuoteOnChange = optimizelyClient.isFeatureEnabled(
      'TEMP_UPDATE_QUOTE_ON_CHANGE',
      userIdentity,
      {
        user_identity: userIdentity,
      },
    );

    let data;
    if (onlyUpdateQuoteOnChange) {
      const quote = yield select((state) => state.quote);
      const currentURL = yield select((state) => state.router.location.pathname);

      if (stateUpdates.shouldCall(quote, currentURL)) {
        data = yield callee;
        stateUpdates.setStoredResponse(quote, data);
      } else {
        data = stateUpdates.getStoredResponse();
      }
    } else {
      data = yield callee;
    }

    const productType = yield select(getProductType);

    let altProductData;
    if (yield call(shouldCheckAltProduct)) {
      const newdriverCrossSellRejectionEnabled = optimizelyClient.isFeatureEnabled(
        'TEMP_ENABLE_NEWDRIVER_CROSS_SELL_REJECTION',
        userIdentity,
        {
          user_identity: userIdentity,
        },
      );

      const newdriverCrossSellSuccessEnabled = optimizelyClient.isFeatureEnabled(
        'TEMP_ENABLE_NEWDRIVER_CROSS_SELL_SUBS',
        userIdentity,
        {
          user_identity: userIdentity,
        },
      );

      const isRejectionEligible =
        newdriverCrossSellRejectionEnabled &&
        !data.underwriting_criteria &&
        productType !== 'newdriver';

      const isSuccessEligible =
        newdriverCrossSellSuccessEnabled && data.underwriting_criteria && data.price;
      if (isRejectionEligible || isSuccessEligible) {
        const previousProductType = yield select(getPreviousProductType);

        altProductData = yield call(
          fetchAltProduct,
          save.uuid,
          productType === 'newdriver' ? previousProductType : 'newdriver',
        );
      }
    }

    // if a customer chooses an excess, then goes back and is no longer eligible for that excess
    // then pricing returns null so their excess has to be reset
    const selectedExcess = yield select((state) => state.quote.importantInformation.excessSelected);
    if (data.excess !== selectedExcess) {
      yield put(setVariableExcess(data.excess));
    }

    data.isLoggedIn = isLoggedIn;
    data.isNewCar = isNewCar;
    data.ancillary_id = ancillaryId;

    // Setup the redirection for post save
    const customerLicenceType = yield select(customerLicenceTypeSelector);
    destination = upgradeAccountRedirectPage(
      isLoggedIn,
      data.licence_type,
      data.cassie_licence_type,
      customerLicenceType,
    );

    if (!destination) {
      destination = rejectQuoteRedirectPage(data.failure_reasons);
    }

    if (page && !destination) {
      destination = typeof page === 'function' ? page(data, altProductData, productType) : page;
    }

    // Check for excess
    const meta = { targetPage: destination };

    debugSave('putting saveQuoteSuccess', meta);
    yield put(saveQuoteSuccess(data, meta));
    yield call(sendPageVisitEvents, page);

    yield determineExcessAndPrice();

    yield determineInitialPriceWithBreakdown();

    if (switchProduct && destination) {
      const product = yield select(getProductType);
      destination = `/${destination}`;
      if (product === 'ldp') {
        window.location.pathname = `/learner-driver${destination}`;
      } else {
        window.location.pathname = `/car-sharing${destination}`;
      }
    } else if (destination) {
      debugSave(`pushing to ${destination}`);
      yield put(push(destination));
    }
  } catch (error) {
    debugSave('error', error);
    if (error.code === 'UNCONFIRMEDPROVISIONAL') {
      yield put(push('licence-change'));
    }
    yield put(saveQuoteFailure(error));
    stateUpdates.resetStoredResponse();
  }
}

export function* sendGAEvent(action) {
  if (action.data.reference_number) {
    yield put(
      sendEvent('quoteReference', {
        quoteReference: action.data.reference_number,
      }),
    );
  }
}

export function* genericQuoteSaveError({ error }) {
  if (error.code === 'THIRDPARTYFAILURE') {
    yield put(push('/error'));
  }
}

export function* watchSave() {
  yield takeEvery(types.SAVE_QUOTE_REQUEST, saveQuoteSaga);
  yield takeEvery(types.SAVE_QUOTE_SUCCESS, sendGAEvent);
  yield takeEvery(types.SAVE_QUOTE_FAILURE, genericQuoteSaveError);
}
