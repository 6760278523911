import { useLocation } from 'react-router-dom';

export enum ComponentType {
  TITLE = 'title',
  SUBTITLE = 'subtitle',
  BUTTON = 'button',
  SPACING = 'spacing',
  TEXT = 'text',
  INPUT = 'input',
  LINK = 'link',
  ICON = 'icon',
  IMAGE = 'image',
  PARAGRAPH = 'paragraph',
  CONTAINER = 'container',
  COMPONENT = 'component',
  FIELD = 'field',
}

type UseComponentIdReturnType = (type: ComponentType, id?: string) => string;

/**
 * React hook for generating unique component IDs based on the location, component type and optional custom ID.
 * It ensures that IDs are unique within the scope of the current page's pathname.
 *
 * @returns {UseComponentIdReturnType} A function that takes a `ComponentType` and an optional custom `id`, and returns a unique string ID for the component.
 */
const useComponentId = (): UseComponentIdReturnType => {
  const location = useLocation();

  const createdIds = new Set<string>();

  /**
   * Generates a unique ID for a component based on its type and an optional custom ID.
   * The generated ID is unique within the scope of the current page's pathname.
   *
   * @param {ComponentType} type - The type of the component (e.g., title, subtitle).
   * @param {string} [id] - An optional custom ID, required if there are multiple of the same components. Should be in the format \([a-zA-z]_)*\.
   * @returns {string} A unique string ID for the component, constructed from the page's pathname, the component type, and the optional custom ID.
   * @throws {Error} If a duplicate component ID is attempted to be created.
   */
  return (type: ComponentType, id?: string): string => {
    const page = location.pathname.split('/')[1];
    const formattedPage = page
      .split('-')
      .map((part, index) => {
        if (index === 0) return part; // Leave the first part as it is
        return part.charAt(0).toUpperCase() + part.slice(1);
      })
      .join('');

    const formattedId = id ? `-${id}` : '';
    const componentId = `${formattedPage}-${type}${formattedId}`;

    if (createdIds.has(componentId)) {
      throw new Error(`Duplicate component id for: ${componentId}`);
    }

    createdIds.add(componentId);

    return componentId;
  };
};

export default useComponentId;
