import React, { FC } from 'react';
import { ComponentType, useComponentId } from '@hooks';

import {
  Container,
  TextContainer,
  QuestionType,
  QuestionDescription,
  TickBoxContainer,
  TickboxWrapper,
} from './styles';

import { Props } from './types';

const QuestionRadioItem: FC<Props> = ({
  onSelect,
  value,
  title,
  id,
  description,
  applyTransparency,
  isSelected,
}) => {
  const creator = useComponentId();
  return (
    <Container onClick={(): void => onSelect(value)}>
      <TextContainer applyTransparency={applyTransparency}>
        <QuestionType id={id}>{title}</QuestionType>
        <QuestionDescription id={creator(ComponentType.TEXT, `${id}Description`)}>
          {description}
        </QuestionDescription>
      </TextContainer>
      <TickboxWrapper>
        <TickBoxContainer applyTransparency={applyTransparency} isSelected={isSelected} />
      </TickboxWrapper>
    </Container>
  );
};

export default QuestionRadioItem;
