import React from 'react';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';

import { getIPIDUrl, getPolicyBookletUrl } from '@reselectors/files';

import colors from '@config/colors';
import size from '@config/size';

interface Props {
  push: () => void;
  faqUrl: string;
  keyFactsUrl?: string;
  yaweuiUrl?: string;
  policyBookletUrl: string;
  helpCentreUrl: string;
  IPIDUrl: string;
  conditionsOfUseUrl: string;
  pathname: string;
}

const Container = styled.footer`
  color: ${colors.white};
  text-align: center;
  display: none;
  ${css`
    @media (min-width: ${size.desktopWidth}) {
      display: block;
    }
  `};
`;

const BottomSection = styled.div`
  background: ${colors.outerSpace};
  font-size: 14px;
  line-height: 18px;
  padding: 20px 0 15px;
`;

const FooterLinks = styled.ul`
  margin-bottom: 10px;
  display: flex;
  width: 100%;
  list-style-type: none;
  align-items: center;
  justify-content: center;
`;

const FooterLinkItem = styled.li``;

const FooterLink = styled.a`
  color: ${colors.white};
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    color: ${colors.blue.important};
    text-decoration: none;
  }
`;

const FooterLinkSeparator = styled.li`
  margin: 0 16px;

  &:after {
    content: '|';
  }
`;

const CopyrightLine = styled.div`
  font-size: 12px;
  line-height: 14px;
  position: relative;
`;

const showPolicyBookIpidLinks = (path: string) => {
  const hidePolicyBookIpidLinksOnPages = ['quote-start', 'account'];
  // eslint-disable-next-line no-restricted-syntax
  for (const item of hidePolicyBookIpidLinksOnPages) {
    if (path.includes(item)) {
      return false;
    }
  }
  return true;
};

const Footer: React.FC<Props> = ({
  conditionsOfUseUrl,
  yaweuiUrl,
  faqUrl,
  helpCentreUrl,
  pathname,
}) => {
  const policyBookletUrl = useSelector((state) => getPolicyBookletUrl(state));
  const IPIDUrl = useSelector((state) => getIPIDUrl(state));

  return (
    <Container>
      <BottomSection>
        <FooterLinks id='quote-list-footerOne'>
          <FooterLinkItem>
            <FooterLink
              id='quote-link-footerListItemOne'
              href={yaweuiUrl}
              target='_blank'
              rel='noopener noreferrer'
            >
              Your Agreement with Able Insurance Services Limited
            </FooterLink>
          </FooterLinkItem>
          {showPolicyBookIpidLinks(pathname) && (
            <>
              <FooterLinkSeparator />
              <FooterLinkItem>
                <FooterLink
                  id='quote-link-footerListItemTwo'
                  href={IPIDUrl}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Product Information Guide
                </FooterLink>
              </FooterLinkItem>
            </>
          )}
          <FooterLinkSeparator />
          <FooterLinkItem>
            <FooterLink
              id='quote-link-footerListItemThree'
              href={conditionsOfUseUrl}
              target='_blank'
              rel='noopener noreferrer'
            >
              Conditions of Use
            </FooterLink>
          </FooterLinkItem>
        </FooterLinks>
        <FooterLinks id='quote-list-footerTwo'>
          {showPolicyBookIpidLinks(pathname) && (
            <>
              <FooterLinkItem>
                <FooterLink
                  id='quote-link-footerListItemOne'
                  href={policyBookletUrl}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Policy Book
                </FooterLink>
              </FooterLinkItem>
              <FooterLinkSeparator />
            </>
          )}
          <FooterLinkItem>
            <FooterLink
              id='quote-link-footerListItemTwo'
              href={faqUrl}
              target='_blank'
              rel='noopener noreferrer'
            >
              FAQ
            </FooterLink>
          </FooterLinkItem>
          <FooterLinkSeparator />
          <FooterLinkItem>
            <FooterLink
              id='quote-link-footerListItemThree'
              href={helpCentreUrl}
              target='_blank'
              rel='noopener noreferrer'
            >
              Help Centre
            </FooterLink>
          </FooterLinkItem>
          <FooterLinkSeparator />
          <FooterLinkItem>
            <FooterLink
              id='quote-link-footerListItemFour'
              href='https://www.veygo.com/privacy-policy/'
              target='_blank'
              rel='noopener noreferrer'
            >
              Privacy & Security
            </FooterLink>
          </FooterLinkItem>
          <FooterLinkSeparator />
          <FooterLinkItem>
            <FooterLink
              id='quote-container-footerListItemFive'
              href='https://www.veygo.com/cookie-policy/'
              target='_blank'
              rel='noopener noreferrer'
            >
              Cookie Policy
            </FooterLink>
          </FooterLinkItem>
        </FooterLinks>
        <CopyrightLine id='quote-text-footerCopyright'>
          Veygo, Tŷ Admiral, David Street, Cardiff, CF10 2EH
        </CopyrightLine>
      </BottomSection>
    </Container>
  );
};

export default Footer;
