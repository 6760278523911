import React from 'react';
import styled from 'styled-components';

import moment, { Moment } from 'moment';
import { QuoteSummary } from './PricingComponents';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const SectionContainer = styled.div`
  border: ${({ theme }) => theme.divider};
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const QuoteDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: ${({ theme }) => theme.backgroundAlt};
  padding: 16px;
  > * {
    width: 100%;
  }
`;

const BoldText = styled.span`
  color: ${({ theme }) => theme.textPrimary};
  font-family: ${({ theme }) => theme.standard};
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 161.8%;
`;

const NormalText = styled.span`
  color: ${({ theme }) => theme.textPrimary};
  font-family: ${({ theme }) => theme.standard};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 161.8%;
`;

const QuoteDetailsRow = ({ label, value, id }: { label: string; value: string; id: string }) => (
  <div id={id} style={{ display: 'flex', justifyContent: 'space-between' }}>
    <NormalText id='productChoice-title-mainChoicePricingElementquoteDetailsRow'>
      {label}
    </NormalText>
    <BoldText id='productChoice-text-mainChoicePricingElementquoteDetailsRow'>{value}</BoldText>
  </div>
);

interface Props {
  duration: { startDateTime: Moment; immediateStart: boolean };
  isSubscription: boolean;
  breakdownCover: boolean;
  carDetails: string;
  policyHolderName: string;
  telematicsSelected: boolean;
  telematicsPrice: string;
  nonTelematicsPrice: string;
}

export const MainChoicePricingElement = (props: Props): JSX.Element => {
  const {
    duration,
    isSubscription,
    breakdownCover,
    policyHolderName,
    carDetails,
    telematicsSelected,
    telematicsPrice,
    nonTelematicsPrice,
  } = props;
  const startDateFormatted = `
    ${moment.tz(duration.startDateTime, 'Europe/London').format('ddd, Do MMM').toString()} 
    ${
      duration.immediateStart
        ? '(Now)'
        : moment.tz(duration.startDateTime, 'Europe/London').format('HH:mm').toString()
    }
  `;
  return (
    <Container>
      <SectionContainer>
        <QuoteDetails>
          <BoldText id='productChoice-title-mainChoicePricingElementCarDetails'>
            {carDetails}
          </BoldText>
          <QuoteDetailsRow
            id='productChoice-text-mainChoicePricingElementStartDate'
            label='Start date:'
            value={startDateFormatted}
          />
          <QuoteDetailsRow
            id='productChoice-text-mainChoicePricingElementPolicyHolder'
            label='Policy holder:'
            value={policyHolderName}
          />
          {breakdownCover && (
            <QuoteDetailsRow
              id='productChoice-text-mainChoicePricingElementBreakdownCover'
              label='add-ons'
              value='UK BREAKDOWN COVER'
            />
          )}
        </QuoteDetails>
      </SectionContainer>
      <SectionContainer>
        <QuoteSummary
          header={
            <BoldText id='productChoice-text-mainChoicePricingElementInsuranceType'>
              {telematicsSelected ? 'New Driver Insurance' : 'Flexible Insurance'}
            </BoldText>
          }
          footer=''
          loading={false}
          price={telematicsSelected ? telematicsPrice : nonTelematicsPrice}
          initialPrice={nonTelematicsPrice}
          crossSaleInitialPrice={null}
          isSubscription={isSubscription}
          isTelematics={telematicsSelected}
          subscriptionText='per month'
        />
      </SectionContainer>
    </Container>
  );
};

export default MainChoicePricingElement;
