import moment, { Moment } from 'moment-timezone';

const calculateSubscriptionEndDate = (): Moment => {
  const start = moment().tz('Europe/London');
  const startDay = start.date();
  const numDaysNextMonth = moment().add(1, 'M').daysInMonth();
  const startPlusOneMonth = start.add(1, 'M').hours(23).minutes(59).seconds(59);

  if (startDay > numDaysNextMonth) {
    return startPlusOneMonth.date(numDaysNextMonth);
  }

  return startPlusOneMonth.subtract(1, 'd');
};

export default calculateSubscriptionEndDate;
