import { call, put, select, takeEvery, take } from 'redux-saga/effects';
import { push, LOCATION_CHANGE } from 'connected-react-router';
import _ from 'lodash';

import CustomersClient from '@services/clients/customers';
import { remoteToLocalLicenceFields } from '@services/transformers/customers';
import { setProduct } from '@reducer/quote/product.actions';
import { setUserField } from '@reducer/quote/user.actions';
import { statedTestPassDate } from '@reselectors/account';
import { setLicenceField, changeLicenceType } from '@reducer/quote/licence.actions';
import handleError from '@redux/utils/errorHandler';
import { selectLocation } from './tools';
import { address as addressApi, customer as customerApi } from '../../services/api';
import {
  PROFILE_FROM_QUOTE,
  CUSTOMER_FETCH_ME_REQUEST,
  CUSTOMER_SAVE_REQUEST,
  CUSTOMER_UPGRADE_REQUEST,
  CUSTOMER_SAVE_FINISH_SUCCESS,
  CUSTOMER_SAVE_FINISH_REQUEST,
  types,
  fetchMe,
  fetchMeSuccess,
  fetchMeFailure,
  saveCustomerSuccess,
  saveCustomerFailure,
  saveCustomerAndFinishSuccess,
  saveCustomerAndFinishFailure,
  setCustomerField,
  upgradeCustomerSuccess,
  setDriverFromQuote,
} from '../reducer/account/customer.actions';
import { types as loginTypes, logout } from '../reducer/account/login.actions';

function licenceAlreadyExists(data) {
  return data.uw_failure_reasons.some((elem) => elem.reason === 'FR13');
}

export const getAccountLicenceType = (state) => state.account.customer.licence.type;
export const getCurrentPage = (state) => state.router.location.pathname;
export const getProductType = (state) => state.quote.product.productType;

export function* fetchMeSaga() {
  try {
    let data = yield call(CustomersClient.getV3);
    // Call csi backend to get additional product-related customer data
    const additionalData = yield call(customerApi.retrieveCustomerAdditionalData);
    data = _.merge({}, data, additionalData);

    yield put(fetchMeSuccess(data));
    // Set the product when the customer logs in
    // Currently we do it based on licence type seleciton on quote start and is defaulted
    // to CSI, when logging in as an LDP user, it means it's defaulted to CSI.
    const page = yield select(getCurrentPage);
    if (page.includes('start')) {
      const productType = yield select(getProductType);
      if (data.licence_type === 'ukp' && productType !== 'newdriver') {
        yield put(setProduct('ldp'));
      }
    }
    yield put(setUserField('status', 'driver'));
  } catch (error) {
    yield put(fetchMeFailure(error));
    if (error.status === 404) {
      yield put(logout());
    }
  }
}

export const getCustomer = (state) => state.account.customer;
export const getLoggedIn = (state) => state.account.login.loggedIn;
export const getAddress = (state) => state.account.address[0];
export const getRegistrationUuid = (state) => state.account.register.customerUuid;
export const getRegistrationEmail = (state) => state.account.register.email;
export const getRegistrationPassword = (state) => state.account.register.password;
export const getRegistrationToken = (state) => state.account.register.registrationToken;
export const getLoginToken = (state) => state.account.login.token;
export const getQuote = (state) => state.quote;

export function* saveCustomerSaga(action) {
  const registrationUuid = yield select(getRegistrationUuid);
  const uuid = registrationUuid;
  const quote = yield select(getQuote);
  yield put(setDriverFromQuote(uuid, quote));
  const addressData = yield select(getAddress);

  const customerData = yield select(getCustomer);
  try {
    let data = yield call(CustomersClient.putV3, { ...customerData, address: addressData });
    // Call csi backend to get additional product-related customer data
    const additionalData = yield call(customerApi.retrieveCustomerAdditionalData);
    data = _.merge({}, data, additionalData);

    yield put(saveCustomerSuccess(data));
    yield put(setCustomerField('hasEdited', false));
    yield put(setCustomerField('hasEditedPersonalDetails', false));
    yield put(push(action.page));
  } catch (error) {
    handleError(error);
    yield put(saveCustomerFailure(error));
  }
}

// eslint-disable-next-line consistent-return
export function* saveCustomerAndFinish({ destination }) {
  const addressData = yield select(getAddress);
  const customerData = yield select(getCustomer);
  const customerApiAction = customerData.isNew
    ? customerApi.registrationComplete
    : customerApi.update;

  try {
    if (!customerData.isNew) {
      yield call(addressApi.save, addressData);
    }
  } catch (error) {
    yield put(saveCustomerAndFinishFailure());
    return yield put(push('/account/edit/address'));
  }

  try {
    const data = yield call(customerApiAction, customerData.uuid, customerData);

    // eslint-disable-next-line no-throw-literal
    if (licenceAlreadyExists(data)) throw { code: 'FR13' };

    // Copy registration token to login reducer
    const regToken = yield select(getRegistrationToken);
    const loginToken = yield select(getLoginToken);

    const token = customerData.isNew ? regToken : loginToken;

    yield put(saveCustomerAndFinishSuccess(data, token));
    yield put(setCustomerField('hasEdited', false));
    yield put(setCustomerField('hasEditedPersonalDetails', false));
    yield put(setCustomerField('display30DayEditModal', false));
    if (destination) {
      yield put(push(destination));
    }
  } catch (error) {
    yield put(saveCustomerAndFinishFailure(error));
  }
}

export function* doFetchMe() {
  const loggedIn = yield select(getLoggedIn);
  if (loggedIn) yield put(fetchMe());
}

// eslint-disable-next-line consistent-return
export function* upgrade() {
  try {
    const testPassDate = yield select(statedTestPassDate);

    const buildCustomerUpgradePayload = yield select((state) => ({
      licenceType: state.account.customer.licence.type,
      licenceNumber: state.account.customer.licence.number,
      date: testPassDate,
    }));

    const response = yield call(customerApi.upgradeLicence, buildCustomerUpgradePayload);
    const data = remoteToLocalLicenceFields(response);
    yield put(upgradeCustomerSuccess(data));

    if (testPassDate) {
      yield put(setLicenceField('fullLicenceConfirmed', true));
      yield put(setLicenceField('fullLicenceDate', testPassDate));
    }
    yield put(changeLicenceType('uk_manual'));

    const productType = yield select(getProductType);
    if (productType === 'newdriver') {
      return yield put(push('/quote-start'));
    }

    return yield put(push('/start'));
  } catch (error) {
    /*
    There's been no error handling here for 6+ years, we need to look into
    how we expect the frontend to react to a licence upgrade failure?
    */
    handleError(error);
    // todo
  }
}

export function* watchCustomer() {
  yield takeEvery(CUSTOMER_FETCH_ME_REQUEST, fetchMeSaga);
  yield takeEvery(CUSTOMER_SAVE_REQUEST, saveCustomerSaga);
  yield takeEvery(CUSTOMER_SAVE_FINISH_REQUEST, saveCustomerAndFinish);
  yield takeEvery(
    [
      loginTypes.LOGIN_SUCCESS,
      selectLocation('/account/connections'),
      selectLocation('/documents'),
      selectLocation('/account'),
    ],
    doFetchMe,
  );
  yield takeEvery(CUSTOMER_UPGRADE_REQUEST, upgrade);
}
