import moment, { Moment } from 'moment';

const ONE_DAY_IN_HOURS = 24;
const ONE_WEEK_IN_HOURS = ONE_DAY_IN_HOURS * 7;

export interface DefaultDuration {
  value: string;
  tab: string;
  inHours: number;
}

const LearnerDefaultDurations = [
  {
    minAge: 0,
    duration: {
      value: '4-week',
      tab: 'weeks',
      inHours: ONE_WEEK_IN_HOURS * 4,
    },
  },
  {
    minAge: 18,
    duration: {
      value: '2-week',
      tab: 'weeks',
      inHours: ONE_WEEK_IN_HOURS * 2,
    },
  },
  {
    minAge: 35,
    duration: {
      value: '4-week',
      tab: 'weeks',
      inHours: ONE_WEEK_IN_HOURS * 4,
    },
  },
];

const TempcoverDefaultDurations = [
  {
    minAge: 0,
    duration: {
      value: '3-day',
      tab: 'days',
      inHours: ONE_DAY_IN_HOURS * 3,
    },
  },
  {
    minAge: 20,
    duration: {
      value: '2-day',
      tab: 'days',
      inHours: ONE_DAY_IN_HOURS * 2,
    },
  },
  {
    minAge: 27,
    duration: {
      value: '3-day',
      tab: 'days',
      inHours: ONE_DAY_IN_HOURS * 3,
    },
  },
  {
    minAge: 35,
    duration: {
      value: '5-day',
      tab: 'days',
      inHours: ONE_DAY_IN_HOURS * 5,
    },
  },
];

export default (product: string, birthdate: Moment | null): DefaultDuration => {
  const age = moment().diff(birthdate ?? moment(), 'y');

  return (product === 'ldp' ? LearnerDefaultDurations : TempcoverDefaultDurations)
    .filter((duration) => duration.minAge <= age)
    .slice(-1)[0].duration;
};
