import React, { useState } from 'react';
import { WrappedFieldProps, Field } from 'redux-form';

import validation from '@services/formValidation';

import { Address } from '@redux/types';
import { AddressCard, AddressSpeechBox } from '@rentecarlo/component-library';
import normalizeAlphaNumericOrSpace from '@services/formNormalization';
import PostcodeSearchBox from './PostcodeSearchBox';

type Props = {
  addresses: Array<Address & { key: string }>;
  isPostcodeValid: boolean;
  lookupAddress: (postcode: string | null | undefined) => void;
  postcode: string;
  setAddressFields: (address: Address) => void;
  postcodeNotFound: string;
};

const AddressSearch: React.FC<Props & WrappedFieldProps> = ({
  isPostcodeValid,
  addresses,
  postcode,
  input,
  setAddressFields,
  lookupAddress,
  meta,
  postcodeNotFound,
}) => {
  const [showAddress, toggleAddressCard] = useState(addresses[parseInt(input.value)]);
  const address = addresses[parseInt(input.value)];
  return (
    <>
      {!showAddress && (
        <>
          <Field
            name='postcode'
            id='postcode'
            validate={[validation.isRequired, validation.isCoveredPostcode]}
            component={PostcodeSearchBox}
            normalize={normalizeAlphaNumericOrSpace}
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
              setAddressFields({ postcode: event.target.value })
            }
            props={{
              onSearch: (): void => lookupAddress(postcode),
              isValid: isPostcodeValid,
              buttonId: 'searchButton',
              postcodeNotFound,
            }}
          />
          <AddressSpeechBox
            id='addressKey'
            text='Please select your address'
            onChange={input.onChange}
            selectedAddress={input.value}
            addresses={addresses}
            visible={addresses.length > 0}
            footerText={`${addresses.length} addresses found`}
            toggleAddressCard={(): void => toggleAddressCard(true)}
            touched={meta.touched}
            error={meta.error}
            active={meta.active}
            pristine={meta.pristine}
            submitFailed={meta.submitFailed}
          />
        </>
      )}
      {address && showAddress && (
        <AddressCard
          id='addressCard'
          address={address}
          toggleAddressCard={(): void => toggleAddressCard(false)}
        />
      )}
    </>
  );
};

export default AddressSearch;
