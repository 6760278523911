import React from 'react';
import { Field } from 'redux-form';
import TextInputField from '@atoms/inputs/TextInputField';
import Group from '@atoms/layout/form/Group';
import { FieldContainer } from '@rentecarlo/component-library';
import { SectionTitle } from '@config/importantInformation';
import { TempcoverReasonField } from '@organisms/modals/TempcoverReasonField';
import ReduxFieldValidation from '@services/formValidation';
import { ComponentType, useComponentId } from '@hooks';
import { ElementContainer } from '../assets/styles';

interface Props {
  initialValues: {
    tempcoverReason: string;
    relationship: string;
  };
  tempcoverReason: string;
  isNewCar: boolean;
  openTempcoverReasonModal(e: React.SyntheticEvent): void;
  closeTempcoverReasonModal: () => void;
  tempcoverModalOpen: boolean;
  changeToCsi: () => void;
  productType: string;
  submitFailed: boolean;
  showError: boolean;
  error: string;
}

const ReasonForCoverField: React.FC<Props> = (props) => {
  const creator = useComponentId();
  const {
    initialValues,
    tempcoverReason,
    isNewCar,
    openTempcoverReasonModal,
    closeTempcoverReasonModal,
    tempcoverModalOpen,
    changeToCsi,
    productType,
    showError,
    error,
  } = props;

  return (
    <Group data-testid='cover-reason'>
      <SectionTitle id={creator(ComponentType.TITLE, 'reasonForCover')}>
        What is the reason you need temporary cover?
      </SectionTitle>
      <FieldContainer
        id={creator(ComponentType.CONTAINER, 'tempCoverReason')}
        showErrorContainer={showError}
        showError={showError}
        error={error}
      >
        <Field
          id={creator(ComponentType.FIELD, 'tempcoverReasonSelection')}
          name='tempcoverReason'
          component={TempcoverReasonField}
          disabled={initialValues.tempcoverReason && !isNewCar}
          validate={[ReduxFieldValidation.required]}
          onClose={closeTempcoverReasonModal}
          onOpen={(event: React.SyntheticEvent): void => {
            window.scrollTo(0, 0);
            openTempcoverReasonModal(event);
          }}
          modalOpen={tempcoverModalOpen}
          productType={productType}
          changeToCsi={changeToCsi}
        />
        {tempcoverReason === 'tc_other' && (
          <ElementContainer>
            <Field
              name={creator(ComponentType.FIELD, 'tempcoverAltReason')}
              component={TextInputField}
              placeholder='Please enter reason for cover'
              validate={[ReduxFieldValidation.required]}
            />
          </ElementContainer>
        )}
      </FieldContainer>
    </Group>
  );
};

export default ReasonForCoverField;
