import React from 'react';
import { RadioButtonCard } from '@rentecarlo/component-library';
import { WrappedFieldProps } from 'redux-form';

import { PaymentMethod } from '@utils/types';

import { List, Spacer } from './assets/styles';

const PaymentMethodSelector: React.FC<WrappedFieldProps> = ({ input: { value, onChange } }) => {
  return (
    <>
      <RadioButtonCard
        id='chooseYourCover-component-oneOff'
        text='One-off short term policy'
        checked={value === PaymentMethod.oneOff}
        value={PaymentMethod.oneOff}
        onSelect={onChange}
      >
        <List>
          <li id='chooseYourCover-text-oneOffListOne'>Cover from 1 hour</li>
          <li id='chooseYourCover-text-oneOffListTwo'>One-off payment</li>
          <li id='chooseYourCover-text-oneOffListThree'>Fully comprehensive</li>
        </List>
      </RadioButtonCard>
      <Spacer />
      <RadioButtonCard
        id='chooseYourCover-component-subscriptions'
        text='Monthly subscription'
        checked={value === PaymentMethod.subscription}
        value={PaymentMethod.subscription}
        onSelect={onChange}
        labelText='New!'
      >
        <List>
          <li id='chooseYourCover-text-subscriptionsListOne'>
            Rolling cover for as long as you need
          </li>
          <li id='chooseYourCover-text-subscriptionsListTwo'>Pay monthly</li>
          <li id='chooseYourCover-text-subscriptionsListThree'>Cancel anytime, with no fees</li>
          <li id='chooseYourCover-text-subscriptionsListFour'>Fully comprehensive</li>
        </List>
      </RadioButtonCard>
      <Spacer />
    </>
  );
};

export default PaymentMethodSelector;
