/* eslint-disable no-param-reassign */
import Moment from 'moment';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);

const MIN_HOURS = 1;

const MAX_CSI_MINUTES = 60 * 24 * 60;
const MAX_LDP_MINUTES = 180 * 24 * 60;

// Cover must be longer than a certain duration
export const isCoverLongerThanMinimumDuration = (startDateTime, endDateTime) => {
  if (!startDateTime || !endDateTime) return true;

  return endDateTime.diff(startDateTime, 'hours', true) >= MIN_HOURS;
};

// Cover must be less than a certain duration
export const isCoverShorterThanMaximumDuration = (startDateTime, endDateTime, productType) => {
  if (!startDateTime || !endDateTime) return true;

  const maxDuration = productType === 'ldp' ? MAX_LDP_MINUTES : MAX_CSI_MINUTES;
  return endDateTime.diff(startDateTime, 'minutes', false) <= maxDuration;
};

// Cover must end after it starts
export const isCoverEndTimeAfterCoverStartTime = (startDateTime, endDateTime) =>
  !startDateTime || !endDateTime || endDateTime.isAfter(startDateTime);

// Cover can't be in the past
export const isCoverStartTimeInFuture = (startDateTime, immediateStart) =>
  immediateStart || !startDateTime || moment().isBefore(startDateTime);

// Cover can't start between 10pm and 5am if the quote duration is less than 24 hours.
export const isCoverStartTimeValid = (startDateTime, endDateTime) => {
  if (!startDateTime || !endDateTime) return true;

  // clone to avoid mutating the original datetime objects
  startDateTime = startDateTime.clone();
  endDateTime = endDateTime.clone();

  if (endDateTime.diff(startDateTime, 'hours') >= 24) return true;

  const isStartTimeBefore10 =
    startDateTime.hour() < 22 || (startDateTime.hour() === 22 && startDateTime.minute() === 0);

  if (startDateTime.hour() >= 5 && isStartTimeBefore10) return true;

  return false;
};

// Cover can't end between 10pm and 5am if the quote duration is less than 24 hours.
export const isCoverEndTimeValid = (startDateTime, endDateTime) => {
  if (!startDateTime || !endDateTime) return true;

  // clone to avoid mutating the original datetime objects
  startDateTime = startDateTime.clone();
  endDateTime = endDateTime.clone();

  if (endDateTime.diff(startDateTime, 'hours') >= 24) return true;

  if (endDateTime.hour() >= 5 && endDateTime.hour() < 22) return true;

  if (endDateTime.hour() >= 5 && endDateTime.hour() === 22 && endDateTime.minute() === 0)
    return true;

  return false;
};

// We don't allow user that want to buy a policy from 10pm to 5am
export const isCoverEdgeCase = (startDateTime, endDateTime) => {
  const isStartTime10pm = startDateTime.hour() === 22 && startDateTime.minute() === 0;
  const isEndTime5am = endDateTime.hour() === 5 && endDateTime.minute() === 0;
  return isStartTime10pm && isEndTime5am;
};

export const isCoverDurationTimeValid = (startDateTime, endDateTime) => {
  if (!startDateTime || !endDateTime) return true;

  // clone to avoid mutating the original datetime objects
  startDateTime = startDateTime.clone();
  endDateTime = endDateTime.clone();

  // check if >24h
  if (endDateTime.diff(startDateTime, 'hours') >= 24) return true;

  // check 22 to 5am
  if (isCoverEdgeCase(startDateTime, endDateTime)) return false;

  const isCoverStartValid = isCoverStartTimeValid(startDateTime, endDateTime);
  const isCoverEndValid = isCoverEndTimeValid(startDateTime, endDateTime);
  return isCoverStartValid && isCoverEndValid;
};

// Cover can't start if the start date is 60 days in the future
export const isCoverStartBefore60Days = (startDateTime) => {
  // return false if we can't proceed
  // true otherwise
  if (!startDateTime) return true;

  // clone to avoid mutating the original datetime objects
  startDateTime = startDateTime.clone();

  if (startDateTime.diff(moment(), 'days') >= 60) return false;

  return true;
};

export const isCoverDurationInRestrictedPeriod = (startDateTime, endDateTime, restrictedPeriod) => {
  if (restrictedPeriod.length > 0 && startDateTime && endDateTime) {
    const periodRange = moment.range(restrictedPeriod[0].start_date, restrictedPeriod[0].end_date);
    const durationRange = moment.range(startDateTime, endDateTime);
    const coverIsLessThanMinimumDuration =
      endDateTime.diff(startDateTime, 'hours') < restrictedPeriod[0].min_duration;

    return periodRange.overlaps(durationRange) && coverIsLessThanMinimumDuration;
  }
  return false;
};
