/**
 * Quote API implemented with RTK Query.
 *
 * { @link https://redux-toolkit.js.org/rtk-query/overview | RTK Query Overview }
 * */

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { getCookie, retrieveCognitoBearerToken } from './httpClient';

interface CheckoutResponse {
  driver_email: string;
  driver_first_name: string;
  driver_last_name: string;
  is_first_purchase: boolean;
  is_subscription: boolean;
  price: string;
  product_type: string;
  reference_number: string;
  utm_source: string;
  commission_group: string;
  duration_start: string;
  duration_end: string;
  subscription_reference_number: string;
  number_of_policies: number;
}

/**
 * {@link https://redux-toolkit.js.org/rtk-query/overview#create-an-api-slice | RTK Query Overview > Basic Usage}
 *
 * {@link https://redux-toolkit.js.org/rtk-query/api/createApi | RTK Query > API Reference > createApi}
 *
 * {@link https://redux-toolkit.js.org/rtk-query/usage-with-typescript#createapi | Usage with Typescript}
 * */
export const quoteApi = createApi({
  reducerPath: 'quoteApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_CSI_BASE_URL}/`,
    prepareHeaders: async (headers) => {
      const authorization = await retrieveCognitoBearerToken();
      if (typeof authorization === 'string') {
        headers.set('Authorization', authorization);
      }

      const csrfTokenValue = getCookie('csrftoken');
      headers.set('CSRF', csrfTokenValue);
      headers.set('Content-Type', 'application/json');
      headers.set('accept', 'application/json');
      return headers;
    },
  }),
  endpoints: (builder) => ({
    /** {@link https://redux-toolkit.js.org/rtk-query/usage/mutations | Using RTK Query > Mutations} */
    createReferral: builder.mutation({
      query: ({ quoteUuid, buyapowaEncryptedValue }) => ({
        url: `api/quotes/${quoteUuid}/referrals`,
        method: 'POST',
        body: { bp_e: buyapowaEncryptedValue },
      }),
    }),
    checkout: builder.query({
      query: (quoteUuid) => {
        if (!quoteUuid) {
          throw new Error(`quoteUuid is required`);
        }

        return {
          url: `tempcover/v1/policies/${quoteUuid}/checkout/`,
          method: 'GET',
          responseHandler: 'json',
        };
      },
      transformResponse: (response: CheckoutResponse) => {
        return {
          driverEmail: response.driver_email,
          driverFirstName: response.driver_first_name,
          driverLastName: response.driver_last_name,
          isFirstPurchase: response.is_first_purchase,
          isSubscription: response.is_subscription,
          price: response.price,
          productType: response.product_type,
          referenceNumber: response.reference_number,
          utmSource: response.utm_source,
          commissionGroup: response.commission_group,
          durationStart: response.duration_start,
          durationEnd: response.duration_end,
          subscriptionReferenceNumber: response.subscription_reference_number,
          numberOfPolicies: response.number_of_policies,
        };
      },
    }),
  }),
});

// These hooks are the primary way to use RTK query.
// You just import them and use them in components,
// and use their `data`, `error`, `isLoading` properties directly.
// See: https://redux-toolkit.js.org/rtk-query/overview#use-hooks-in-components }
export const { useCreateReferralMutation, useCheckoutQuery } = quoteApi;
