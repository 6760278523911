import { types as loginTypes, LoginActiontypes } from './login.actions';
import { types as appTypes, AppTypeActions } from '../app/actions';
import { CUSTOMER_SAVE_FINISH_SUCCESS, SaveCustomerAndFinishSuccess } from './customer.actions';
import { types as registerTypes, RegisterSuccess } from './register.actions';

export interface LoginState {
  email: string;
  errorType: string | null;
  loading: boolean;
  loggedIn: boolean;
  password: string;
  postLoginPage: string;
}

// Reducer
export const initialState: LoginState = {
  email: '',
  errorType: null,
  loading: false,
  loggedIn: false,
  password: '',
  postLoginPage: '/start',
};

export type LoginTypes =
  | LoginActiontypes
  | AppTypeActions
  | SaveCustomerAndFinishSuccess
  | RegisterSuccess;
export default function loginReducer(state = initialState, action: LoginTypes): LoginState {
  switch (action.type) {
    case loginTypes.LOGIN_REQUEST:
      return {
        ...state,
      };
    case loginTypes.LOGIN_SUCCESS:
      return {
        ...state,
        email: '',
        errorType: null,
        loading: false,
        loggedIn: true,
        password: '',
      };
    case registerTypes.REGISTER_SUCCESS:
    case CUSTOMER_SAVE_FINISH_SUCCESS:
      return {
        ...state,
        email: '',
        errorType: null,
        loading: false,
        loggedIn: action.data.clearance_level !== 'rejected',
        password: '',
      };
    case loginTypes.LOGIN_FAILURE:
      return {
        ...state,
        errorType: action.origin,
        loading: false,
        loggedIn: false,
      };
    case loginTypes.LOGOUT_SUCCESS:
      return {
        ...initialState,
      };
    case appTypes.CHANGE_PRODUCT:
      return {
        ...state,
        postLoginPage: '/start',
      };
    default:
      return state;
  }
}
