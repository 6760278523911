import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { resetQuote } from '@redux/reducer/quote/actions';
import { push } from 'connected-react-router';

function useResetQuote(): [() => void] {
  const history = useHistory();
  const dispatch = useDispatch();

  const redirectToStartHandler = useCallback(() => {
    dispatch(resetQuote());
    dispatch(push('/start'));
  }, [dispatch]);

  useEffect(() => {
    const unblock = history.block((_, action) => {
      if (action === 'POP') {
        redirectToStartHandler();
      }
    });

    return () => {
      unblock();
    };
  }, [history, redirectToStartHandler]);

  useEffect(() => {
    const handleStateChange = () => {
      window.removeEventListener('beforeunload', handleStateChange);
      redirectToStartHandler();
    };

    window.addEventListener('beforeunload', handleStateChange);

    return () => {
      window.removeEventListener('beforeunload', handleStateChange);
    };
  }, [redirectToStartHandler]);

  return [redirectToStartHandler] as const;
}

export default useResetQuote;
