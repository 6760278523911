import { optimizelyClient } from '@services/clients/optimizely';
import { optimizelyOverrideAttributes } from '@reselectors/optimizely';
import HttpClient from './httpClient';
import store from '../../redux/store';

import { quotes as quoteTransformer } from '../transformers';

const getEnabledFlags = () => {
  const state = store.getState();
  const overrideAttributes = optimizelyOverrideAttributes(state);
  const enabledFlags = optimizelyClient.getEnabledFeatures(
    overrideAttributes.user_identity,
    overrideAttributes,
  );
  const enabledFlagsString = enabledFlags.join(', ');
  return enabledFlagsString;
};

const transformAltProductType = (productType) => {
  switch (productType) {
    case 'tc':
      return 'tempcover';
    case 'newdriver':
      return 'newdriver';
    case 'ldp':
      return 'ldp';
    default:
      return 'csi';
  }
};

const quote = {
  create(data) {
    return new HttpClient.Builder()
      .post('/api/quotes/')
      .data(quoteTransformer.reduxToAPI(data))
      .additionalHeaders({ 'vey-feature-flags': getEnabledFlags() })
      .execute();
  },

  update(uuid, data) {
    return new HttpClient.Builder()
      .put(`/api/quotes/${uuid}/`)
      .data(quoteTransformer.reduxToAPI(data))
      .additionalHeaders({ 'vey-feature-flags': getEnabledFlags() })
      .execute();
  },

  cancel(uuid, data) {
    return new HttpClient.Builder().post(`/api/quotes/${uuid}/cancel/`).data(data).execute();
  },

  getIndicativePrice(uuid, durations, isSubscription) {
    return new HttpClient.Builder()
      .post(`/api/quotes/${uuid}/flexi-indicative-price/`)
      .data({ durations, subscription: isSubscription })
      .execute();
  },

  getCancelRefundAmount(uuid) {
    return new HttpClient.Builder().post(`/api/quotes/${uuid}/cancel-refund-amount/`).execute();
  },

  createPaymentIntent(uuid) {
    return new HttpClient.Builder()
      .post(`/api/quotes/${uuid}/stripe/payment-intent/`)
      .additionalHeaders({ 'vey-feature-flags': getEnabledFlags() })
      .execute();
  },

  createSubscriptionPayment(uuid) {
    return new HttpClient.Builder().post(`/api/quotes/${uuid}/stripe/subscription/`).execute();
  },

  checkQuoteStatus(uuid) {
    return new HttpClient.Builder()
      .get(`/api/quotes/${uuid}/check-quote-status/`)
      .additionalHeaders({ pragma: 'no-cache', 'cache-control': 'no-cache' })
      .execute();
  },

  partialUpdate(uuid, requestData) {
    return new HttpClient.Builder()
      .put(`/api/quotes/${uuid}/partial-update/`)
      .data(requestData)
      .execute();
  },

  createAncillary(uuid) {
    return new HttpClient.Builder().post(`/api/quotes/${uuid}/ancillaries`).execute();
  },

  deleteAncillary(uuid, ancillaryId) {
    return new HttpClient.Builder()
      .delete(`/api/quotes/${uuid}/ancillaries/${ancillaryId}`)
      .execute();
  },

  updateAncillary(uuid, ancillaryId) {
    return new HttpClient.Builder().put(`/api/quotes/${uuid}/ancillaries/${ancillaryId}`).execute();
  },

  makeFinalCheck(uuid, paymentMethod) {
    return new HttpClient.Builder()
      .put(`/api/quotes/${uuid}/make-final-check/`)
      .data({ paymentMethod })
      .additionalHeaders({ 'vey-feature-flags': getEnabledFlags() })
      .execute();
  },

  getAltProduct(uuid, productType) {
    return new HttpClient.Builder()
      .post(`/api/quotes/${uuid}/alt-product-check/`)
      .data({ product: transformAltProductType(productType) })
      .execute();
  },
};

export default quote;
