import { connect } from 'react-redux';
import { replace } from 'connected-react-router';
import { fetchRestrictedPeriodsRequest } from '@reducer/config/actions';
import { resetQuote } from '@reducer/quote/actions';
import { getCurrentRestrictedPeriod } from '@reselectors/restrictedPeriod';
import { saveQuote } from '@redux/reducer/quote/save.actions';
import { setProduct } from '@reducer/quote/product.actions';

import Rejection from './Rejection';

const mapState = (state) => ({
  rejectionReasons: state.quote.save.rejectionReasons,
  restrictedPeriod: getCurrentRestrictedPeriod(state),
  product: state.quote.product.productType,
  isSubscription: state.quote.product.isSubscription,
  alternativeProducts: state.quote.alternativeProducts,
});

const mapDispatch = (dispatch) => ({
  fetchRestrictedPeriods: () => dispatch(fetchRestrictedPeriodsRequest()),
  updateQuoteToNewDriver: () => {
    dispatch(setProduct('newdriver'));
    dispatch(saveQuote());

    // Remove the /rejection page from history to allow customers to go back and edit their newdriver quote
    dispatch(replace('/important-information'));
  },
});

export default connect(mapState, mapDispatch)(Rejection);
