import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import {
  reduxForm,
  formValueSelector,
  getFormInitialValues,
  getFormSyncErrors,
  change,
} from 'redux-form';
import { login } from '@reducer/account/login.actions';
import { setCustomerField } from '@reducer/account/customer.actions';
import {
  setAddressFields,
  setDriverFields,
  setDriverPracticalTestField,
} from '@reducer/quote/driver.actions';
import { postcodeNotFound } from '@reselectors/errors';
import { isDriverPostcodeValid } from '@reselectors/quote';
import { addressToList, getAddressFromLookup, addressActionCreator } from '@services/address';
import objectFieldsHaveChanged from '@services/object';
import { lookupAddress } from '@reducer/lookup/actions';
import { aboutYouPageSubmitted } from '@reducer/pages/actions';

import { RootState } from '@redux/reducer';
import { Dispatch } from 'redux';
import { DriverAddress, DriverFields } from '@reducer/quote/driverTypes';

import { sendEvent } from '@redux/reducer/analytics/actions';

import AboutYou, { AboutYouProps } from './AboutYou';

const initialformSelector = getFormInitialValues('aboutYouForm');
const formSelector = formValueSelector('aboutYouForm');

const haveDetailsChanged = (initialValues: DriverFields, values: DriverFields) => {
  const interestedFields = ['occupation', 'occupationType', 'email', 'phoneNumber'];
  return objectFieldsHaveChanged(interestedFields, { ...initialValues }, { ...values });
};

const mapStateToProps = (state: RootState) => {
  const {
    account: {
      customer,
      login: { loggedIn },
    },
    quote: { car, duration, driver, user, licence, product },
    lookup,
  } = state;

  return {
    address: driver.address && addressToList(driver.address),
    addresses: lookup.addresses,
    driverBirthdate: driver.birthdate,
    hadPostcode: !!customer.postcode,
    initialPostcode: customer.postcode,
    initialValues: initialformSelector(state),
    isLoggedIn: loggedIn,
    isNewCar: car.isNewCar,
    isPostcodeValid: isDriverPostcodeValid(state),
    licenceType: licence.type,
    occupations: state.config.occupations,
    occupationType: formSelector(state, 'occupationType'),
    postcode: driver.address.postcode,
    postcodeNotFound: postcodeNotFound(state),
    prefilled: state.quote.user.prefilledDriver,
    preffilledConnection: user.connection !== '',
    productType: product.productType,
    paymentMethod: product.paymentMethod,
    quoteStart: duration.startDateTime,
    formErrors: getFormSyncErrors('aboutYouForm')(state),
    hasBookedPracticalTest: formSelector(state, 'hasBookedPracticalTest') === 'true',
    email: formSelector(state, 'email'),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  lookupAddress: (postcode: string) => dispatch(lookupAddress(postcode)),
  setAddressFields: (data: DriverAddress) => dispatch(setAddressFields(data)),
  setCustomerField: (field: string, value: boolean) => dispatch(setCustomerField(field, value)),
  goToLogin: () => dispatch(login('about-you')),
  goToEditAccount: () => dispatch(push('/account/edit/personal-details')),
  resetOccupation: () => dispatch(change('aboutYouForm', 'occupation', '')),
  clearEmailField: () => dispatch(change('aboutYouForm', 'email', null)),
  submission: (
    values: DriverFields,
    props: AboutYouProps,
    testBookedEnabled: boolean,
    directOtcSubsToDrivingProfile: boolean,
  ) => {
    const address = getAddressFromLookup(props.addresses, values.addressKey);
    dispatch(setAddressFields(addressActionCreator(address)));

    const driverValues = (({
      firstName,
      lastName,
      title,
      occupationType,
      occupation,
      source,
      phoneNumber,
      email,
      birthdate,
    }) => ({
      firstName,
      lastName,
      title,
      occupationType,
      occupation,
      source,
      phoneNumber,
      email,
      birthdate,
    }))(values);

    dispatch(setDriverFields(driverValues));

    const aboutYouFormData = {
      ...driverValues,
      birthdate: values.birthdate,
      address: addressActionCreator(address),
    };
    dispatch(aboutYouPageSubmitted(aboutYouFormData));

    const hasPostcodeChanged = props.initialPostcode !== props.postcode;
    if (hasPostcodeChanged || haveDetailsChanged(props.initialValues, driverValues)) {
      dispatch(setCustomerField('hasEditedPersonalDetails', true));
    }

    if (testBookedEnabled && props.productType === 'ldp') {
      const { hasBookedPracticalTest, dateOfPracticalTest } = values;

      dispatch(
        setDriverPracticalTestField({
          hasBooked: hasBookedPracticalTest ? hasBookedPracticalTest === 'true' : null,
          date: (hasBookedPracticalTest === 'true' && dateOfPracticalTest) || null,
        }),
      );

      const practicalTestResponse = hasBookedPracticalTest
        ? `Has ${hasBookedPracticalTest === 'false' ? 'not' : ''} booked test${
            hasBookedPracticalTest === 'true'
              ? ` (${dateOfPracticalTest ? 'with' : 'without'} date)`
              : ''
          }`
        : 'No response';

      dispatch(
        sendEvent('PracticalTestResponse', {
          practicalTestResponse,
        }),
      );
    }

    if (props.isLoggedIn) {
      return dispatch(push('driver-details-summary'));
    }

    if (
      props.productType === 'newdriver' ||
      (props.productType === 'tc' &&
        props.paymentMethod === 'subscription' &&
        directOtcSubsToDrivingProfile)
    ) {
      return dispatch(push('driving-profile'));
    }

    return dispatch(push('driving-history'));
  },
});

const AboutYouForm = reduxForm({
  form: 'aboutYouForm',
})(AboutYou);

export default connect(mapStateToProps, mapDispatchToProps)(AboutYouForm);
