import React from 'react';
import { Calendar } from 'react-calendar';
import { Moment } from 'moment-timezone';

import ChevronLeft from './chevron-left';
import ChevronRight from './chevron-right';
import './calendar.less';
import {
  formatMonth,
  formatWeekday,
  createNativeDateFromMoment,
  isOutsideRange,
  createMomentFromNativeDate,
  formatNativeDate,
} from '../../../services/date';

interface ComponentProps {
  startDate: Moment | null;
  endDate: Moment | null;
  toOrFrom: string;
  onChange: (date: Moment) => void;
}

const QuoteCalendar: React.FC<ComponentProps> = ({ startDate, endDate, toOrFrom, onChange }) => {
  const onDatesChange = ({ newStartDate, newEndDate }) => {
    return onChange(toOrFrom === 'from' ? newStartDate : newEndDate);
  };

  return (
    <Calendar
      minDetail='month'
      showNeighboringMonth={false}
      selectRange={false}
      prevLabel={<ChevronLeft />}
      nextLabel={<ChevronRight />}
      nextAriaLabel='Move forward to switch to the next month.'
      prevAriaLabel='Move backward to switch to the previous month.'
      prev2Label={null}
      next2Label={null}
      formatShortWeekday={(_locale, date) => formatWeekday(date)}
      formatLongDate={(_locale, date) => formatNativeDate(date)}
      navigationLabel={({ date }) => formatMonth(date)}
      tileDisabled={({ date }) =>
        isOutsideRange(createMomentFromNativeDate(date), toOrFrom, startDate, endDate)
      }
      value={[createNativeDateFromMoment(startDate), createNativeDateFromMoment(endDate)]}
      onChange={(value, _event) => {
        if (value instanceof Date) {
          value.setHours(0, 0, 0);
        }

        if (toOrFrom === 'from' && value) {
          onDatesChange({ newStartDate: createMomentFromNativeDate(value), newEndDate: endDate });
        }

        if (toOrFrom === 'to' && value) {
          onDatesChange({
            newStartDate: startDate,
            newEndDate: createMomentFromNativeDate(value),
          });
        }
      }}
    />
  );
};

export default QuoteCalendar;
