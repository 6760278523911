/* eslint-disable camelcase */
import ReactGA from 'react-ga';
import { sha256 } from 'js-sha256';

const CAR_SHARING = '/car-sharing';
const LEARNER_DRIVER = '/learner-driver';

const PRODUCT_URL_COMPONENT = {
  csi: CAR_SHARING,
  tc: CAR_SHARING,
  ldp: LEARNER_DRIVER,
};

export const initGA = () => {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_USER_ACCOUNT_ID);
  ReactGA.plugin.require('ecommerce');
};

export const logPageView = (product) => {
  const page = window.location.pathname;
  const product_page = `${PRODUCT_URL_COMPONENT[product]}${page}`;
  ReactGA.set({
    page: product_page,
    product,
  });
  dataLayer.push({
    // GTM
    event: 'pageView',
    page,
    product_url: product_page,
    product,
  });
};

const getSku = (product) => {
  if (product === 'car-sharing') {
    return 'csi1';
  }
  if (product === 'learner-driver') {
    return 'ldp1';
  }
  return 'unknown';
};

export const logEcommerceEvent = (quoteUuid, price, product) => {
  ReactGA.plugin.execute('ecommerce', 'addItem', {
    id: quoteUuid,
    name: product,
    sku: getSku(product),
    price,
    category: product,
    quantity: '1',
  });

  ReactGA.plugin.execute('ecommerce', 'addTransaction', {
    id: quoteUuid,
    revenue: price,
  });

  ReactGA.plugin.execute('ecommerce', 'send');
  ReactGA.plugin.execute('ecommerce', 'clear');
};

export const logConversionEvent = (
  price,
  product,
  driverEmail,
  isFirstPurchase = '',
  numberOfPolicies = '',
) => {
  ReactGA.ga('event', 'conversion', {
    price,
    currency: 'GBP',
    product,
  });

  dataLayer.push({
    // GTM
    event: 'conversion',
    price,
    currency: 'GBP',
    product,
    driverEmail,
    isFirstPurchase,
    numberOfPolicies,
  });
};

const getHashedValue = (value) => {
  return value ? sha256(value.toLowerCase()) : '';
};

const getHashedPhoneNumber = (value) => {
  return value ? sha256(value) : '';
};

export const logPaymentComplete = (quote, screenName) => {
  const {
    driver = {},
    car = {},
    licence = {},
    duration = {},
    indicativePrice = {},
    payment = {},
  } = quote;

  const properties = {
    licenceType: licence.type || '',
    loggedIn: true,
    ecommerce: {
      transaction_id: quote.save?.referenceNumber || '',
      coupon: quote.payment?.discountcode || '',
      shipping: 0,
      currency: 'GBP',
      breakdownAdded: quote.ancillary?.breakdownCover || false,
      items: [
        {
          item_id: quote.product?.product || '',
          item_name: licence.type || '',
          index: 0,
          price: quote.save?.price || '',
          subscription: quote.product?.isSubscription || false,
        },
      ],
    },
    product: {
      duration: duration?.endChoiceSelected?.inHours || null,
      subscription: quote.product?.isSubscription || false,
    },
    quote: {
      quoteId: quote.save?.uuid || '',
      policyNumber: quote.save?.referenceNumber || '',
      excess: quote.importantInformation?.excessSelected || null,
      purchased: quote.save?.purchased || false,
      hours: duration?.hours || null,
      reason: quote.tempcover?.reasonForCover || '',
    },
    car: {
      insuranceGroup: quote.car?.mainInsurer || '',
      make: quote.car?.make || '',
      model: quote.car?.model || '',
      transmission: quote.car?.transmission || '',
      value: quote.car?.value || '',
      year: quote.car?.year || '',
    },
    driver: {
      occupation: quote.driver?.occupation || '',
      occupationType: quote.driver?.occupationType || '',
      email: getHashedValue(quote.driver?.email),
      phoneNumber: getHashedPhoneNumber(quote.driver?.phoneNumber),
    },
    owner: {
      relationship: quote.owner?.relationship || '',
      occupation: quote.owner?.occupation || '',
      occupationType: quote.owner?.occupationType || '',
      email: getHashedValue(quote.owner?.email),
      phoneNumber: getHashedPhoneNumber(quote.owner?.phoneNumber),
    },
  };

  dataLayer.push({
    event: 'logPaymentComplete',
    screenName,
    properties,
  });
};

export const pushReasonForCover = (state, screenName) => {
  const { owner = {}, quote = {}, isLoggedIn = false, hasOwnerDetails = false } = state;
  const {
    driver = {},
    car = {},
    licence = {},
    duration = {},
    indicativePrice = {},
    payment = {},
  } = quote;

  const properties = {
    licenceType: licence.type || '',
    loggedIn: isLoggedIn,
    product: {
      owner: hasOwnerDetails,
      duration: duration.endChoiceSelected?.inHours || null,
      subscription: quote.product?.isSubscription || false,
    },
    quote: {
      quoteId: quote.save?.uuid || '',
    },
    car: {
      insuranceGroup: quote.car?.mainInsurer || '',
      make: quote.car?.make || '',
      model: quote.car?.model || '',
      transmission: quote.car?.transmission || '',
      value: quote.car?.value || '',
      year: quote.car?.year || '',
    },
    driver: {
      occupation: quote.driver?.occupation || '',
      occupationType: quote.driver?.occupationType || '',
    },
    owner: {
      relationship: quote.owner?.relationship || '',
      occupation: quote.owner?.occupation || '',
      occupationType: quote.owner?.occupationType || '',
    },
  };

  dataLayer.push({
    event: 'insuranceReason',
    screenName,
    properties,
  });
};

export const logNewDriverTelematicsToggledEvent = (newValue) => {
  dataLayer.push({
    event: 'newDriverTelematicsToggled',
    toggled: newValue,
  });
};

export const logNewDriverTelematicsModalOpenedEvent = () => {
  dataLayer.push({
    event: 'newDriverTelematicsFindOutMoreModalOpened',
  });
};

export const logNewDriverTelematicsMessagingEvent = (bannerText) => {
  dataLayer.push({
    event: 'newDriverTelematicsMessagingEvent',
    bannerText,
  });
};

export const logVariableExcessEvent = (excessValue, productType) => {
  const product = productType === 'tc' ? 'TEMPCOVER' : productType.toUpperCase();
  ReactGA.event({
    category: `Variable Excess`,
    action: `Click - ${product}`,
    label: `${excessValue}`,
  });
};

export const logNoMainInsuranceEvent = () => {
  ReactGA.event({
    category: 'No main insurance',
    action: 'Click - No main insurance LDP owner driver',
    label: 'No Main Insurance Owner LDP',
  });
};
