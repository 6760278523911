import { createSelector } from 'reselect';

import { RootState } from '@redux/reducer';
import convertProductType from '@services/convertProductType';

import { OptimizelyAttributes } from '@redux/reducer/config/types';

import { PaymentMethod } from '@utils/types';

export interface OptimizelyOverrideAttributes extends OptimizelyAttributes {
  product: string;
  paymentMethod: PaymentMethod | null;
  isSubscription: boolean;
}

export const getOptimizelyUserIdentity = (state: RootState): string =>
  state.config.optimizelyAttributes.user_identity;

const getOptimizelyAttributes = (state: RootState) => state.config.optimizelyAttributes;
const getProduct = (state: RootState) => state.quote.product;
const getIsDriverOwner = (state: RootState) => state.quote.driver.isDriverOwner;

export const optimizelyOverrideAttributes = createSelector(
  getOptimizelyAttributes,
  getProduct,
  getIsDriverOwner,
  (optimizelyAttributes, product, isDriverOwner): OptimizelyOverrideAttributes => ({
    ...optimizelyAttributes,
    product: convertProductType(product.productType, isDriverOwner),
    paymentMethod: product.paymentMethod,
    isSubscription: product.isSubscription,
  }),
);
