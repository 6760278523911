export default function getTempcoverReasonText(reason) {
  switch (reason) {
    case 'tc_buying':
      return 'I am buying a car';
    case 'tc_selling':
      return 'I am selling my car';
    case 'tc_personal':
      return 'Personal / social use';
    case 'tc_borrowing':
      return 'Borrowing own car';
    case 'tc_shopforannual':
      return 'Shopping for annual insurance';
    case 'tc_impound':
      return 'Releasing from impound';
    case 'tc_other':
      return 'Other';
    default:
      return 'Select a reason';
  }
}
