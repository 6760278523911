import { types, LookupActions } from './actions';
import { types as loginTypes, LoginActiontypes } from '../account/login.actions';
import { LookupState } from './lookupTypes';

import deepUpdate from '../../../services/deepUpdate';

// Reducer
export const initialState: LookupState = {
  addresses: [],
  billingAddresses: [],
  billingAddressPostcode: '',
  car: null,
  error: null,
  indicativePriceMissing: false,
  loading: false,
  instructors: [],
  errors: {
    errorCode: null,
    carReg: null,
  },
  registrationNumber: null,
};

export type LookActionTypes = LookupActions | LoginActiontypes;

export default function lookup(
  state: LookupState = initialState,
  action = {} as LookActionTypes,
): LookupState {
  switch (action.type) {
    case types.LOOKUP_ADDRESS_FETCH:
      return {
        ...state,
        loading: true,
      };
    case types.LOOKUP_ADDRESS_SUCCESS:
      return {
        ...state,
        addresses: action.addressData,
        loading: false,
        error: null,
      };
    case types.LOOKUP_ADDRESS_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case types.LOOKUP_BILLING_ADDRESS_FETCH:
      return {
        ...state,
        loading: true,
      };
    case types.LOOKUP_BILLING_ADDRESS_SUCCESS:
      return {
        ...state,
        billingAddresses: action.addressData,
        loading: false,
        error: null,
      };
    case types.LOOKUP_BILLING_ADDRESS_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case types.LOOKUP_CAR_FETCH:
      return {
        ...state,
        loading: true,
        // isCarDetails: action.isCarDetails,
        registrationNumber: action.registrationNumber,
        indicativePriceMissing: false,
        errors: {
          ...state.errors,
          errorCode: null,
          carReg: null,
        },
      };
    case types.LOOKUP_CAR_SUCCESS:
      return {
        ...state,
        car: action.carData,
        loading: false,
        error: null,
        errors: {
          ...state.errors,
          errorCode: null,
          carReg: null,
        },
      };
    case types.LOOKUP_CAR_FAILURE:
      return {
        ...state,
        car: null,
        loading: false,
        errors: {
          ...state.errors,
          errorCode: action.code,
          carReg: action.error,
        },
      };
    case types.LOOKUP_CAR_ERROR_RESET:
      return {
        ...state,
        errors: {
          ...state.errors,
          errorCode: null,
        },
      };
    case types.SET_LOOKUP_FIELD:
      return deepUpdate(state, action.field, action.value);
    case loginTypes.LOGOUT_SUCCESS:
    case types.LOOKUP_CAR_RESET:
    case types.RESET_QUOTE:
      return {
        ...state,
        car: null,
        registrationNumber: null,
      };
    default:
      return {
        ...state,
        error: null,
      };
  }
}
