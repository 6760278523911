import Debug from 'debug';
import React, { useEffect } from 'react';
import { InjectedFormProps } from 'redux-form';
import QuoteStep from '@templates/QuoteStep';

import UpgradeLicenceBackground from '@assets/prompt-box-background-uml.svg';
import PlateIcon from '@assets/p-plate-v2.svg';
import { PromptBox } from '@rentecarlo/component-library';

import { useAppDispatch, useAppSelector, useOptimizelyFlag, useOptimizelyTrack } from '@hooks';
import { goToUpgrade } from '@reducer/quote/licence.actions';
import { setUserField } from '@reducer/quote/user.actions';

import PostcodeAndDoB from './PostcodeAndDob';
import AboutCar from './AboutCar';
import { ComponentProps } from './QuoteStart.container';

const debug = Debug('veygo:pages:quoteStart');

const getTextAttributes = (hideSubsIndicativePrice: boolean, indicativePriceRemoved: boolean) => {
  if (indicativePriceRemoved) {
    return {
      titleText: 'Tell us about you',
      subTitleText:
        'Please provide a few essential details so we can tailor our services just for you.',
    };
  }
  if (hideSubsIndicativePrice) {
    return {
      titleText: '',
      subTitleText: '',
    };
  }
  return {
    titleText: 'Price incoming...',
    subTitleText:
      "Just complete the below and we'll have your guide price waiting on the next page.",
  };
};

const QuoteStart: React.FC<ComponentProps & InjectedFormProps> = ({
  licenceType,
  productType,
  setDriverOwner,
  setProduct,
  submission,
  submitAddressAndDob,
  // formErrors isn't injected by redux-form, but it's a part of it
  formErrors,
  // Redux-Form injected props
  formValues,
  handleSubmit,
  initialize,
  submitFailed,
  valid,
}) => {
  // Selectors and Dispatch
  const dispatch = useAppDispatch();

  const birthdate = useAppSelector((state) => state.quote.driver.birthdate);
  const car = useAppSelector((state) => state.lookup.car);
  const haveCar = useAppSelector((state) => Boolean(state.lookup.car?.make));
  const isLoggedIn = useAppSelector((state) => state.account.login.loggedIn);
  const postcode = useAppSelector((state) => state.account.customer.postcode);

  const hideSubsIndicativePrice = useOptimizelyFlag('TEMP_HIDE_INDICATIVE_SUBS').enabled;
  const indicativePriceRemoved = useOptimizelyFlag('TEMP_INDICATIVE_PRICE_REMOVED').enabled;

  const { titleText, subTitleText } = getTextAttributes(
    hideSubsIndicativePrice,
    indicativePriceRemoved,
  );

  const sendEvent = useOptimizelyTrack();

  useEffect(() => {
    if (isLoggedIn) {
      const values = Object.assign(formValues, { postcode, birthdate });
      debug('initializing form with values', values);
      initialize(values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, postcode, birthdate]);

  return (
    <>
      {isLoggedIn &&
        (productType === 'ldp' || (productType === 'newdriver' && licenceType === 'uk_prov')) && (
          <PromptBox
            id='upgradeLicencePromptbox'
            title='Passed your test?'
            text='If you’re no longer learning, congrats! 🎉 You need to update your licence.'
            buttonText='Change to full licence'
            icon={PlateIcon}
            marginTop='48px'
            marginBottom='0px'
            textFontSize='16px'
            background={UpgradeLicenceBackground}
            onClick={() => dispatch(goToUpgrade())}
          />
        )}
      <QuoteStep
        displaySummaryFooter={false}
        form
        formErrors={formErrors}
        nextAction={handleSubmit((values: Record<string, unknown>) => {
          const valuesWithCar = {
            ...values,
            car,
          };
          sendEvent('save_product_selected');
          submission(valuesWithCar, productType);
        })}
        nextLabel='Next'
        nextDisabled={!haveCar}
        stepTitle={titleText}
        stepSubtitle={subTitleText}
        submitError={submitFailed && !valid}
        showButton={haveCar}
      >
        <form
          onSubmit={handleSubmit((values: Record<string, unknown>) => {
            submission(values, productType);
          })}
        />
        <PostcodeAndDoB />
        <AboutCar
          toggleStatus={(status: boolean) => dispatch(setUserField('status', status))}
          setProduct={setProduct}
          setDriverOwner={setDriverOwner}
          submitFailed={submitFailed}
          onModalOpen={handleSubmit((values: Record<string, unknown>) => {
            submitAddressAndDob(values, licenceType);
          })}
          licenceType={licenceType}
        />
      </QuoteStep>
    </>
  );
};

export default QuoteStart;
