export default [
  {
    text: '1',
    value: '1',
  },
  {
    text: '2',
    value: '2',
  },
  {
    text: '3',
    value: '3',
  },
  {
    text: '4+',
    value: '4+',
  },
];
