import moment from 'moment';
import calculateSubscriptionEndDate from '@services/subscription';
/*
 * Provides options for the indicative price quick selector.
 * WARNING: When adding options ensure the backend has been updates
 * to return this hours duration. (pricing_transformer.py)
 */
const ONE_DAY_IN_HOURS = 24;
const ONE_WEEK_IN_HOURS = ONE_DAY_IN_HOURS * 7;
const ONE_MONTH_IN_HOURS = ONE_DAY_IN_HOURS * 30;

export interface DurationOptions {
  text: string;
  value: string;
  inHours: number;
  sub: string;
  validate: boolean;
}
export interface BaseDurationConfig {
  default: {
    value: string;
    tab: string;
    inHours: number;
  };
  hours: {
    text: 'HOURS';
    value: 'hours';
    locked: boolean;
    options: DurationOptions[];
  };
  days: {
    text: 'DAYS';
    value: 'days';
    locked: boolean;
    options: DurationOptions[];
  };
  weeks: {
    text: 'WEEKS';
    value: 'weeks';
    locked: boolean;
    options: DurationOptions[];
  };
}

export interface DurationConfigWithMonths extends BaseDurationConfig {
  months: {
    text: 'MONTHS';
    value: 'months';
    locked: boolean;
    options: DurationOptions[];
  };
}

export interface DurationConfigSubs {
  default: {
    value: string;
    tab: string;
    inHours: number;
  };
  months: {
    text: 'MONTHS';
    value: 'months';
    locked: boolean;
    options: DurationOptions[];
  };
}

export type Durations = BaseDurationConfig | DurationConfigWithMonths;

const csiConfig: BaseDurationConfig = {
  default: {
    value: '30-days',
    tab: 'weeks',
    inHours: ONE_MONTH_IN_HOURS,
  },
  hours: {
    text: 'HOURS',
    value: 'hours',
    locked: false,
    options: [
      {
        text: '1',
        value: '1-hour',
        inHours: 1,
        sub: 'HOUR',
        validate: moment().isBetween(
          moment({ h: 5, m: 0, s: 0 }),
          moment({ h: 20, m: 0, s: 0 }),
          undefined,
          '[]',
        ),
      },
      {
        text: '2',
        value: '2-hour',
        inHours: 2,
        sub: 'HOURS',
        validate: moment().isBetween(
          moment({ h: 14, m: 0, s: 0 }),
          moment({ h: 20, m: 0, s: 0 }),
          undefined,
          '(]',
        ),
      },
      {
        text: '4',
        value: '4-hour',
        inHours: 4,
        sub: 'HOURS',
        validate: moment().isBetween(
          moment({ h: 5, m: 0, s: 0 }),
          moment({ h: 18, m: 0, s: 0 }),
          undefined,
          '[)',
        ),
      },
      {
        text: '6',
        value: '6-hour',
        inHours: 6,
        sub: 'HOURS',
        validate: moment().isBetween(
          moment({ h: 5, m: 0, s: 0 }),
          moment({ h: 16, m: 0, s: 0 }),
          undefined,
          '[)',
        ),
      },
      {
        text: '8',
        value: '8-hour',
        inHours: 8,
        sub: 'HOURS',
        validate: moment().isBetween(
          moment({ h: 5, m: 0, s: 0 }),
          moment({ h: 14, m: 0, s: 0 }),
          undefined,
          '[)',
        ),
      },
      {
        text: '12',
        value: '12-hour',
        inHours: 12,
        sub: 'HOURS',
        validate:
          moment().isBetween(
            moment({ h: 5, m: 0, s: 0 }),
            moment({ h: 10, m: 0, s: 0 }),
            undefined,
            '[)',
          ) ||
          moment().isBetween(
            moment({ h: 17, m: 0, s: 0 }),
            moment({ h: 20, m: 0, s: 0 }),
            undefined,
            '[)',
          ),
      },
      {
        text: '24',
        value: '24-hour',
        inHours: 24,
        sub: 'HOURS',
        validate: moment().isBetween(
          moment({ h: 10, m: 0, s: 0 }),
          moment({ h: 20, m: 0, s: 0 }),
          undefined,
          '[)',
        ),
      },
    ],
  },
  days: {
    text: 'DAYS',
    value: 'days',
    locked: false,
    options: [
      {
        text: '1',
        value: '1-day',
        inHours: 24,
        sub: 'DAY',
        validate: true,
      },
      {
        text: '1.5',
        value: '1.5-day',
        inHours: 36,
        sub: 'DAYS',
        validate: true,
      },
      {
        text: '2',
        value: '2-day',
        inHours: 48,
        sub: 'DAYS',
        validate: true,
      },
      {
        text: '3',
        value: '3-day',
        inHours: 72,
        sub: 'DAYS',
        validate: true,
      },
      {
        text: '5',
        value: '5-day',
        inHours: 120,
        sub: 'DAYS',
        validate: true,
      },
    ],
  },
  weeks: {
    text: 'WEEKS',
    value: 'weeks',
    locked: false,
    options: [
      {
        text: '1',
        value: '1-week',
        inHours: 168,
        sub: 'WEEK',
        validate: true,
      },
      {
        text: '2',
        value: '2-week',
        inHours: 336,
        sub: 'WEEKS',
        validate: true,
      },
      {
        text: '3',
        value: '3-week',
        inHours: 504,
        sub: 'WEEKS',
        validate: true,
      },
      {
        text: '30',
        value: '30-days',
        inHours: 720,
        sub: 'DAYS',
        validate: true,
      },
      {
        text: '60',
        value: '60-days',
        inHours: 1440,
        sub: 'DAYS',
        validate: true,
      },
    ],
  },
};

const ldpConfig: DurationConfigWithMonths = {
  default: {
    value: '2-week',
    tab: 'weeks',
    inHours: ONE_WEEK_IN_HOURS * 2,
  },
  hours: {
    text: 'HOURS',
    value: 'hours',
    locked: false,
    options: [
      {
        text: '1',
        value: '1-hour',
        inHours: 1,
        sub: 'HOUR',
        validate: moment().isBetween(
          moment({ h: 5, m: 0, s: 0 }),
          moment({ h: 20, m: 0, s: 0 }),
          undefined,
          '[]',
        ),
      },
      {
        text: '2',
        value: '2-hour',
        inHours: 2,
        sub: 'HOURS',
        validate: moment().isBetween(
          moment({ h: 14, m: 0, s: 0 }),
          moment({ h: 20, m: 0, s: 0 }),
          undefined,
          '(]',
        ),
      },
      {
        text: '4',
        value: '4-hour',
        inHours: 4,
        sub: 'HOURS',
        validate: moment().isBetween(
          moment({ h: 5, m: 0, s: 0 }),
          moment({ h: 18, m: 0, s: 0 }),
          undefined,
          '[)',
        ),
      },
      {
        text: '6',
        value: '6-hour',
        inHours: 6,
        sub: 'HOURS',
        validate: moment().isBetween(
          moment({ h: 5, m: 0, s: 0 }),
          moment({ h: 16, m: 0, s: 0 }),
          undefined,
          '[)',
        ),
      },
      {
        text: '8',
        value: '8-hour',
        inHours: 8,
        sub: 'HOURS',
        validate: moment().isBetween(
          moment({ h: 5, m: 0, s: 0 }),
          moment({ h: 14, m: 0, s: 0 }),
          undefined,
          '[)',
        ),
      },
      {
        text: '12',
        value: '12-hour',
        inHours: 12,
        sub: 'HOURS',
        validate:
          moment().isBetween(
            moment({ h: 5, m: 0, s: 0 }),
            moment({ h: 10, m: 0, s: 0 }),
            undefined,
            '[)',
          ) ||
          moment().isBetween(
            moment({ h: 17, m: 0, s: 0 }),
            moment({ h: 20, m: 0, s: 0 }),
            undefined,
            '[)',
          ),
      },
      {
        text: '24',
        value: '24-hour',
        inHours: 24,
        sub: 'HOURS',
        validate: moment().isBetween(
          moment({ h: 10, m: 0, s: 0 }),
          moment({ h: 20, m: 0, s: 0 }),
          undefined,
          '[)',
        ),
      },
    ],
  },
  days: {
    text: 'DAYS',
    value: 'days',
    locked: false,
    options: [
      {
        text: '1',
        value: '1-day',
        inHours: 24,
        sub: 'DAY',
        validate: true,
      },
      {
        text: '2',
        value: '2-day',
        inHours: 48,
        sub: 'DAYS',
        validate: true,
      },
      {
        text: '3',
        value: '3-day',
        inHours: 72,
        sub: 'DAYS',
        validate: true,
      },
      {
        text: '5',
        value: '5-day',
        inHours: 120,
        sub: 'DAYS',
        validate: true,
      },
      {
        text: '7',
        value: '7-day',
        inHours: 168,
        sub: 'DAYS',
        validate: true,
      },
    ],
  },
  weeks: {
    text: 'WEEKS',
    value: 'weeks',
    locked: false,
    options: [
      {
        text: '2',
        value: '2-week',
        inHours: ONE_WEEK_IN_HOURS * 2,
        sub: 'WEEKS',
        validate: true,
      },
      {
        text: '4',
        value: '4-week',
        inHours: ONE_WEEK_IN_HOURS * 4,
        sub: 'WEEKS',
        validate: true,
      },
      {
        text: '6',
        value: '6-week',
        inHours: ONE_WEEK_IN_HOURS * 6,
        sub: 'WEEKS',
        validate: true,
      },
      {
        text: '8',
        value: '8-week',
        inHours: ONE_WEEK_IN_HOURS * 8,
        sub: 'WEEKS',
        validate: true,
      },
      {
        text: '12',
        value: '12-week',
        inHours: ONE_WEEK_IN_HOURS * 12,
        sub: 'WEEKS',
        validate: true,
      },
    ],
  },
  months: {
    text: 'MONTHS',
    value: 'months',
    locked: false,
    options: [
      {
        text: '1',
        value: '1-month',
        inHours: ONE_MONTH_IN_HOURS,
        sub: 'MONTH',
        validate: true,
      },
      {
        text: '2',
        value: '2-month',
        inHours: ONE_MONTH_IN_HOURS * 2,
        sub: 'MONTHS',
        validate: true,
      },
      {
        text: '3',
        value: '3-month',
        inHours: ONE_MONTH_IN_HOURS * 3,
        sub: 'MONTHS',
        validate: true,
      },
      {
        text: '4',
        value: '4-month',
        inHours: ONE_MONTH_IN_HOURS * 4,
        sub: 'MONTHS',
        validate: true,
      },
      {
        text: '6',
        value: '6-month',
        inHours: ONE_MONTH_IN_HOURS * 6,
        sub: 'MONTHS',
        validate: true,
      },
    ],
  },
};

const subsConfig = (): DurationConfigSubs => {
  const duration = Math.floor(
    moment.duration(calculateSubscriptionEndDate().diff(moment())).asHours(),
  );
  return {
    default: {
      value: '1-month',
      tab: 'months',
      inHours: duration,
    },
    months: {
      text: 'MONTHS',
      value: 'months',
      locked: false,
      options: [
        {
          text: '1',
          value: '1-month',
          inHours: duration,
          sub: 'MONTH',
          validate: true,
        },
      ],
    },
  };
};

export default {
  csi: csiConfig,
  ldp: ldpConfig,
  getSubs: subsConfig,
};

export const durationLabelMap = {
  '1-day': '1 day',
  '1.5-day': '1.5 days',
  '2-day': '2 days',
  '3-day': '3 days',
  '5-day': '5 days',
  '7-day': '7 days',
  '30-days': '30 days',
  '60-days': '60 days',
  '1-hour': '1 hour',
  '2-hour': '2 hours',
  '4-hour': '4 hours',
  '6-hour': '6 hours',
  '8-hour': '8 hours',
  '12-hour': '12 hours',
  '24-hour': '24 hours',
  '1-week': '1 week',
  '2-week': '2 weeks',
  '3-week': '3 weeks',
  '4-week': '4 weeks',
  '6-week': '6 weeks',
  '8-week': '8 weeks',
  '12-week': '12 weeks',
  '1-month': '1 month',
  '2-month': '2 months',
  '3-month': '3 months',
  '4-month': '4 months',
  '6-month': '6 months',
};
