/* eslint-disable camelcase */
import moment, { Moment } from 'moment-timezone';
import { connect } from 'react-redux';
import { RootState } from '@redux/reducer';
import { Dispatch } from 'redux';
import { saveAndGoTo } from '@reducer/quote/save.actions';
import { getCurrentRestrictedPeriod } from '@reselectors/restrictedPeriod';
import {
  isStartImmediate,
  setDurationStart,
  setDurationEnd,
} from '@reducer/quote/duration.actions';
import {
  isCoverEndTimeAfterCoverStartTime,
  isCoverLongerThanMinimumDuration,
  isCoverShorterThanMaximumDuration,
  isCoverStartTimeInFuture,
  isCoverStartBefore60Days,
  isCoverDurationTimeValid,
  isCoverDurationInRestrictedPeriod,
} from '@services/cover';
import { isBetween22and5 } from '@services/date';
import indicativeDurations from '@config/indicativeDurations';
import { optimizelyClient } from '@services/clients/optimizely';
import { QuoteData } from '@redux/reducer/quote/saveTypes';
import DurationOfCover from './DurationOfCover';

export interface CurrentRestrictedPeriod {
  end_date: string;
  min_duration: number;
}

interface StateProps {
  immediateStart: boolean | null;
  startDateTime: Moment | null;
  endDateTime: Moment | null;
  productType: string;
  isSubscription: boolean;
  isCoverStartTimeInFuture: boolean;
  isCoverDurationTimeValid: boolean;
  isCoverStartBefore60Days: boolean;
  isCoverEndTimeAfterCoverStartTime: boolean;
  isCoverLongerThanMinimumDuration: boolean;
  isCoverShorterThanMaximumDuration: boolean;
  isCoverDurationInRestrictedPeriod: boolean;
  currentRestrictedPeriod: CurrentRestrictedPeriod[];
  loggedIn: boolean;
  isStartDateTimeBetween22and5: boolean | null;
  userIdentity: string;
  driverDOB: Moment | null;
}

const nextPage = (
  quote: QuoteData,
  userIdentity: string,
  altProduct: { passedUW: boolean; price: number | null },
  productType: string,
) => {
  const indicativePriceRemoved = optimizelyClient.isFeatureEnabled(
    'TEMP_INDICATIVE_PRICE_REMOVED',
    userIdentity,
  );

  if (indicativePriceRemoved) {
    if (!quote.underwriting_criteria || quote.price === null) {
      return 'rejection';
    }
    if (
      altProduct &&
      altProduct.price &&
      (productType === 'newdriver' || altProduct.price <= Number(quote.price))
    ) {
      return 'product-choice';
    }

    return 'important-information';
  }

  return 'car';
};

const mapStateToProps = (state: RootState): StateProps => {
  const {
    quote: {
      duration: { immediateStart, startDateTime, endDateTime },
      product: { productType, isSubscription },
      driver: { birthdate },
    },
    config: {
      optimizelyAttributes: { user_identity: userIdentity },
    },
  } = state;

  return {
    immediateStart,
    startDateTime: startDateTime ? startDateTime.clone() : null,
    endDateTime: endDateTime ? endDateTime.clone() : null,
    productType,
    isSubscription,
    isCoverStartTimeInFuture: isCoverStartTimeInFuture(startDateTime, immediateStart),
    isCoverDurationTimeValid: isCoverDurationTimeValid(startDateTime, endDateTime),
    isCoverStartBefore60Days: isCoverStartBefore60Days(startDateTime),
    isCoverEndTimeAfterCoverStartTime: isCoverEndTimeAfterCoverStartTime(
      startDateTime,
      endDateTime,
    ),
    isCoverLongerThanMinimumDuration: isCoverLongerThanMinimumDuration(startDateTime, endDateTime),
    isCoverShorterThanMaximumDuration: isCoverShorterThanMaximumDuration(
      startDateTime,
      endDateTime,
      productType,
    ),
    isCoverDurationInRestrictedPeriod: isCoverDurationInRestrictedPeriod(
      startDateTime,
      endDateTime,
      getCurrentRestrictedPeriod(state),
    ),
    currentRestrictedPeriod: getCurrentRestrictedPeriod(state),
    loggedIn: state.account.login.loggedIn,
    isStartDateTimeBetween22and5: isBetween22and5(startDateTime),
    userIdentity,
    driverDOB: birthdate,
  };
};

interface DispatchProps {
  setImmediateStart: (immediateStart: boolean, isSubscription: boolean) => void;
  setStartDateTime: (date: Moment, isSubscription: boolean) => void;
  setEndDateTime: (date: Moment) => void;
  nextAction: (action: string | boolean) => void;
  resetEndDate: () => void;
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setImmediateStart: (immediateStart: boolean, isSubscription: boolean) => {
    dispatch(isStartImmediate(immediateStart));
    if (immediateStart && isSubscription)
      dispatch(
        setDurationEnd(
          moment.tz('Europe/London').add(indicativeDurations.getSubs().default.inHours, 'hours'),
        ),
      );
  },
  setStartDateTime: (date: Moment, isSubscription = false) => {
    dispatch(setDurationStart(moment.tz(date, 'Europe/London')));
    if (isSubscription)
      dispatch(
        setDurationEnd(
          moment
            .tz(date, 'Europe/London')
            .add(indicativeDurations.getSubs().default.inHours, 'hours'),
        ),
      );
  },
  setEndDateTime: (date: Moment) => {
    dispatch(setDurationEnd(moment.tz(date, 'Europe/London')));
  },
  nextAction: (userIdentity: string) => {
    dispatch(
      saveAndGoTo(
        (
          data: QuoteData,
          altProduct: { passedUW: boolean; price: number | null },
          productType: string,
        ) => nextPage(data, userIdentity, altProduct, productType),
      ),
    );
  },
  resetEndDate: () => dispatch(setDurationEnd(null)),
});

const mergeProps = (stateProps: StateProps, dispatchProps: DispatchProps) => {
  const { userIdentity, ...otherStateProps } = stateProps;
  const { nextAction, ...otherDispatchProps } = dispatchProps;
  return {
    ...otherStateProps,
    ...otherDispatchProps,
    nextAction: () => nextAction(userIdentity),
  };
};

const DurationOfCoverContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(DurationOfCover);

export default DurationOfCoverContainer;
