import * as React from 'react';
import styled from 'styled-components';
import colors from '@config/colors';

import lockImage from '@assets/lock.svg';

interface Props {
  onClick?: () => void;
  children?: React.ReactNode;
  isLocked?: boolean;
  disabled?: boolean;
  id?: string;
  width?: string;
  padding?: string;
  fontSize?: string;
  icon?: string;
  textColor?: string;
  background?: string;
}

const Button = styled.button`
  height: 30px;
  border-radius: 17px;
  background-color: ${(props: Props): string => props.background || '#F3F4F7'};
  border-width: 0;
  padding: ${(props: Props): string | undefined => props.padding};
  &:focus {
    outline: 0;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: ${(props: Props): string => (props.disabled ? 'no-drop' : 'pointer')};
  opacity: ${(props: Props): string => (props.disabled ? '0.5' : '1.0')};
  width: ${(props: Props): string | undefined => props.width};
}
`;

const PillButtonText = styled.span`
  color: ${(props: Props): string =>
    props.isLocked ? colors.grey.text.light : props.textColor || '#0B7BFB'};
  font-family: 'proxima-soft';
  font-size: ${(props: Props): string => props.fontSize || '16px'};
  font-weight: bold;
  line-height: 20px;
  text-align: center;
  opacity: ${(props: Props): string => (props.disabled ? '0.5' : '1.0')};
  padding-bottom: 1px; /* Pads the text align out the factor in the border */
`;

const Image = styled.img`
  padding-right: 8px;
`;

const PillButton = (props: Props): React.ReactElement => {
  const {
    onClick,
    isLocked,
    disabled,
    id,
    width,
    padding,
    children,
    fontSize,
    icon,
    textColor,
    background,
  } = props;
  return (
    <Button
      id={id}
      width={width}
      padding={padding}
      disabled={isLocked || disabled}
      onClick={onClick}
      background={background}
    >
      {icon && <img src={icon} alt='' style={{ height: 16, width: 16, marginRight: 6 }} />}
      {isLocked && <Image src={lockImage} />}
      <PillButtonText
        isLocked={isLocked}
        disabled={disabled}
        fontSize={fontSize}
        textColor={textColor}
      >
        {children}
      </PillButtonText>
    </Button>
  );
};

PillButton.defaultProps = {
  isLocked: false,
  disabled: false,
  padding: '2px 18px',
};

export default PillButton;
