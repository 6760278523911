import React, { FC } from 'react';
import styled from 'styled-components';
import { InformationBox, RadioButton } from '@rentecarlo/component-library';

const ToggleButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 -4px 0 -4px;
`;

const ButtonSpacer = styled.div`
  height: 16px;
`;

export const errors = {
  ERROR_RIGHT:
    "You must be the registered keeper on the car's V5C document to purchase New Driver insurance",
};

type Props = {
  value: string;
  onSelect: (value: string) => void;
  groupName: string;
  error?: string;
  warn?: string;
  disabled?: boolean;
};

const ToggleButtons: FC<Props> = ({
  value,
  onSelect,
  groupName,
  error,
  warn,
  disabled = false,
}) => (
  <div>
    <ToggleButtonContainer>
      <RadioButton
        id={`${groupName}-yes`}
        checked={value === 'owner'}
        onSelect={(selectedValue: string): void => onSelect(selectedValue)}
        text="I'm the legal owner"
        value='owner'
        disabled={value !== 'owner' && disabled}
        capitaliseButtonText={false}
      />
      <ButtonSpacer />
      <RadioButton
        id={`${groupName}-no`}
        checked={value === 'driver'}
        onSelect={(selectedValue: string): void => onSelect(selectedValue)}
        text="I'm borrowing someone else's car"
        value='driver'
        displayError={error === errors.ERROR_RIGHT}
        disabled={value !== 'driver' && disabled}
        capitaliseButtonText={false}
      />
    </ToggleButtonContainer>
    {warn !== undefined && (
      <InformationBox id={`${groupName}-text-warning`} type='important'>
        {warn}
      </InformationBox>
    )}
  </div>
);

export default ToggleButtons;
