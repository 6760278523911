/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Field, WrappedFieldInputProps, InjectedFormProps, DecoratedFormProps } from 'redux-form';
import styled from 'styled-components';
import { InformationBox, LinkButton } from '@rentecarlo/component-library';
import InfoTag from '@atoms/paragraphs/InfoTag';
import { Moment } from 'moment-timezone';

import { Dispatch } from 'redux';

import titles from '@config/titles';
import occupationTypes from '@config/occupationTypes';
import ReduxFieldValidation from '@services/formValidation';

import Group from '@atoms/layout/form/Group';
import SectionTitle from '@atoms/titles/componentTitles/SectionTitle';
import SectionSubTitle from '@atoms/titles/componentTitles/SectionSubTitle';
import DateInputField from '@molecules/DateInputField';
import AddressSearch from '@molecules/AddressSearch';

import QuoteStep from '@templates/QuoteStep';

import MultipleChoiceField from '@molecules/inputs/MultipleChoiceField';
import TextInput from '@atoms/inputs/TextInputField';
import OccupationSelectField from '@molecules/selects/OccupationSelectField';
import Selector from '@organisms/Selector/Selector';
import QuoteCalendar from '@molecules/calendars/QuoteCalendar';

import { OccupationData } from '@reducer/config/types';
import { Address } from '@reducer/account/addressTypes';
import { DriverFields } from '@reducer/quote/driverTypes';

import accountApi from '@services/api/customer';

import ToggleButtons from '@molecules/inputs/ToggleButtonsField';

import EmailAlreadyExistsModal from '@organisms/modals/EmailAlreadyExistsModal';

import { useOptimizelyFlag } from '@hooks';
import { PaymentMethod } from '@utils/types';

const InfoTagContainer = styled.div`
  margin: -7px 16px 0 16px;
`;

const getSubtitle = (isLoggedIn: boolean, goToLogin: () => void) => {
  if (isLoggedIn) return 'Tell us about yourself.';
  return (
    <span id='aboutYou-text-driverTitle'>
      Please provide the following information for the person who will drive the vehicle on this
      policy. Purchased before?{' '}
      <LinkButton id='aboutYou-button-goToLogin' action={goToLogin} large>
        Log in to save time
      </LinkButton>
    </span>
  );
};

const renderTitles = (title: string, productType: string) => {
  const tempcover = productType === 'tc';
  const titlesConfig: { [key: string]: string } = {
    driversName: tempcover ? 'What is your full name?' : "What is the driver's full name?",
    dob: tempcover ? 'What is your date of birth?' : "What is the driver's date of birth?",
    address: tempcover ? 'What is your address?' : "What is the driver's address?",
    occupation: tempcover ? 'What is your occupation?' : "What is the driver's occupation?",
    contactNumber: tempcover ? 'What is your phone number?' : "What is the driver's phone number?",
    emailAddress: tempcover ? 'What is your email address?' : "What is the driver's email address?",
    emailAddressInfo: tempcover
      ? "This email address is really important - it's where we'll send your policy documents and also the magic link to recover your account if you forget your password. We'll send marketing updates here too. You can change your preferences at any time in your account or by clicking the unsubscribe link in marketing emails."
      : "This email address is really important - it's where we'll send your policy documents and also the magic link to recover your account if the password is forgotten. We'll send marketing updates here too. You can change your preferences at any time in your account or by clicking the unsubscribe link in marketing emails.",
    driversNameInfo: tempcover
      ? "Please complete the below with the details as they appear on your driver's licence."
      : "Please complete the below with the details as they appear on the temporary driver's licence.",
  };

  return titlesConfig[title];
};

interface ComponentProps {
  input: WrappedFieldInputProps;
  name: string;
  id: string;
}

const ToggleButtonField: React.FC<ComponentProps> = ({ input, name, id }) => (
  <ToggleButtons
    id={id}
    value={input.value}
    onSelect={input.onChange}
    leftLabel='YES'
    rightLabel='NO'
    error={undefined}
    errorMessage=''
    groupName={name}
    displayLeftError={false}
    hasValidationError={false}
    validationErrorMessage={undefined}
    page='aboutYou'
  />
);

const DateContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  background: white;
  border-radius: 8px;
  margin: 16px 0 60px;
  padding: 16px 0;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
`;

const QuoteCalendarField: React.FC<ComponentProps> = ({ input }) => (
  <QuoteCalendar
    endDate={input.value || undefined}
    onChange={(date: Moment) => input.onChange(date)}
  />
);

export interface AboutYouProps {
  initialValues: DriverFields;
  initialPostcode?: string;
  occupations: OccupationData;
  occupationType: string;
  prefilled: boolean;
  resetOccupation: () => void;
  valid: boolean;
  submitFailed: boolean;
  formErrors: Record<string, unknown>;
  postcodeNotFound: boolean;
  lookupAddress: (postcode: string) => void;
  addresses: Address[];
  setAddressFields: () => void;
  postcode: string;
  isLoggedIn: boolean;
  quoteStart: Moment;
  goToLogin: () => void;
  productType: string;
  paymentMethod: PaymentMethod;
  hasBookedPracticalTest: boolean;
  email: string;
  clearEmailField: () => void;
  submission: (
    values: Record<string, string>,
    props: AboutYouProps,
    testBookedEnabled: boolean,
    directOtcSubsToDrivingProfile: boolean,
  ) => void;
}

const AboutYou: React.FC<AboutYouProps & InjectedFormProps> = ({
  handleSubmit,
  initialValues = {} as DriverFields,
  occupations,
  occupationType,
  prefilled,
  resetOccupation,
  valid,
  submitFailed,
  formErrors,
  postcodeNotFound,
  lookupAddress,
  addresses,
  setAddressFields,
  postcode,
  isLoggedIn,
  goToLogin,
  productType,
  hasBookedPracticalTest,
  email,
  clearEmailField,
  submission,
}) => {
  const [showEmailExistsModal, toggleEmailExistsModal] = useState(false);
  const enableTestBookedQuestionFlag = useOptimizelyFlag('TEMP_ENABLE_TEST_BOOKED');
  const redirectOtcSubsToDrivingProfile = useOptimizelyFlag('TEMP_ENABLE_DRIVING_PROFILE_OTC_SUBS');

  const checkEmailExists = async (): Promise<void> => {
    try {
      if (!formErrors.email) {
        await accountApi.checkEmail(email);

        // Hide any onscreen options/keyboards if user has focused another field after inputting email
        (document.activeElement as HTMLElement).blur();

        toggleEmailExistsModal(true);
      }
    } catch (exception) {
      // Email wasn't found
    }
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(
          (values: Record<string, string>, _dispatch: Dispatch, props: DecoratedFormProps) => {
            submission(
              values,
              props as AboutYouProps,
              enableTestBookedQuestionFlag.enabled,
              redirectOtcSubsToDrivingProfile.enabled,
            );
          },
        )}
      >
        <QuoteStep
          id='aboutYou-component-quoteStep'
          form
          stepTitle='About you'
          stepSubtitle={getSubtitle(isLoggedIn, goToLogin)}
          nextAction={handleSubmit(
            (values: Record<string, string>, _dispatch: Dispatch, props: DecoratedFormProps) => {
              submission(
                values,
                props as AboutYouProps,
                enableTestBookedQuestionFlag.enabled,
                redirectOtcSubsToDrivingProfile.enabled,
              );
            },
          )}
          submitError={submitFailed && (!valid || postcodeNotFound)}
          formErrors={formErrors}
        >
          <Group>
            <SectionTitle id='aboutYou-title-email'>
              {renderTitles('emailAddress', productType)}
            </SectionTitle>
            <Field
              id='aboutYou-field-email'
              name='email'
              type='email'
              placeholder='Email address'
              maxLength={254}
              component={TextInput}
              validate={[ReduxFieldValidation.isRequired, ReduxFieldValidation.isEmail]}
              disabled={isLoggedIn}
              onBlur={() => checkEmailExists()}
            />
            {!isLoggedIn && (
              <InfoTagContainer>
                <InfoTag
                  id='aboutYou-text-infoTag'
                  text={renderTitles('emailAddressInfo', productType)}
                />
              </InfoTagContainer>
            )}
          </Group>

          {enableTestBookedQuestionFlag.enabled && productType === 'ldp' && (
            <>
              <SectionTitle id='aboutYou-title-bookedTest'>
                Have you booked your practical driving test?
              </SectionTitle>
              <Field
                props={{ name: 'hasBookedPracticalTest' }}
                name='hasBookedPracticalTest'
                component={ToggleButtonField}
                id='aboutYou-field-hasBookedPracticalTest'
              />
              {hasBookedPracticalTest && (
                <>
                  <SectionTitle id='aboutYou-title-dateOfPractical'>
                    What date is your practical test?
                  </SectionTitle>
                  <DateContainer>
                    <Field
                      name='dateOfPracticalTest'
                      component={QuoteCalendarField}
                      id='aboutYou-field-dateOfPracticalTest'
                    />
                  </DateContainer>
                </>
              )}
            </>
          )}
          <Group>
            <SectionTitle id='aboutYou-title-name'>
              {renderTitles('driversName', productType)}
            </SectionTitle>
            <SectionSubTitle id='aboutYou-subtitle-name'>
              {renderTitles('driversNameInfo', productType)}
            </SectionSubTitle>
            <Field
              id='aboutYou-field-title'
              name='title'
              component={Selector}
              placeholder='Select a title'
              options={titles}
              validate={[ReduxFieldValidation.isRequired]}
              disabled={initialValues.title && prefilled}
            />
            <Field
              id='aboutYou-field-firstName'
              name='firstName'
              placeholder='First name'
              component={TextInput}
              validate={[ReduxFieldValidation.isRequired, ReduxFieldValidation.isName]}
              disabled={!!(initialValues.firstName && prefilled)}
            />
            <Field
              id='aboutYou-field-lastName'
              name='lastName'
              placeholder='Last name'
              component={TextInput}
              validate={[ReduxFieldValidation.isRequired, ReduxFieldValidation.isName]}
              disabled={!!(initialValues.lastName && prefilled)}
            />
          </Group>
          <Group>
            <SectionTitle id='aboutYou-title-DOB'>{renderTitles('dob', productType)}</SectionTitle>
            <Field
              id='aboutYou-field-birthdate'
              name='birthdate'
              component={DateInputField}
              validate={[ReduxFieldValidation.isValidDOB]}
              disabled={initialValues.birthdate && prefilled}
            />
          </Group>
          <Group>
            <SectionTitle id='aboutYou-title-address'>
              {renderTitles('address', productType)}
            </SectionTitle>
            <Field
              id='aboutYou-field-addressKey'
              name='addressKey'
              component={AddressSearch}
              validate={[ReduxFieldValidation.isRequired]}
              addresses={addresses}
              setAddressFields={setAddressFields}
              lookupAddress={lookupAddress}
              isPostcodeValid={ReduxFieldValidation.isCoveredPostcode}
              postcode={postcode}
              postcodeNotFound={postcodeNotFound}
            />
          </Group>
          <Group>
            <SectionTitle id='aboutYou-title-occupation'>
              {renderTitles('occupation', productType)}
            </SectionTitle>
            <Field
              id='aboutYou-field-occupationType'
              name='occupationType'
              component={MultipleChoiceField}
              options={occupationTypes}
              withChange={resetOccupation}
              validate={[ReduxFieldValidation.isRequired]}
            />
            <Field
              id='aboutYou-field-occupation'
              name='occupation'
              component={OccupationSelectField}
              options={occupationTypes}
              occupations={occupations}
              occupationType={occupationType}
              validate={[ReduxFieldValidation.isRequired]}
            />
          </Group>
          {productType === 'newdriver' ? (
            <Group>
              <SectionTitle id='aboutYou-title-phoneNumberND'>
                Driver’s mobile phone number
              </SectionTitle>
              <Field
                id='aboutYou-field-phoneNumber'
                name='phoneNumber'
                type='tel'
                placeholder='e.g. 07123456789'
                component={TextInput}
                validate={[ReduxFieldValidation.isMobileNumber]}
              />
              <InformationBox id='aboutYou-text-checkPhoneNumberND' type='important'>
                Please make sure that the driver’s mobile number is correct as we will use this to
                activate the New Driver app.
              </InformationBox>
            </Group>
          ) : (
            <Group>
              <SectionTitle id='aboutYou-title-phoneNumber'>
                {renderTitles('contactNumber', productType)}
              </SectionTitle>
              <Field
                id='aboutYou-field-phoneNumber'
                name='phoneNumber'
                type='tel'
                placeholder='e.g. 01234567890'
                component={TextInput}
                validate={[ReduxFieldValidation.isRequired, ReduxFieldValidation.isPhoneNumber]}
              />
              <InformationBox id='aboutYou-text-includeAreaCode' type='important'>
                Make sure to include the area code.
              </InformationBox>
              <div style={{ padding: '16px' }}>
                <InfoTag
                  id='aboutYou-text-infoMarketingPreferences'
                  text='We need your number in case we need to speak with you regarding your insurance. We may also share marketing updates via SMS. If you do not wish for us to send you marketing updates you can change your account preferences at any time in your account.'
                />
              </div>
            </Group>
          )}
        </QuoteStep>
      </form>

      <EmailAlreadyExistsModal
        visible={showEmailExistsModal}
        close={() => {
          clearEmailField();
          toggleEmailExistsModal(false);
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });
        }}
        email={email}
      />
    </>
  );
};

export default AboutYou;
