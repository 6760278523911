import React from 'react';
import { Field, InjectedFormProps, FormErrors } from 'redux-form';
import styled from 'styled-components';
import { InformationBox, RoundedGreyBox } from '@rentecarlo/component-library';

import { useOptimizelyTrack } from '@hooks';
import QuoteStep from '@templates/QuoteStep';
import SecondaryTitle from '@atoms/titles/componentTitles/SecondaryTitle';
import Table from '@molecules/layout/Table';
import VariableExcessForm from '@organisms/forms/VariableExcessForm';
import AncillaryField from '@organisms/forms/AncillaryField';
import ReduxFieldValidation from '@services/formValidation';
import { updatePrice } from '@reducer/quote/save.actions';

import { FinalPriceExcess } from '@utils/types';

const SecondaryTitleWrapper = styled.div`
  margin-bottom: 25px;
`;

const InformationBoxContainer = styled.div`
  margin-bottom: 25px;
`;

const VariableExcessWrapper = styled.div`
  margin: 60px 0 65px;
`;

const AncillaryWrapper = styled.div`
  margin: 27px 0px 32px;
`;

type Excesses = {
  title: string;
  value: string;
};

interface Props {
  formExcess: number;
  excesses: Excesses[];
  finalPricesByExcess: FinalPriceExcess[];
  showUnavailableExcess: boolean;
  formErrors: FormErrors<unknown, string>;
  submitFailed: boolean;
  valid: boolean;
  productType: string;
  ffAboutYourCoverTerms: string;
  breakdownCover: string;
  breakdownCoverPrice: string;
  isSubscription: boolean;
  submission: (values: Record<string, unknown>) => void;
}

const ImportantInformation: React.FC<Props & InjectedFormProps> = ({
  formExcess,
  excesses,
  finalPricesByExcess,
  handleSubmit,
  showUnavailableExcess,
  formErrors,
  submitFailed,
  valid,
  productType,
  submission,
  breakdownCoverPrice,
  isSubscription,
}) => {
  const addToExcess = (addedExcesses: Excesses[]) => {
    return [...addedExcesses.slice(1), { title: 'Your Payable Excess', value: `£${formExcess}` }];
  };
  const sendEvent = useOptimizelyTrack();

  const sendBreakdownCoverEvent = (values: Record<string, unknown>) => {
    if (!values.breakdownCover) {
      return;
    }

    sendEvent(`breakdown_cover_${values.breakdownCover === 'true' ? 'yes' : 'no'}`);
  };

  return (
    <form
      onSubmit={handleSubmit((values: Record<string, unknown>) => {
        sendBreakdownCoverEvent(values);
        submission(values);
      })}
    >
      <QuoteStep
        id='importantInformation-component-quoteStep'
        nextLabel='NEXT'
        nextAction={handleSubmit((values: Record<string, unknown>) => {
          sendBreakdownCoverEvent(values);
          submission(values);
        })}
        stepTitle='About your policy'
        stepSubtitle='Please review the extra information about your policy.'
        form
        formErrors={formErrors}
        submitError={submitFailed && !valid}
      >
        <SecondaryTitleWrapper>
          <SecondaryTitle id='importantInformation-title-excesses'>EXCESSES</SecondaryTitle>
        </SecondaryTitleWrapper>
        {finalPricesByExcess && (finalPricesByExcess.length > 1 || showUnavailableExcess) ? (
          <Table id='importantInformation-table-addedExcesses' data={addToExcess(excesses)} />
        ) : (
          <Table id='importantInformation-table-excesses' data={excesses} />
        )}
        <InformationBoxContainer>
          <InformationBox id='importantInformation-text-information' type='important'>
            The excess is the amount you pay in the event of any claim on this policy, regardless of
            who is responsible for the incident.
          </InformationBox>
        </InformationBoxContainer>
        {finalPricesByExcess && (finalPricesByExcess.length > 1 || showUnavailableExcess) && (
          <VariableExcessWrapper>
            <Field
              id='importantInformation-field-excess'
              name='excess'
              validation={[ReduxFieldValidation.isRequired]}
              component={VariableExcessForm}
              finalPricesByExcess={finalPricesByExcess}
              showUnavailableExcess={showUnavailableExcess}
              productType={productType}
            />
          </VariableExcessWrapper>
        )}
        {breakdownCoverPrice !== '' && !isSubscription && (
          <AncillaryWrapper>
            <Field
              id='importantInformation-field-breakdownCover'
              name='breakdownCover'
              validation={[ReduxFieldValidation.isRequired]}
              component={AncillaryField}
              breakdownCoverPrice={breakdownCoverPrice}
              onChange={(hasBreakdown) => {
                const priceFound = finalPricesByExcess.find(
                  (price: FinalPriceExcess) => price.excess === formExcess,
                );

                let newPrice;
                if (priceFound && hasBreakdown === 'true') {
                  newPrice = priceFound.price_with_breakdown_after_discount;
                }
                if (priceFound && hasBreakdown === 'false') {
                  newPrice = priceFound.price;
                }

                if (newPrice) {
                  updatePrice(newPrice.toString());
                }
              }}
            />
            <RoundedGreyBox
              id='importantInformation-text-considerBreakdown'
              margin={[32, 0, 20, 0]}
              padding={[16, 16, 16, 16]}
            >
              Please consider whether you have any existing breakdown cover elsewhere such as with
              your bank or vehicle manufacturer.
            </RoundedGreyBox>
          </AncillaryWrapper>
        )}
      </QuoteStep>
    </form>
  );
};

export default ImportantInformation;
